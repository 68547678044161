import React from 'react';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import './sidebar.css';

const Sidebar = () => {
  const userRole = useSelector(state => state.user_role)
  const counter = useSelector(state => state.count)

  
  
  return (
    <div className="sidebar">
      <div className="alarm">{counter}</div>
        
      {userRole.roleId === 1 && <NavLink className="button_sidebar first" to="/unos">UNOS</NavLink>}
        <NavLink className="button_sidebar" to="/magacin">MAGACIN</NavLink>
        <NavLink className="button_sidebar" to="/vozni_park">VOZNI PARK</NavLink>
        <NavLink className="button_sidebar" to="/servis">SERVIS</NavLink>
        <NavLink className="button_sidebar" to="/zaposleni">ZAPOSLENI</NavLink>
        <NavLink className="button_sidebar" to="/gorivo">GORIVO</NavLink>
        <NavLink className="button_sidebar" to="/kazne">PREKRŠAJI</NavLink>
        <div className="button_sidebar" >
        {userRole.roleId === 1 && <NavLink  to="/settings/configuration">{`PODEŠAVANJA`}<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
</svg></NavLink>}
        <NavLink style={{borderTop: userRole.roleId !== 1 && 'none'}} to="/info">INFO</NavLink>
        </div>
        
       
    </div>
  );
};

export default Sidebar;
