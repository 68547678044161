import React from 'react';
import './unos_redova_voznji.css';
import Slika from './red_voznje.jpg'

const UnosRedovaVoznji = props => {
 
  return (
    <div className="unos unos_redova_voznji">
      <h1>UNOS REDOVA VOŽNJI</h1>
      <img src={Slika} alt="Unos redova voznji"/>
      {props.children}
    </div>
  );
};

export default UnosRedovaVoznji;