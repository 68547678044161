import React, { useState, useEffect, useRef, useContext, useReducer } from "react";
import Plan from "./plan.js";
import Loading from "../../Loading/loading";
import "./home.css";
import { Toast } from "primereact/toast";
import { AuthContext } from "../../Header/auth - context";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";

let useHomeDataReducer = (state, action) =>{
  if(action.type === 'PLAN_DB1'){
    return {planDB1: action.value, planDB2: state.planDB2, planDB3: state.planDB3, usersDB: state.usersDB, load: state.load, operater: state.operater, company: state.company, prioritetDB: state.prioritetDB, render: state.render}
  }
  if(action.type === 'PLAN_DB2'){
    return {planDB1: state.planDB1, planDB2: action.value, planDB3: state.planDB3, usersDB: state.usersDB, load: state.load, operater: state.operater, company: state.company, prioritetDB: state.prioritetDB, render: state.render}
  }
  if(action.type === 'PLAN_DB3'){
    return {planDB1: state.planDB1, planDB2: state.planDB2, planDB3: action.value, usersDB: state.usersDB, load: state.load, operater: state.operater, company: state.company, prioritetDB: state.prioritetDB, render: state.render}
  }
  if(action.type === 'USERS_DB'){
    return {planDB1: state.planDB1, planDB2: state.planDB2, planDB3: state.planDB3, usersDB: action.value, load: state.load, operater: state.operater, company: state.company, prioritetDB: state.prioritetDB, render: state.render}
  }
  if(action.type === 'LOAD'){
    return {planDB1: state.planDB1, planDB2: state.planDB2, planDB3: state.planDB3, usersDB: state.usersDB, load: action.value, operater: state.operater, company: state.company, prioritetDB: state.prioritetDB, render: state.render}
  }
  if(action.type === 'OPERATER'){
    return {planDB1: state.planDB1, planDB2: state.planDB2, planDB3: state.planDB3, usersDB: state.usersDB, load: state.load, operater: action.value, company: state.company, prioritetDB: state.prioritetDB, render: state.render}
  }
  if(action.type === 'COMPANY'){
    return {planDB1: state.planDB1, planDB2: state.planDB2, planDB3: state.planDB3, usersDB: state.usersDB, load: state.load, operater: state.operater, company: action.value, prioritetDB: state.prioritetDB, render: state.render}
  }
  if(action.type === 'PRIORITET'){
    return {planDB1: state.planDB1, planDB2: state.planDB2, planDB3: state.planDB3, usersDB: state.usersDB, load: state.load, operater: state.operater, company: state.company, prioritetDB: action.value, render: state.render}
  }
  if(action.type === 'RENDER'){
    return {planDB1: state.planDB1, planDB2: state.planDB2, planDB3: state.planDB3, usersDB: state.usersDB, load: state.load, operater: state.operater, company: state.company, prioritetDB: state.prioritetDB, render: action.value}
  }

  return {planDB1: [], planDB2:[], planDB3:[], usersDB:[], load: true, operater: null, prioritetDB: [], render: false}
}

const Home = () => {
  const company = useSelector(state => state.company)
  const [stateHomeReducer, dispatchHomeState] = useReducer(useHomeDataReducer,{planDB1: [], planDB2:[], planDB3:[], usersDB:[], load: true, operater: null, company: null, prioritetDB: [], render: false})
  // const [planDB1, setPlanDB1] = useState([]);
  // const [planDB2, setPlanDB2] = useState([]);
  // const [planDB3, setPlanDB3] = useState([]);
  const [planInsert, setPlanInsert] = useState('');
  // const [usersDB, setUsersDB] = useState([]);
  const [userDrop, setUserDrop] = useState("");
  // const [prioritetDB, setPrioritetDB] = useState([]);
  const [prioritetDrop, setPrioritetDrop] = useState("");
  // const [load, setLoad] = useState(true);
  // const [render, setRender] = useState(false);
  // const [operater, setOperater] = useState(null);
  const [heightDiv, setHeightDiv] = useState('24%');
  const toast = useRef(null);
  const auth = useContext(AuthContext);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };
  const renderFunction = () => {
    if (stateHomeReducer.render === false) {
      dispatchHomeState({type:'RENDER', value: true})
    } else {
      dispatchHomeState({type:'RENDER', value: false})
    }
  }
  const calculateHeight = () => {
    let box = document.querySelector('#root');
    let height = box.offsetHeight;
    if(height < 760){
      setHeightDiv('30%')
    } else if (height > 760){
      setHeightDiv('24%')
    }
  }
  window.onresize = () => {
    calculateHeight()
  };

  useEffect(() => {
    calculateHeight();
        const storedData = JSON.parse(localStorage.getItem("userData"));
  
    if(storedData && company){
      dispatchHomeState({type: 'OPERATER', value: storedData.userId})
      dispatchHomeState({type: 'COMPANY', value: company.companyId})
    }
   
    setTimeout(()=>{
      if(company !== null){
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          body: JSON.stringify({ 
            firma_id: company.companyId
           }),
        };
        fetch(process.env.REACT_APP_BACKEND_URL + "/plan", requestOptions)
        .then(handleErrors)
        .then((result) => {
          let _plandb1 = [];
          let _plandb2 = [];
          let _plandb3 = [];
          for (let i = 0; i < result.length; i++) {
            if (result[i].Stanje === 1) {
              _plandb1.push(result[i])
            }
            if (result[i].Stanje === 2) {
              _plandb2.push(result[i])
            }
            if (result[i].Stanje === 3) {
              _plandb3.push(result[i])
            }
  
          }
          dispatchHomeState({type:'PLAN_DB1', value: _plandb1})
          dispatchHomeState({type:'PLAN_DB2', value: _plandb2})
          dispatchHomeState({type:'PLAN_DB3', value: _plandb3})
          dispatchHomeState({type:'LOAD', value: false})
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(error);
            dispatchHomeState({type:'LOAD', value: false})
          });
        })
        fetch(process.env.REACT_APP_BACKEND_URL + "/users", requestOptions)
        .then(handleErrors)
        .then((result) => {
          let users = [];
          for (let user of result) {
            let _user = {
              label: `${user.name} ${user.surname}`,
              value: user.id,
            };
            users.push(_user);
          }
          dispatchHomeState({type:'USERS_DB', value: users})
      
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
      }
      fetch(process.env.REACT_APP_BACKEND_URL + "/plan/prioritet_plana")
      .then(handleErrors)
      .then((result) => {
        let prioritet = [];
        for (let tip of result) {
          let _tip = {
            label: tip.naziv_prioriteta,
            value: tip.id,
          };
          prioritet.push(_tip);
        }
        dispatchHomeState({type:'PRIORITET', value: prioritet})
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
    },100)
  }, [planInsert, stateHomeReducer.render]);

  const setPlanHandler = () => {
    let value = document.querySelector("textarea").value;
    const storedData = JSON.parse(localStorage.getItem("userData"));
  
    if (value.length > 1 && company.companyId !== null && userDrop !== null && prioritetDrop !== null ) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ 
          text: value,
          taskOd: storedData.userId,
          taskZa: userDrop,
          prioritet_id: prioritetDrop,
          firma_id: company.companyId
         }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/plan/createPlan", requestOptions)
        .then(handleErrors)
        .then((data) => {
          setPlanInsert(data);
          toast.current.show({
            severity: "success",
            summary: "Uspešno unet plan!",
            life: 3000,
          });
          document.querySelector("textarea").value = "";
          setUserDrop(null);
          setPrioritetDrop(null);
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje",
        detail: "Pre snimanja napišite plan!",
        life: 3000,
      });
    }
  };

  return (
    <main>
      <div id="workstation">
        <div id="board_input">
          <div className="textarea">
            <textarea placeholder="Unesite novi zadatak..."></textarea>
            <div>
            <Dropdown
              value={userDrop}
              options={stateHomeReducer.usersDB}
              required
              onChange={(e) => setUserDrop(e.value)}
              placeholder="Za korisnika"
              tooltip="Za korisnika"
              tooltipOptions={{position: 'top'}}
              className="plan_dropdown"
            />
            <Dropdown
              value={prioritetDrop}
              options={stateHomeReducer.prioritetDB}
              required
              onChange={(e) => setPrioritetDrop(e.value)}
              placeholder="Proritet"
              tooltip="Prioritet"
              tooltipOptions={{position: 'top'}}
              className="plan_dropdown"
            />
            </div>
          </div>
          <div className="board_unos">
            <Button
              onClick={setPlanHandler}
              label="UNESI"
              className="p-button-raised p-button-success p-button"
              id="btn"
            />
          </div>
        </div>

        <div id="boardPR">
          <Toast ref={toast} />
          {stateHomeReducer.load && <Loading />}
          <div className="kolona_naslov">
            <div>NOVI</div>
            <div>U RADU</div>
            <div>ZAVRŠENI</div>
          </div>
          <section className="kolona_plan">

            {stateHomeReducer.planDB1.length > 0
              && stateHomeReducer.planDB1.map((plan, i) => (
                <Plan
                  key={i}
                  index={i}
                  date={plan.DateEntered}
                  ID={plan.id}
                  text={plan.Text}
                  plan={plan}
                  prioritet={stateHomeReducer.prioritetDB}
                  users={stateHomeReducer.usersDB}
                  planAll={stateHomeReducer.planDB1}
                  setPlan={dispatchHomeState}
                  render={renderFunction}
                  height={heightDiv}
                />
              ))
            }</section>
          <section className="kolona_plan">
            {stateHomeReducer.planDB2.length > 0
              && stateHomeReducer.planDB2.map((plan, i) => (
                <Plan
                  key={i}
                  index={i}
                  date={plan.DateEntered}
                  ID={plan.id}
                  text={plan.Text}
                  plan={plan}
                  users={stateHomeReducer.usersDB}
                  prioritet={stateHomeReducer.prioritetDB}
                  planAll={stateHomeReducer.planDB2}
                  setPlan={dispatchHomeState}
                  render={renderFunction}
                  height={heightDiv}
                />
              ))
            }</section>
          <section className="kolona_plan">
            {stateHomeReducer.planDB3.length > 0
              && stateHomeReducer.planDB3.map((plan, i) => (
                <Plan
                  key={i}
                  index={i}
                  date={plan.DateEntered}
                  ID={plan.id}
                  text={plan.Text}
                  plan={plan}
                  users={stateHomeReducer.usersDB}
                  prioritet={stateHomeReducer.prioritetDB}
                  planAll={stateHomeReducer.planDB3}
                  setPlan={dispatchHomeState}
                  render={renderFunction}
                  height={heightDiv}
                />
              ))
            }</section>
        </div>
      </div>
    </main>
  );
};

export default Home;
