import React, { useState, useRef, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import "./unosdelovapopup.css";
import Delovi from "./delovi.js";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { AuthContext } from "../../../../Header/auth - context";
import { confirmDialog } from "primereact/confirmdialog";
import { CSSTransition } from "react-transition-group";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";

const UnosDelovaPop = (props) => {
  const dispatch = useDispatch();
  const nodeRef = React.useRef(null);
  const auth = useContext(AuthContext);
  const company = useSelector((state) => state.company);
  const [partDB, setPartDB] = useState([]);
  const [stavkaToUpdate, setStavkaToUpdate] = useState([]);
  const [stavka, setStavka] = useState([]);
  const [filteredParts, setFilteredParts] = useState(null);
  const toast = useRef(null);
  const [kat, setKat] = useState([]);
  const [dropKat, setDropKat] = useState(null);
  const [podkat, setPodKat] = useState([]);
  const [singlePK, setSinglePK] = useState([]);
  const [dropPodKat, setDropPodKat] = useState(null);
  const [vType, setvType] = useState([]);
  const [dropvType, setDropvType] = useState(null);
  const [mag, setMag] = useState([]);
  const [dropMag, setDropMag] = useState(null);
  const [value, setValue] = useState(null);
  const [valueKol, setValueKol] = useState(null);
  const [date, setDate] = useState(null);
  const [valueTA, setValueTA] = useState("");
  const [idToUpdate, setIdToUpdate] = useState(null);
  const [render, setRender] = useState(false);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  const handleDeleteStavka = (index) => {
    if (index > -1) {
      const item = stavka.slice();
      item.splice(index, 1);
      setStavka(item);
    }
  };

  const handleInput = () => {
    const stavka = {
      id: "",
      naziv: "",
      kategorija: "",
      podkategorija: "",
      tip: "",
      magacin: "",
      cena: "",
      datum: "",
      kolicina: "",
      opis: "",
    };
    let naziv = document.getElementsByClassName("p-autocomplete-input")[0]
      .value;
    let opis = document.getElementsByClassName("opis")[0].value;

    if (
      naziv !== "" &&
      dropKat !== null &&
      dropPodKat !== null &&
      dropvType !== null &&
      dropMag !== null &&
      valueKol !== null &&
      valueKol > 0
    ) {
      stavka.id = idToUpdate;
      stavka.naziv = naziv;
      stavka.kategorija = dropKat;
      stavka.podkategorija = dropPodKat;
      stavka.tip = dropvType;
      stavka.magacin = dropMag;
      stavka.cena = value;
      if (date === null) {
        stavka.datum = date;
      } else {
        let newDateFD = new Date(date);
        newDateFD.setHours(newDateFD.getHours() + 2);
        stavka.datum = newDateFD.toISOString().substr(0, 10);
      }
      stavka.kolicina = valueKol;
      stavka.opis = opis;

      setStavka((item) => [...item, stavka]);
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste uneli sve podatke za deo!",
        life: 3000,
      });
    }
  };

  const handleUpdate = () => {
  
    let naziv = document.getElementsByClassName("p-autocomplete-input")[0]
      .value;
    let opis = document.getElementsByClassName("opis")[0].value;

    let datum;
    if (date === null) {
      datum = date;
    } else {
      let newDateFD = new Date(date);
      newDateFD.setHours(newDateFD.getHours() + 2);
      datum = newDateFD.toISOString().substr(0, 10);
    }

    if (idToUpdate !== null && opis !== undefined && naziv !== '') {
      let requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          id: idToUpdate,
          naziv_dela: naziv,
          kategorija_id: dropKat,
          podkategorija_id: dropPodKat,
          tip_id: dropvType,
          magacin_id: dropMag,
          cena: value,
          faktura_date: datum,
          kolicina: valueKol,
          opis: opis,
        }),
      };
      const requestOptions2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ 
          firma_id: company.companyId
         }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/part", requestOptions)
        .then(handleErrors)
        .then((data) => {
        if(data.message === 'Izmena nije uspela, isti podaci!'){
          toast.current.show({
            severity: "info",
            summary: "Obaveštenje!",
            detail: data.message,
            life: 3000,
          }),
          deleteContent(),
          setIdToUpdate(null)
        } else {
          toast.current.show({
            severity: "success",
            summary: "Uspešno!",
            detail: data.message,
            life: 3000,
          }),
          deleteContent(),
          setIdToUpdate(null),
          fetch(process.env.REACT_APP_BACKEND_URL + "/alarms/info", requestOptions2)
          .then(handleErrors)
          .then((result) => {
            if (result.partInfo.length  > 0 || result.vehicleReg.length  > 0 || result.vehicleSes.length  > 0 || result.vehicleTaho.length  > 0) {
            dispatch({type: 'COUNTER', value: result.partInfo.length + result.vehicleReg.length + result.vehicleSes.length + result.vehicleTaho.length})
          }
          })
        }})
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste izabrali deo koji želite da ažurirate!",
        life: 3000,
      });
    }
  };

  const handleInputDBHandler = () => {
    
    if(stavka.length > 0){
    for (let i = 0; i < stavka.length; i++) {
      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          id: idToUpdate,
          firma_id: company.companyId,
          naziv_dela: stavka[i].naziv,
          kategorija_id: stavka[i].kategorija,
          podkategorija_id: stavka[i].podkategorija,
          tip_id: stavka[i].tip,
          magacin_id: stavka[i].magacin,
          cena: stavka[i].cena,
          faktura_date: stavka[i].datum,
          kolicina: stavka[i].kolicina,
          opis: stavka[i].opis,
        }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/part/insertPart", requestOptions)
        .then(handleErrors)
        .then((data) => {
          toast.current.show({
            severity: "success",
            summary: "Unos uspešan!",
            detail: data.message,
            life: 3000,
          });
          setStavka([]);
          deleteContent();
          fetch(process.env.REACT_APP_BACKEND_URL + "/alarms/info", requestOptions)
          .then(handleErrors)
          .then((result) => {
            setTimeout(()=>{
              if (result.partInfo.length  > 0 || result.vehicleReg.length  > 0 || result.vehicleSes.length  > 0 || result.vehicleTaho.length  > 0) {
                dispatch({type: 'COUNTER', value: result.partInfo.length + result.vehicleReg.length + result.vehicleSes.length + result.vehicleTaho.length})
                }
          },[100])
          })
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    }
      } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: 'Nemate unetu ni jednu stavku. Nakon dodavanja stavke pokušajte unos ponovo.',
        life: 3000,
      });
    }
  };
  useEffect(() => {

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };

    fetch(process.env.REACT_APP_BACKEND_URL + "/part", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setPartDB(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
      fetch(process.env.REACT_APP_BACKEND_URL + "/part/warehouse", requestOptions)
      .then(handleErrors)
      .then((result) => {
        let warehouse = [];
        for (let ware of result) {
          let _ware = {
            label: ware.magacin,
            value: ware.id,
          };
          warehouse.push(_ware);
        }
        setMag(warehouse);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });

  }, [idToUpdate, stavka, render, props.renderD]);

  useEffect(()=>{
    fetch(process.env.REACT_APP_BACKEND_URL + "/part/vehicletype")
    .then(handleErrors)
    .then((result) => {
      let vtype = [];
      for (let tip of result) {
        let _type = {
          label: tip.tip,
          value: tip.id,
        };
        vtype.push(_type);
      }
      setvType(vtype);
    })
    .catch((error) => {
      error.json().then((errorMessage) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessage.message,
          life: 3000,
        });
        console.log(errorMessage);
      });
    });
    fetch(process.env.REACT_APP_BACKEND_URL + "/part/kat")
      .then(handleErrors)
      .then((result) => {
        let katres = [];
        for (let kat of result) {
          let _kat = {
            label: kat.kategorija,
            value: kat.id,
            items: [],
          };
          katres.push(_kat);
        }
        setKat(katres);
        const katCopy = katres;

        fetch(process.env.REACT_APP_BACKEND_URL + "/part/podkat")
          .then(handleErrors)
          .then((result) => {
            let podkatres = [];
            for (let katt of katCopy) {
              let podkat = result.filter(
                (item) => item.kategorija_id === katt.value
              );
              let podkatBlock = {
                label: katt.label,
                code: "podkat",
                items: [],
              };
              let podkatitemblock = [];
              for (let podk of podkat) {
                let podkatitemblockitem = {
                  label: podk.podkategorija,
                  value: podk.id,
                };
                podkatitemblock.push(podkatitemblockitem);
              }
              podkatBlock.items = podkatitemblock;
              podkatres.push(podkatBlock);
            }
            setSinglePK(result);
            setPodKat(podkatres);
          })
          .catch((error) => {
            error.json().then((errorMessage) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMessage.message,
                life: 3000,
              });
              console.log(errorMessage);
            });
          });
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
  },[])

  const searchPart = (event) => {
    let query = event.query;
    let _filteredParts = [];
    let filteredItems = partDB.filter(
      (item) =>
        item.naziv_dela.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (filteredItems && filteredItems.length) {
      _filteredParts.push(...filteredItems);
    }

    setFilteredParts(_filteredParts);
  };
  const stavkaToUpdateFUNC = (e) => {
    setDropKat(e.value.kategorija_id);
    setDropPodKat(e.value.podkategorija_id);
    setDropvType(e.value.tip_id);
    setDropMag(e.value.magacin_id);
    setValue(e.value.cena);
    setValueKol(e.value.kolicina);
    if (e.value.faktura_date === null) {
      setDate(null);
    } else {
      let datumFD = new Date(e.value.faktura_date);
      setDate(datumFD);
    }
    setValueTA(e.value.opis);

    setStavkaToUpdate(e.value);
    setIdToUpdate(e.value.id);
  };

  const handleDelete = () => {
    confirmDialog({
      message: "Jel ste sigurni da želite obrisati deo?",
      acceptLabel: "Da",
      rejectLabel: "Ne",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  const accept = () => {
    if (idToUpdate !== null) {
      
      let requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ id: idToUpdate }),
      };
      const requestOptions2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ 
          firma_id: company.companyId
         }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/part", requestOptions)
        .then(handleErrors)
        .then((result) => {
          toast.current.show({
            severity: "success",
            summary: "Brisanje uspešno!",
            detail: result.message,
            life: 3000,
          });
          fetch(process.env.REACT_APP_BACKEND_URL + "/alarms/info", requestOptions2)
          .then(handleErrors)
          .then((result) => {
            setTimeout(()=>{
              if (result.partInfo.length  > 0 || result.vehicleReg.length  > 0 || result.vehicleSes.length  > 0 || result.vehicleTaho.length  > 0) {
                dispatch({type: 'COUNTER', value: result.partInfo.length + result.vehicleReg.length + result.vehicleSes.length + result.vehicleTaho.length})
                }
          },[100])
          })
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste izabrali deo koji želite da obrišete!",
        life: 3000,
      });
    }
  
    deleteContent();
  };
  const reject = () => {
    console.log("REJECTED DELETION!");
  };

  const deleteContent = () => {
    setStavkaToUpdate([]);
    setDropKat(null);
    setDropPodKat(null);
    setDropvType(null);
    setDropMag(null);
    setValue(null);
    setDate(null);
    setValueKol(null);
    setValueTA("");
    if (render === true) {
      setRender(false);
    } else {
      setRender(true);
    }
  }
  const handleChange = (e) => {
    deleteContent();
    setIdToUpdate(null)
    setStavkaToUpdate(e)
  }
  const handleClose = () => {
    deleteContent();
    setIdToUpdate(null);
    setStavka([]);
    props.close();
  }

  const content = (
    <CSSTransition
      in={props.show}
      timeout={300}
      classNames="slide-in-top"
      mountOnEnter
      unmountOnExit
      nodeRef={nodeRef}
    >
      <div id="unos_d_popup" ref={nodeRef}>
        <Toast ref={toast} />
        <section>
          <div className="header_popup">
            <h2>UNOS DELOVA</h2>
            <Button
              onClick={handleClose}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger"
            />
          </div>
          <div className="main_popup">
            <form onSubmit={(event) => event.preventDefault()}>
              <AutoComplete
                value={stavkaToUpdate}
                placeholder="Naziv artikla"
                inputStyle={{ width: "100%", margin: "0" }}
                className="errorInput"
                suggestions={filteredParts}
                completeMethod={searchPart}
                field="naziv_dela"
                onClear={() => setIdToUpdate(null)}
                onSelect={stavkaToUpdateFUNC}
                onChange={(e) => handleChange(e.value)}
              />
              <span className="errorInputName"></span>
              <Dropdown
                value={dropKat}
                options={kat}
                required
                onChange={(e) => setDropKat(e.value)}
                placeholder="Izaberi kategoriju"
                className="kategorija drugiDeo"
              />
              <Dropdown
                value={dropPodKat}
                required
                options={podkat}
                onChange={(e) => setDropPodKat(e.value)}
                placeholder="Izaberi podkategoriju"
                className="drugiDeo"
                id="podkategorija"
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
              />
              <Dropdown
                value={dropvType}
                options={vType}
                required
                onChange={(e) => setDropvType(e.value)}
                placeholder="Izaberi tip vozila"
                className="tip drugiDeo"
              />
              <Dropdown
                value={dropMag}
                options={mag}
                required
                onChange={(e) => setDropMag(e.value)}
                placeholder="Izaberi magacin"
                className="drugiDeo magacin"
              />

              <InputNumber
                value={value}
                onValueChange={(e) => setValue(e.value)}
                mode="decimal"
                placeholder="Cena"
                minFractionDigits={2}
                className="treciRed"
                id="cena"
                currency="EUR"
                required
              />

              <Calendar
                value={date}
                onChange={(e) => setDate(e.value)}
                placeholder="Datum na fakturi"
                showIcon
                dateFormat="dd.mm.yy"
                className="treciRed datum"
                tooltip="Datum na fakturi" 
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
              ></Calendar>

              <InputNumber
                value={valueKol}
                onValueChange={(e) => setValueKol(e.value)}
                className="treciRed"
                required
                id="kolicina"
                placeholder="Količina"
              />
            

              <InputTextarea
                rows={2}
                cols={30}
                value={valueTA}
                className="opis"
                onChange={(e) => setValueTA(e.value)}
                placeholder="Opis / komentar"
              />
              <div className="buttonsUD">
                <button className="btn btn-secondary" onClick={handleInput}>
                  +STAVKA
                </button>
                <button className="btn btn-warning" onClick={handleUpdate}>
                  AŽURIRAJ
                </button>
                <button className="btn btn-danger" onClick={handleDelete}>
                  OBRIŠI
                </button>
              </div>
            </form>
          </div>
          <div className="second_popup">
            <div className="deo_stavka">
              {stavka.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Naziv</th>
                      <th>Kategorija</th>
                      <th>Podkategorija</th>
                      <th>Magacin</th>
                      <th>Količina</th>
                      <th>Cena</th>
                      <th>-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stavka.map((item, i) => (
                      <Delovi
                        magacin={mag}
                        kategorija={kat}
                        podkategorija={singlePK}
                        delovi={item}
                        key={i}
                        index={i}
                        delete={handleDeleteStavka}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                "Niste uneli ni jednu stavku!"
              )}
            </div>

            <div onSubmit={(event) => event.preventDefault()} className="form">
              <button
                type="submit"
                onClick={handleInputDBHandler}
                className="btn btn-success"
              >
                UNOS
              </button>
            </div>
          </div>
        </section>
      </div>
    </CSSTransition>
  );
  return ReactDOM.createPortal(content, document.getElementById("ud-hook"));
};

export default UnosDelovaPop;
