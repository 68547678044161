import React, { useState, useRef } from "react";
import UnosDelova from "./components/UD/unos delova";
import UnosVozila from "./components/UV/unos vozila";
import UnosRadnih from "./components/UR/unos radnih";
import UnosZaposlenih from "./components/UZ/unos zaposlenih";
import UnosGoriva from "./components/UG/unos_goriva";
import UnosRedovaVoznji from "./components/URV/unos redova voznji";
import UnosDelovaPop from "./components popup/unos delova popup/unosdelovapopup";
import UnosVozilaPop from "./components popup/unos vozila popup/unosvozilapopup";
import UnosRadnihPop from "./components popup/unos radnih popup/unosradnihpopup";
import UnosZaposlenihPop from "./components popup/unos zaposlenih popup/unoszaposlenihpopup";
import UnosGorivaPop from "./components popup/unos goriva popup/unosgorivapopup";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";

import "./unos.css";

const Unos = () => {
  const [showDel, setShowDel] = useState(false);
  const [showVoz, setShowVoz] = useState(false);
  const [showRN, setShowRN] = useState(false);
  const [showZap, setShowZap] = useState(false);
  const [showGov, setShowGov] = useState(false);
  const [renderSL, setRenderSL] = useState(false);
  const [renderP, setRenderP] = useState(false);
  const toast = useRef(null);
  const role = useSelector(state => state.user_role)

  const handleClosePopDel = () => {
    setShowDel(false);
    if (renderP === true) {
      setRenderP(false);
    } else {
      setRenderP(true);
    }
  };
  const handleOpenPopDel = () => {
    if(role.roleId === 1){
      setShowDel(true);
      if (renderP === false) {
        setRenderP(true);
      } else {
        setRenderP(false);
      }
    }  else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Nemate prava pristupa za UNOS DELOVA!",
        life: 3000,
      });
    }
  };
  const handleOpenPopVoz = () => {
    if(role.roleId === 1){
    setShowVoz(true);
  }  else {
    toast.current.show({
      severity: "warn",
      summary: "Upozorenje!",
      detail: "Nemate prava pristupa za UNOS VOZILA!",
      life: 3000,
    });
  }
  };
  const handleClosePopVoz = () => {
    setShowVoz(false);
  };
  const handleOpenPopRN = () => {
    setShowRN(true);
    if (renderSL === false) {
      setRenderSL(true);
    } else {
      setRenderSL(false);
    }
  };
  const handleClosePopRN = () => {
    setShowRN(false);
    if (renderSL === true) {
      setRenderSL(false);
    } else {
      setRenderSL(true);
    }
  };
  const handleOpenPopZap = () => {
    if(role.roleId === 1){
    setShowZap(true);
    }
    else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Nemate prava pristupa za UNOS ZAPOSLENIH!",
        life: 3000,
      });
    }
  };
  const handleClosePopZap = () => {
    setShowZap(false);
  };

  const handleOpenPopGov = () => {
    if(role.roleId === 1){
    setShowGov(true);
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Nemate prava pristupa za UNOS GORIVA!",
        life: 3000,
      });
    }
  }

  const handleClosePopGov = () => {
    setShowGov(false);
  }

  return (
    <div id="unos">
      <Toast ref={toast} />
      <UnosDelovaPop
        renderD={renderP}
        close={handleClosePopDel}
        show={showDel}
      />
      <UnosVozilaPop close={handleClosePopVoz} show={showVoz} />
      <UnosRadnihPop render={renderSL} close={handleClosePopRN} show={showRN} />
      <UnosZaposlenihPop close={handleClosePopZap} show={showZap} />
      <UnosGorivaPop close={handleClosePopGov} show={showGov} />

      <UnosDelova>
        <Button
          label="+Dodaj"
          className="unos_button p-button-raised p-button-success p-button-lg"
          onClick={handleOpenPopDel}
        />
      </UnosDelova>
      <UnosVozila>
        <Button
          label="+Dodaj"
          className="unos_button p-button-raised p-button-success p-button-lg"
          onClick={handleOpenPopVoz}
        />
      </UnosVozila>
      <UnosRadnih>
        <Button
          label="+Dodaj"
          className="unos_button p-button-raised p-button-success p-button-lg"
          onClick={handleOpenPopRN}
        />
      </UnosRadnih>
      <UnosZaposlenih>
        <Button
          label="+Dodaj"
          className="unos_button p-button-raised p-button-success p-button-lg"
          onClick={handleOpenPopZap}
        />
      </UnosZaposlenih>
      <UnosGoriva>
      <Button
          label="+Dodaj"
          className="unos_button p-button-raised p-button-success p-button-lg"
          onClick={handleOpenPopGov}
        />
      </UnosGoriva>
      <UnosRedovaVoznji>
      <Button
          label="+Dodaj"
          className="unos_button p-button-raised p-button-success p-button-lg"
          // onClick={handleOpenPopRV}
        />
      </UnosRedovaVoznji>
    </div>
  );
};

export default Unos;
