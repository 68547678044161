import React, { useState, useEffect, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import "../magacin_basic.css";
import "../magacin.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Toast } from "primereact/toast";
import Loading from "../../../Loading/loading";
import { AuthContext } from "../../../Header/auth - context";
import { useSelector } from "react-redux";

const Magacin3 = () => {
  const company = useSelector(state => state.company)
  const [globalFilter, setGlobalFilter] = useState(null);
  const [load, setLoad] = useState(true);
  const [first, setFirst] = useState(0);
  const [magacin3, setMagacin3] = useState([]);
  const toast = useRef(null);  
  const auth = useContext(AuthContext);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  useEffect(() => {

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/part/magacin3", requestOptions)
      .then(handleErrors)
      .then((result) => {
        let mag3 = [];
        for (let i = 0; i < result.length; i++) {
          let date;
          if (result[i].niceDate === null) {
            date = null;
          } else {
            let newDate = new Date(result[i].niceDate);
            newDate.setHours(newDate.getHours() + 2);
            date = newDate.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }
          let obj = {
            index: '',
            id: '',
            naziv_dela:'',
            kategorija:'',
            podkategorija:'',
            tip:'',
            magacin:'',
            cena:'',
            niceDate:'',
            kolicina:'',
            opis:''
          }
          obj.index = i+1;
          obj.id = result[i].id;
          obj.naziv_dela = result[i].naziv_dela;
          obj.kategorija = result[i].kategorija;
          obj.podkategorija = result[i].podkategorija;
          obj.tip = result[i].tip;
          obj.magacin = result[i].magacin;
          obj.cena = result[i].cena;
          obj.niceDate = date;
          obj.kolicina = result[i].kolicina;
          obj.opis = result[i].opis;
          mag3.push(obj)
        }
        setMagacin3(mag3);
        setLoad(false);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(error);
          setLoad(false);
        });
      });
  }, []);

  const rowClass = (data) => {
    return {
      "row-outofstock": data.kolicina <= 0,
    };
  };
  const stockBodyTemplate = (rowData) => {
    const stockClassName = classNames({
      outofstock: rowData.kolicina <= 0,
      verylowstock: rowData.kolicina > 0 && rowData.kolicina < 6,
      lowstock: rowData.kolicina > 5 && rowData.kolicina < 11,
      instock: rowData.kolicina > 10 && rowData.kolicina < 21,
    });

    return <div className={stockClassName}>{rowData.kolicina}</div>;
  };

  return (
    <div className="magacin_child datatable-style-demo">
      <Toast ref={toast} />
      <div style={{ textAlign: "right", marginTop: "1%", marginRight: "1%" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
        <input
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Pretraga"
          size="50"
        />
      </div>
      {load && <Loading />}
      <DataTable
        value={magacin3}
        rowClassName={rowClass}
        paginator
        first={first}
        rows={12}
        globalFilter={globalFilter}
        onPage={(e) => setFirst(e.first)}
      >
        <Column sortable field="index" header="Rb"></Column>
        <Column sortable field="naziv_dela" header="Naziv"></Column>
        <Column sortable field="kategorija" header="Kategorija"></Column>
        <Column sortable field="podkategorija" header="Podkategorija"></Column>
        <Column sortable field="tip" header="Tip"></Column>
        <Column sortable field="cena" header="Cena[€]"></Column>
        <Column sortable field="niceDate" header="Datum"></Column>
        <Column
          sortable
          field="kolicina"
          header="Kolicina"
          body={stockBodyTemplate}
        ></Column>
        <Column sortable field="opis" header="Komentar"></Column>
      </DataTable>
    </div>
  );
};

export default Magacin3;
