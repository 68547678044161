import React, {useState, useEffect, useRef, useContext} from 'react';
import './servis.css';
import Serviced_vehicles from './Components/serviced_vehicles.js';
import { Toast } from 'primereact/toast';
import Loading from '../../Loading/loading';
import { AuthContext } from "../../Header/auth - context";
import { useSelector } from 'react-redux';

const Vozila_servis = () => {

    const company = useSelector(state => state.company)
    const toast = useRef(null);
    const [unique, setUnique] = useState([]);
    const [serviceLogs, setServiceLogs] = useState([]);
    const [load, setLoad] = useState(true);
    const auth = useContext(AuthContext);

    const handleErrors = (response) => {
        if (response.ok) {
         return response.json();
       } else {
         throw response;
       }
      }
        
       
        
        useEffect(()=>{

          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            },
            body: JSON.stringify({ 
              firma_id: company.companyId
             }),
          };
          let mounted = true; 

            fetch(process.env.REACT_APP_BACKEND_URL + '/service_logs/table', requestOptions)
            .then(handleErrors)
            .then(
              (result) => {
                if(mounted) {
                setServiceLogs(result);
                setLoad(false);}
              }).catch((error) => {
                error.json().then( errorMessage => {
                  toast.current.show({severity:'error', summary: 'Error', detail:errorMessage.message, life: 3000});
                  console.log(errorMessage);
                  setLoad(false);
                      })
              }); 
                let uniqueValues = serviceLogs.map(item => item.Garazni)
            .filter((value, index, self) => self.indexOf(value) === index)
            
                
                setUnique([...uniqueValues])
                return () => { mounted = false };
        },[load])
    

    return(
        <div className="servis_table">
            <Toast ref={toast}/>
            {load && <Loading/>}
            <div className="odrzavana_vozila">
                {unique.length > 0 ? unique.map((item, i)=>(
                        // eslint-disable-next-line react/jsx-pascal-case
                        <Serviced_vehicles
                        key={i}
                        largeData={serviceLogs}
                        data={item}
                        index={i}/>
                )): 'NEMA ODRZAVANIH VOZILA, NIŠTA NIJE ODRŽAVANO U SKORIJE VREME!!!'}
            </div>
        </div>
        
    )
}
export default Vozila_servis;