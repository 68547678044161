import React from "react";
import "./gorivo.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const Gorivo = () => {

  return (
    <div>
      OBrada
    </div>
  );
};

export default Gorivo;
