import React, { useState, useEffect, useRef, useContext } from "react";
import "./gorivo_svipodaci.css";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Loading from "../../../../Loading/loading";
import { AuthContext } from "../../../../Header/auth - context";
import { useSelector } from "react-redux";

const GorivoSviPodaci = () => {
  const company = useSelector(state => state.company)
  const toast = useRef(null);
  const auth = useContext(AuthContext);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [first, setFirst] = useState(0);
  const [allRecords, setAllRecords] = useState([]);
  const [load, setLoad] = useState(true);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/fuel/table", requestOptions)
      .then(handleErrors)
      .then((result) => {
        let _svasipanja = [];
        for (let i = 0; i < result.length; i++) {
          let sipanje;
          if (result[i].datum_sipanja === null) {
            sipanje = null;
          } else {
            let date = new Date(result[i].datum_sipanja);
            sipanje = date.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }

          let noviPodaci = {
            index: i+1,
            id: result[i].id,
            garazni_broj: result[i].garazni_broj,
            datum_sipanja: sipanje,
            pumpa: result[i].pumpa,
            kolicina: result[i].kolicina,
            kilometraza: result[i].kilometraza,
            komentar: result[i].komentar,
          };
          _svasipanja.push(noviPodaci);
        }
        setAllRecords(_svasipanja);
        setLoad(false)
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
          setLoad(false)
        });
      });
  }, []);

  return (
    <div id="zaposleni_main">
       {load && <Loading/>}
      <Toast ref={toast} />
      <div className="zaposleni_table">
        <div
          className="zaposleni_input"
          style={{ textAlign: "right", marginRight: "1%" }}
        >
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <input
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pretraga"
            size="50"
          />
        </div>
        <DataTable
          value={allRecords}
          paginator
          first={first}
          rows={12}
          globalFilter={globalFilter}
          onPage={(e) => setFirst(e.first)}
        >
          <Column sortable field="index" header="Rb"></Column>
          <Column sortable field="garazni_broj" header="Garažni"></Column>
          <Column sortable field="datum_sipanja" header="Datum sipanja"></Column>
          <Column sortable field="pumpa" header="Pumpa"></Column>
          <Column sortable field="kolicina" header="Količina"></Column>
          <Column
            sortable
            field="kilometraza"
            header="Kilometraža"
          ></Column>
  
          <Column sortable field="komentar" header="Komentar"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default GorivoSviPodaci;

