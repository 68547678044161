import React, { useState, useEffect, useRef, useContext } from "react";
import "./zaposleni.css";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Loading from "../../Loading/loading";
import { AuthContext } from "../../Header/auth - context";
import { useSelector } from "react-redux";

const Zaposleni = () => {
  const company = useSelector(state => state.company)
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [first, setFirst] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [load, setLoad] = useState(true);
  const auth = useContext(AuthContext);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  useEffect(() => {

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/employees/table", requestOptions)
      .then(handleErrors)
      .then((result) => {
        let _employees = [];
        for (let i = 0; i < result.length; i++) {
          let DPR;
          if (result[i].Datum_PR === null) {
            DPR = null;
          } else {
            let newDatePR = new Date(result[i].Datum_PR);
            newDatePR.setHours(newDatePR.getHours() + 2);
            DPR = newDatePR.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }
          let DZR;
          if (result[i].Datum_ZR === null) {
            DZR = null;
          } else {
            let newDateZR = new Date(result[i].Datum_ZR);
            newDateZR.setHours(newDateZR.getHours() + 2);
            DZR = newDateZR.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }
          let DPLP;
          if (result[i].Datum_PLP === null) {
            DPLP = null;
          } else {
            let newDatePLP = new Date(result[i].Datum_PLP);
            newDatePLP.setHours(newDatePLP.getHours() + 2);
            DPLP = newDatePLP.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }
          let DVVD;
          if (result[i].Datum_VVD === null) {
            DVVD = null;
          } else {
            let newDateVVD = new Date(result[i].Datum_VVD);
            newDateVVD.setHours(newDateVVD.getHours() + 2);
            DVVD = newDateVVD.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }
          let DVTK;
          if (result[i].Datum_VTK === null) {
            DVTK = null;
          } else {
            let newDateVTK = new Date(result[i].Datum_VTK);
            newDateVTK.setHours(newDateVTK.getHours() + 2);
            DVTK = newDateVTK.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }

          let newDateEmployee = {
            index: i+1,
            id: result[i].id,
            Ime: result[i].Ime,
            Prezime: result[i].Prezime,
            JMBG: result[i].JMBG,
            RadnoMesto: result[i].RadnoMesto,
            Datum_PR: DPR,
            Datum_ZR: DZR,
            Datum_PLP: DPLP,
            Datum_VVD: DVVD,
            Datum_VTK: DVTK,
            opis_komentar: result[i].opis_komentar,
          };
          _employees.push(newDateEmployee);
        }
        setEmployees(_employees);
        setLoad(false)
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
          setLoad(false)
        });
      });
  }, []);

  return (
    <div id="zaposleni_main">
       {load && <Loading/>}
      <div className="zaposleni_pregled">
        <p>ZAPOSLENI</p>
      </div>
      <Toast ref={toast} />
      <div className="zaposleni_table">
        <div
          className="zaposleni_input"
          style={{ textAlign: "right", marginRight: "1%" }}
        >
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <input
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pretraga"
            size="50"
          />
        </div>
        <DataTable
          value={employees}
          paginator
          first={first}
          rows={12}
          globalFilter={globalFilter}
          onPage={(e) => setFirst(e.first)}
        >
          <Column sortable field="index" header="Rb"></Column>
          <Column sortable field="Ime" header="Ime"></Column>
          <Column sortable field="Prezime" header="Prezime"></Column>
          <Column sortable field="JMBG" header="JMBG"></Column>
          <Column sortable field="RadnoMesto" header="Radno mesto"></Column>
          <Column
            sortable
            field="Datum_PR"
            header="Datum početka rada"
          ></Column>
          <Column
            sortable
            field="Datum_ZR"
            header="Datum završetka rada"
          ></Column>
          <Column
            sortable
            field="Datum_PLP"
            header="Datum poslednjeg lekarskog pregleda"
          ></Column>
          <Column
            sortable
            field="Datum_VVD"
            header="Datum važenja vozačke dozvole"
          ></Column>
          <Column
            sortable
            field="Datum_VTK"
            header="Datum važenja TAHO KARTICE"
          ></Column>
          <Column sortable field="opis_komentar" header="Komentar"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Zaposleni;
