import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import RadniNG from "./radniNG.js";
import Print from "./print";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { AuthContext } from "../../../../Header/auth - context";
import { CSSTransition } from "react-transition-group";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { useSelector } from "react-redux";

import "./unosradnihpopup.css";

const UnosRadnihPop = (props) => {
  const nodeRef = React.useRef(null);
  const toast = useRef(null);
  const auth = useContext(AuthContext);
  const company = useSelector((state) => state.company);
  const [window, setWindow] = useState(true);
  const [logRNToUpdate, setlogRNToUpdate] = useState(null);
  const [serviceLogNumber, setServiceLogNumber] = useState([]);
  const [stavkaRN, setStavkaRN] = useState([]);
  const [nalog, setNalog] = useState(null)
  const [datePI, setDatePI] = useState(null);
  const [dateZI, setDateZI] = useState(null);
  const [valueTASL, setValueTASL] = useState("");
  const [dropTypeService, setDropTypeService] = useState(null);
  const [serviceLog, setServiceLog] = useState("");
  const [stavkaGar, setStavkaGar] = useState(null);
  const [filteredGar, setFilteredGar] = useState([]);
  const [serviceLogsDB, setServiceLogsDB] = useState([]);
  const [filteredLog, setFilteredLog] = useState([]);
  const [vehicleDB, setVehicleDB] = useState([]);
  const [vehicleDBAll, setVehicleDBAll] = useState([]);
  const [filteredPart, setFilteredPart] = useState([]);
  const [stavkaPart, setStavkaPart] = useState(null);
  const [partDB, setPartDB] = useState([]);
  const [partDBAll, setPartDBAll] = useState([]);
  const [employeeDB, setEmployeeDB] = useState([]); 
  const [employeeDBAll, setEmployeeDBAll] = useState([]);
  const [filteredEmployeTake, setFilteredEmployeTake] = useState([]);
  const [stavkaEMTake, setStavkaEMTake] = useState([]);
  const [stavkaEMResponsible, setStavkaEMResponsible] = useState([]);
  const [filteredEmployeResponsible, setFilteredEmployeResponsible] = useState(
    []
  );
  const [dropMag, setDropMag] = useState(null);
  const [mag, setMag] = useState([]);

  const [milage, setMilage] = useState(null);
  const [amount, setAmount] = useState(null);
  const [render, setRender] = useState(false);

  const typeService = [
    { label: "Redovni", value: "Redovni" },
    { label: "Vanredni", value: "Vanredni" },
  ];

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  const handleDeleteStavkaRN = (index) => {
    if (index > -1) {
      const item = stavkaRN.slice();
      item.splice(index, 1);
      setStavkaRN(item);
    }
  };

  const handleEditStavkaRN = (index) => {
    console.log(index);

    setlogRNToUpdate(index);
    setServiceLog(index.brnaloga);
    let GR = vehicleDB.find((e) => e.id === index.garazniID);
    setStavkaGar(GR.garazni_broj);
    let TS = typeService.find((e) => e.value === index.odrzavanje);
    setDropTypeService(TS.value);
    let MG = mag.find((e) => e.value === index.magacin);
    setDropMag(MG.value);
    let PR = partDB.find((e) => e.id === index.deo);
    setStavkaPart(PR);
    let ZU = employeeDB.find((e) => e.id === index.zaposleniUD);
    setStavkaEMTake(ZU);
    let OM = employeeDB.find((e) => e.id === index.odgMajstor);
    setStavkaEMResponsible(OM);
    if (index.datumPO === null) {
      setDatePI(index.datumPO);
    } else {
      let newDatePI = new Date(index.datumPO);
      setDatePI(newDatePI);
    }
    if (index.datumZO === null) {
      setDateZI(index.datumZO);
    } else {
      let newDateZI = new Date(index.datumZO);
      setDateZI(newDateZI);
    }
    setValueTASL(index.opis);
    setMilage(index.kilometraza);
    setAmount(index.kolicina);
  };
  const openWindowPrint = () => {
    if(stavkaRN.length === 0){
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje",
        detail: 'Morate prvo selektovati nalog koji želite odštampari!',
        life: 3000,
      });
    } else {
      setNalog(stavkaRN[0].brnaloga)
      setWindow(false)
    }
  }
  const closeWindowPrint = () => {
    setWindow(true)
  }

  const handleInputSL = () => {
    const stavkaRN = {
      id: "",
      brnaloga: "",
      garazniID: "",
      odrzavanje: "",
      magacin: "",
      deo: "",
      zaposleniUD: "",
      odgMajstor: "",
      datumPO: "",
      datumZO: "",
      kilometraza: "",
      kolicina: "",
      opis: "",
    };
    let opis = document.getElementsByClassName("opisr")[0].value;

    if (
      serviceLog !== "" &&
      stavkaGar !== "" &&
      dropTypeService !== "" &&
      dropMag !== null &&
      stavkaPart !== "" &&
      stavkaEMTake !== "" &&
      stavkaEMResponsible !== "" &&
      datePI !== null &&
      amount !== null &&
      milage !== null
    ) {
      if (typeof serviceLog === "object") {
        stavkaRN.brnaloga = serviceLog.Broj_radnog_naloga;
      } else {
        stavkaRN.brnaloga = serviceLog;
      }
      if (typeof stavkaGar === "object") {
        stavkaRN.garazniID = stavkaGar.id;
      } else {
        let GR = vehicleDB.find((e) => e.garazni_broj === stavkaGar);
        stavkaRN.garazniID = GR.id;
      }
      stavkaRN.odrzavanje = dropTypeService;
      stavkaRN.magacin = dropMag;
      stavkaRN.deo = stavkaPart.id;
      stavkaRN.zaposleniUD = stavkaEMTake.id;
      stavkaRN.odgMajstor = stavkaEMResponsible.id;
      if (datePI === null) {
        stavkaRN.datumPO = datePI;
      } else {
        let newDatePI = new Date(datePI);
        newDatePI.setHours(newDatePI.getHours() + 2);
        stavkaRN.datumPO = newDatePI.toISOString().substr(0, 10);
      }
      if (dateZI === null) {
        stavkaRN.datumZO = dateZI;
      } else {
        let newDateZI = new Date(dateZI);
        newDateZI.setHours(newDateZI.getHours() + 2);
        stavkaRN.datumZO = newDateZI.toISOString().substr(0, 10);
      }
      
      stavkaRN.kilometraza = milage;
      stavkaRN.kolicina = amount;
      stavkaRN.opis = opis;

      setStavkaRN((item) => [...item, stavkaRN]);
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste uneli sve podatke za Radni nalog!",
        life: 3000,
      });
    }
  };

  const handleUpdateSL = () => {
    let opisRN = document.getElementsByClassName("opisr")[0].value;
    const updateNalog = {
      id: "",
      brnaloga: "",
      garazniID: "",
      odrzavanje: "",
      magacin: "",
      deo: "",
      zaposleniUD: "",
      odgMajstor: "",
      datumPO: "",
      datumZO: "",
      kilometraza: "",
      kolicina: "",
      opis: "",
    };
    if (
      serviceLog !== "" &&
      stavkaGar !== null &&
      dropTypeService !== null &&
      dropMag !== null &&
      stavkaPart !== "" &&
      stavkaEMTake !== "" &&
      stavkaEMResponsible !== "" &&
      datePI !== null &&
      amount !== null &&
      milage !== null
    ) {
      updateNalog.id = logRNToUpdate.id;
      updateNalog.brnaloga = serviceLogNumber;
      if (typeof stavkaGar === "object") {
        updateNalog.garazniID = stavkaGar.id;
      } else {
        let GR = vehicleDB.find((e) => e.garazni_broj === stavkaGar);
        updateNalog.garazniID = GR.id;
      }
      updateNalog.odrzavanje = dropTypeService;
      updateNalog.magacin = dropMag;
      updateNalog.deo = stavkaPart.id;
      updateNalog.zaposleniUD = stavkaEMTake.id;
      updateNalog.odgMajstor = stavkaEMResponsible.id;
      if (datePI === null) {
        updateNalog.datumPO = datePI;
      } else {
        let newDatePI = new Date(datePI);
        newDatePI.setHours(newDatePI.getHours() + 2);
        updateNalog.datumPO = newDatePI.toISOString().substr(0, 10);
      }
      if (dateZI === null) {
        updateNalog.datumZO = dateZI;
      } else {
        let newDateZI = new Date(dateZI);
        newDateZI.setHours(newDateZI.getHours() + 2);
        updateNalog.datumZO = newDateZI.toISOString().substr(0, 10);
      }
      updateNalog.kilometraza = milage;
      updateNalog.kolicina = amount;
      updateNalog.opis = opisRN;

      let requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          id: updateNalog.id,
          broj_radnog_naloga: updateNalog.brnaloga,
          garazni_id: updateNalog.garazniID,
          tip_odrzavanja: updateNalog.odrzavanje,
          magacin_id: updateNalog.magacin,
          deo_id: updateNalog.deo,
          zaposleni_UD_id: updateNalog.zaposleniUD,
          odgovorni_majstor_id: updateNalog.odgMajstor,
          datum_PO: updateNalog.datumPO,
          datum_ZO: updateNalog.datumZO,
          kilometraza: updateNalog.kilometraza,
          kolicina: updateNalog.kolicina,
          old_kolicina: logRNToUpdate.kolicina,
          opis_komentar: updateNalog.opis,
        }),
      };
      if (logRNToUpdate !== null) {
        fetch(
          process.env.REACT_APP_BACKEND_URL + "/service_logs",
          requestOptions
        )
          .then(handleErrors)
          .then((data) =>
            toast.current.show({
              severity: "success",
              summary: "Ažuriranje uspešno!",
              detail: data.message,
              life: 3000,
            })
          )
          .catch((error) => {
            error.json().then((errorMessage) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMessage.message,
                life: 3000,
              });
              console.log(errorMessage);
            });
          });
      } else {
        console.log("some value problem");
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste uneli sve podatke za Radni nalog!",
        life: 3000,
      });
    }

    setStavkaGar(null);
    setDropTypeService(null);
    setDropMag(null);
    setStavkaPart(null);
    setStavkaEMTake(null);
    setStavkaEMResponsible(null);
    setDatePI(null);
    setDateZI(null);
    setValueTASL([]);
    setMilage(null);
    setAmount(null);
    let ind = stavkaRN.indexOf(logRNToUpdate);
    stavkaRN[ind] = updateNalog;
  };

  const handleDeleteSL = () => {
    if (logRNToUpdate !== null) {
      let requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          id: logRNToUpdate.id,
          partID: logRNToUpdate.deo,
          kolicinaPart: logRNToUpdate.kolicina,
        }),
      };

      fetch(
        process.env.REACT_APP_BACKEND_URL + "/service_logs/service_log_row",
        requestOptions
      )
        .then(handleErrors)
        .then((result) => {
          toast.current.show({
            severity: "success",
            summary: `Uspešno brisanje stavke radnog naloga ID = ${logRNToUpdate.id}!`,
            detail: result.message,
            life: 3000,
          })
          deleteContentWorkLog();
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste izabrali stavku naloga koju želite da obrišete!",
        life: 3000,
      });
    }
    handleDeleteStavkaRN(stavkaRN.indexOf(logRNToUpdate));

    setStavkaGar(null);
    setDropTypeService(null);
    setDropMag(null);
    setStavkaPart(null);
    setStavkaEMTake(null);
    setStavkaEMResponsible(null);
    setDatePI(null);
    setDateZI(null);
    setValueTASL([]);
    setMilage(null);
    setAmount(null);
  };

  const handleConfirmDeleteLogDialog = () => {
    confirmDialog({
      message: "Jel ste sigurni da želite obrisati stavku radnog naloga?",
      acceptLabel: "Da",
      rejectLabel: "Ne",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  const accept = async () => {
    await handleDeleteSL();
  };
  const reject = () => {
    console.log("REJECTED DELETION!");
  };

  const handleInputServiceLogDBHandler = () => {
    if(stavkaRN.length > 0){

    for (let i = 0; i < stavkaRN.length; i++) {
      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          firma_id: company.companyId,
          broj_radnog_naloga: stavkaRN[i].brnaloga,
          garazni_id: stavkaRN[i].garazniID,
          tip_odrzavanja: stavkaRN[i].odrzavanje,
          magacin_id: stavkaRN[i].magacin,
          deo_id: stavkaRN[i].deo,
          zaposleni_UD_id: stavkaRN[i].zaposleniUD,
          odgovorni_majstor_id: stavkaRN[i].odgMajstor,
          datum_PO: stavkaRN[i].datumPO,
          datum_ZO: stavkaRN[i].datumZO,
          kilometraza: stavkaRN[i].kilometraza,
          kolicina: stavkaRN[i].kolicina,
          opis_komentar: stavkaRN[i].opis,
        }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/service_logs/insertServiceLog", requestOptions)
        .then(handleErrors)
        .then((data) => {
          toast.current.show({
            severity: "success",
            summary: "Unos uspešan!",
            detail: data.message,
            life: 3000,
          });
          setStavkaRN([]);
          deleteContentWorkLog();
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: 'Nemate unetu ni jednu stavku. Nakon dodavanja stavke pokušajte unos ponovo.',
        life: 3000,
      });
    }
  };

  const fillStavkaRN = (e) => {
    setStavkaRN([]);
    let values = serviceLogsDB.filter((s) => s.Broj_radnog_naloga === e);
    setServiceLogNumber(e);

    for (let i = 0; i < values.length; i++) {
      const rNalog = {
        id: "",
        brnaloga: "",
        garazniID: "",
        odrzavanje: "",
        magacin: "",
        deo: "",
        zaposleniUD: "",
        odgMajstor: "",
        datumPO: "",
        datumZO: "",
        kilometraza: "",
        kolicina: "",
        opis: "",
      };

      rNalog.id = values[i].id;
      rNalog.brnaloga = values[i].Broj_radnog_naloga;
      rNalog.garazniID = values[i].Garazni_id;
      rNalog.odrzavanje = values[i].Tip_odrzavanja;
      rNalog.magacin = values[i].Magacin_id;
      rNalog.deo = values[i].Deo_id;
      rNalog.zaposleniUD = values[i].Zaposleni_UD_id;
      rNalog.odgMajstor = values[i].Odgovorni_majstor_id;
      rNalog.datumPO = values[i].Datum_PO;
      rNalog.datumZO = values[i].Datum_ZO;
      rNalog.kilometraza = values[i].Kilometraza;
      rNalog.kolicina = values[i].Kolicina;
      rNalog.opis = values[i].opis_komentar;

      setStavkaRN((item) => [...item, rNalog]);
    }
  };

  useEffect(() => {

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };

    fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setVehicleDB(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
      fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles/all", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setVehicleDBAll(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
    fetch(process.env.REACT_APP_BACKEND_URL + "/service_logs", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setServiceLogsDB(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
      fetch(process.env.REACT_APP_BACKEND_URL + "/part/SL", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setPartDB(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
    fetch(process.env.REACT_APP_BACKEND_URL + "/part/SL_all", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setPartDBAll(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
    fetch(process.env.REACT_APP_BACKEND_URL + "/employees", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setEmployeeDB(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
      fetch(process.env.REACT_APP_BACKEND_URL + "/employees/all", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setEmployeeDBAll(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
    fetch(process.env.REACT_APP_BACKEND_URL + "/part/warehouse", requestOptions)
      .then(handleErrors)
      .then((result) => {
        let warehouse = [];
        for (let ware of result) {
          let _ware = {
            label: ware.magacin,
            value: ware.id,
          };
          warehouse.push(_ware);
        }
        setMag(warehouse);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
  }, [logRNToUpdate, stavkaRN, props.render, render]);

  const searchGar = (event) => {
    let query = event.query;
    let _filteredGar = [];
    let filteredItems = vehicleDB.filter(
      (item) =>
        item.garazni_broj.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (filteredItems && filteredItems.length) {
      _filteredGar.push(...filteredItems);
    }

    setFilteredGar(_filteredGar);
  };
  const searchServiceLog = (event) => {
    let query = event.query;
    let _filteredLog = [];
    let uniqueValues = serviceLogsDB
      .map((item) => item.Broj_radnog_naloga)
      .filter((value, index, self) => self.indexOf(value) === index);
    let filteredItems = uniqueValues.filter(
      (item) => item.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (filteredItems && filteredItems.length) {
      _filteredLog.push(...filteredItems);
    }

    setFilteredLog(_filteredLog);
  };
  const searchEmployeeTake = (event) => {
    let query = event.query;
    let _filteredEMTake = [];
    let filteredItems = employeeDB.filter(
      (item) =>
        item.Ime_i_prezime.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (filteredItems && filteredItems.length) {
      _filteredEMTake.push(...filteredItems);
    }

    setFilteredEmployeTake(_filteredEMTake);
  };
  const searchEmployeeResponsible = (event) => {
    let query = event.query;
    let _filteredEMRes = [];
    let filteredItems = employeeDB.filter(
      (item) =>
        item.Ime_i_prezime.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (filteredItems && filteredItems.length) {
      _filteredEMRes.push(...filteredItems);
    }

    setFilteredEmployeResponsible(_filteredEMRes);
  };
  const searchPart = (event) => {
    
    let query = event.query;
    let newPartperMAG = [];

    let filteredItemsNEW = partDB.filter((item) => item.magacin_id === dropMag);
    if (filteredItemsNEW && filteredItemsNEW.length) {
      newPartperMAG.push(...filteredItemsNEW);
    }

    if (dropMag !== null) {
      let _filteredPart = [];
      let filteredItems = newPartperMAG.filter(
        (item) =>
          item.naziv_dela.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
      if (filteredItems && filteredItems.length) {
        _filteredPart.push(...filteredItems);
      }

      setFilteredPart(_filteredPart);
    } else {
      setStavkaPart(null);
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Morate prvo izabrati magacin!",
        life: 3000,
      });
    }
  };

  const setKolicinaFUNC = (e) => {
    setAmount(e.value.kolicina);
  };
  const deleteContentWorkLog = () => {
    setServiceLog(null);
    setStavkaGar(null);
    setDropTypeService(null);
    setDropMag(null);
    setStavkaPart(null);
    setStavkaEMTake(null);
    setStavkaEMResponsible(null);
    setDatePI(null);
    setDateZI(null);
    setValueTASL("");
    setMilage(null);
    setAmount(null);
    setlogRNToUpdate(null);
    if (render === true) {
      setRender(false);
    } else {
      setRender(true);
    }
  }
  const handleClose = () =>{
    deleteContentWorkLog();
    setlogRNToUpdate(null);
    setStavkaRN([]);
    props.close();
  }
  
  const content = (
    <CSSTransition
      in={props.show}
      timeout={300}
      classNames="slide-in-top"
      mountOnEnter
      unmountOnExit
      nodeRef={nodeRef}
    >
      <div id="unos_d_popup" ref={nodeRef}>
        <section>
          <div className="header_popup" id="header_popup_r">
            <h2>UNOS RADNIH NALOGA</h2>
            <Button
              onClick={handleClose}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger"
            />
            <Button
            onClick={openWindowPrint}
            style={{right: "24.5%", paddingLeft: "8px", paddingRight: "8px", height: "fit-content"}}
              className="p-button-rounded p-button" id="print_button"
            ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="currentColor" className="bi bi-printer-fill" viewBox="0 0 16 16">
            <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>
            <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
          </svg></Button>
          </div>
  
          <Toast ref={toast} />

          <div className="main_popup_r">
            <form onSubmit={(event) => event.preventDefault()}>
              <AutoComplete
                value={serviceLog}
                style={{ marginLeft: "2%" }}
                onChange={(e) => setServiceLog(e.value)}
                className="registracija treciRedv serviceLog"
                placeholder="Broj radnog naloga"
                required
                suggestions={filteredLog}
                completeMethod={searchServiceLog}
                onSelect={(e) => fillStavkaRN(e.value)}
              />
              <AutoComplete
                style={{ width: "30.65%" }}
                className="treciRedr garazni"
                value={stavkaGar}
                placeholder="Garažni broj"
                required
                suggestions={filteredGar}
                completeMethod={searchGar}
                field="garazni_broj"
                onChange={(e) => setStavkaGar(e.value)}
              />
              <Dropdown
                value={dropTypeService}
                options={typeService}
                required
                onChange={(e) => setDropTypeService(e.value)}
                placeholder="Izaberi tip održavanja"
                className="treciRedr tipr"
              />
              <Dropdown
                value={dropMag}
                style={{ marginBottom: "0" }}
                options={mag}
                required
                onChange={(e) => setDropMag(e.value)}
                placeholder="Izaberi magacin"
                className="treciRedr magacinr"
              />
              <AutoComplete
                style={{ width: "63.30%" }}
                className="treciRedr deo"
                value={stavkaPart}
                placeholder="Izaberi deo"
                required
                suggestions={filteredPart}
                completeMethod={searchPart}
                field="naziv_dela"
                onSelect={setKolicinaFUNC}
                onChange={(e) => setStavkaPart(e.value)}
              />
              <AutoComplete
                style={{ width: "30.65%" }}
                className="treciRedr zaposleniUD"
                value={stavkaEMTake}
                placeholder="Zaposleni uzeo?"
                required
                suggestions={filteredEmployeTake}
                completeMethod={searchEmployeeTake}
                field="Ime_i_prezime"
                onChange={(e) => setStavkaEMTake(e.value)}
              />
              <AutoComplete
                style={{ width: "30.65%" }}
                className="treciRedr odgMajstor"
                value={stavkaEMResponsible}
                placeholder="Odgovorni majstor?"
                required
                suggestions={filteredEmployeResponsible}
                completeMethod={searchEmployeeResponsible}
                field="Ime_i_prezime"
                onChange={(e) => setStavkaEMResponsible(e.value)}
              />
              <Calendar
                value={datePI}
                style={{ float: "left", width: "14.33%" }}
                inputStyle={{ float: "left" }}
                onChange={(e) => setDatePI(e.value)}
                showIcon
                dateFormat="dd.mm.yy"
                className="treciRedz datumPI"
                placeholder="Početak intervencije"
                tooltip="Početak intervencije" 
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
              ></Calendar>
              <Calendar
                value={dateZI}
                style={{ float: "left", width: "14.33%" }}
                inputStyle={{ float: "left" }}
                onChange={(e) => setDateZI(e.value)}
                showIcon
                dateFormat="dd.mm.yy"
                className="treciRedz datumZI"
                placeholder="Završetak intervencije"
                tooltip="Završetak intervencije" 
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
              ></Calendar>
              <InputTextarea
                style={{
                  float: "left",
                  marginTop: "0",
                  width: "79.62%",
                  height: "95px",
                }}
                rows={3}
                cols={30}
                value={valueTASL}
                className="opisr"
                onChange={(e) => setValueTASL(e.value)}
                placeholder="Opis / komentar"
              />

              <InputNumber
                value={milage}
                style={{ marginLeft: "0", width: "14.33%", display: "inline" }}
                onValueChange={(e) => setMilage(e.value)}
                placeholder="Kilometraza"
                tooltip="Kilometraža"
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
                className="treciRedv kilometraza"
                required
              />
              <InputNumber
                value={amount}
                style={{ marginLeft: "0", width: "14.33%", display: "inline" }}
                onValueChange={(e) => setAmount(e.value)}
                placeholder="Količina"
                className="treciRedv količina"
                required
              />

              <div className="buttonsUR">
                <button className="btn btn-secondary" onClick={handleInputSL}>
                  +STAVKA
                </button>
                <button className="btn btn-warning" onClick={handleUpdateSL}>
                  AŽURIRAJ
                </button>
                <button
                  className="btn btn-danger"
                  onClick={handleConfirmDeleteLogDialog}
                >
                  OBRIŠI
                </button>
              </div>
            </form>
          </div>
          <div className="second_popup_r">
            <div className="radni_nalog_stavka">
              {stavkaRN.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Broj RN</th>
                      <th>Garažni</th>
                      <th>Deo</th>
                      <th>Zaposleni U.D.</th>
                      <th>Odgovorni Maj.</th>
                      <th>Količina</th>
                      <th>-</th>
                      <th>-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stavkaRN.map((item, i) => (
                      <RadniNG
                        key={i}
                        nalog={item}
                        index={i}
                        edit={handleEditStavkaRN}
                        delete={handleDeleteStavkaRN}
                        employeeDB={employeeDBAll}
                        partDB={partDBAll}
                        vehicleDB={vehicleDBAll}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                "Niste uneli ni jednu stavku!"
              )}
            </div>

            <div className="form_r">
              <button
                className="btn btn-success"
                onClick={handleInputServiceLogDBHandler}
              >
                UNOS
              </button>
            </div>
          </div>
        </section>
      </div>
    </CSSTransition>
  );  

  const content2 = (
    <CSSTransition
    in={props.show}
    timeout={300}
    classNames="slide-in-top"
    mountOnEnter
    unmountOnExit
    nodeRef={nodeRef}>
     
      <div id="unos_d_popup" ref={nodeRef}>
      <Toast ref={toast} />
        <section onClick={closeWindowPrint} style={{width: "100%", height: "100%", border: "none"}}>
          <div className="header_popup">
          <h2>RADNI NALOG - {nalog === null ? "nema broja naloga" : nalog}</h2>
          </div>
        
            <div className="radni_nalog_stavka">
              {stavkaRN.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Garažni</th>
                      <th>Deo</th>
                      <th>Zaposleni U.D.</th>
                      <th>Odgovorni Maj.</th>
                      <th>Količina</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stavkaRN.map((item, i) => (
                      <Print
                        key={i}
                        nalog={item}
                        index={i}
                        edit={handleEditStavkaRN}
                        delete={handleDeleteStavkaRN}
                        employeeDB={employeeDBAll}
                        partDB={partDBAll}
                        vehicleDB={vehicleDBAll}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                "Niste uneli ni jednu stavku!"
              )}
            </div>
      
        </section>
      </div>
    </CSSTransition>
  );
  return ReactDOM.createPortal(window ? content : content2, document.getElementById("urn-hook"));
};

export default UnosRadnihPop;
