export function processSmallDayRest(records, company, driversDB, isGradski) {

    let count = 0;

    const arrayWorkDay = [];
    let workDay = {
        driver: 0,
        startTime: 0,
        endTime: 0,
        avail: 0
    };
    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatSRBTime(stringDate) {
      const date = new Date(stringDate);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
      return formattedDate
    }

    for(let n=0; n<driversDB.length;n++){
      let driver = driversDB[n];
      let filtered = records.filter(item => item.DriverId === driver.Id)
      
    for (let m = 0; m < filtered.length; m++) {
        const record = filtered[m];
        const nextRecord = filtered[m + 1];
        const previousRecord = filtered[m - 1];

        if (record.Value.toLowerCase() === 'driving' || record.Value.toLowerCase() === 'work' || record.Value.toLowerCase() === 'availability') {
                                if(record.Value.toLowerCase() === 'work'){

                                      if(record.TotalTime < 5 * 60 * 60 * 1000){
                                                  if(!previousRecord) {
                                                          workDay.driver = record.DriverId;
                                                          workDay.startTime = record.StartTime;
                                                          workDay.endTime = record.EndTime;
                                                      } else {
                                                              if(!nextRecord){
                                                                if (workDay.startTime === 0) {
                                                                        workDay.driver = record.DriverId;
                                                                        workDay.startTime = record.StartTime;
                                                                        workDay.endTime = record.EndTime;
                                                                        arrayWorkDay.push({ ...workDay });
                                                                        workDay = {
                                                                                      driver: 0,
                                                                                      startTime: 0,
                                                                                      endTime: 0,
                                                                                      avail: 0
                                                                                      };
                                                                    } else {
                                                                      if(nextRecord.Value.toLowerCase() === 'work'){
                                                                        const startTime = new Date(workDay.endTime).getTime();
                                                                        const endTime = new Date(nextRecord.StartTime).getTime();
                                                                        const timeDifference = endTime - startTime;
                                                                        if(timeDifference > 7 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                                          continue;
                                                                        } else {
                                                                          workDay.endTime = record.EndTime;
                                                                        }
                                                                      } else {
                                                                        workDay.endTime = record.EndTime;
                                                                      }
                                                                    }
                                                          } else {
                                                            if(workDay.startTime === 0) {
                                                              workDay.driver = record.DriverId;
                                                              workDay.startTime = record.StartTime;
                                                              workDay.endTime = record.EndTime;
                                                            } else {
                                                              workDay.endTime = record.EndTime;
                                                            }
                                                            
                                                          }
                                                    } 
                                       } else {
                                                  if(previousRecord) {
                                                      if(previousRecord.Value.toLowerCase() === 'work') {
                                                        const startTime = new Date(previousRecord.endTime).getTime();
                                                        const endTime = new Date(record.EndTime).getTime();
                                                        const timeDifference = endTime - startTime;
                                                        if(timeDifference > 7 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                          continue;
                                                        } else {
                                                          workDay.endTime = record.EndTime;
                                                        }
                                                          }
                                                           else {
                                                            continue
                                                          }         
                                                } else {
                                                  if(!nextRecord) {
                                                    arrayWorkDay.push({ ...workDay });
                                                            workDay = {
                                                            driver: 0,
                                                            startTime: 0,
                                                            endTime: 0,
                                                            avail: 0
                                                            };
                                                   
                                                  } else { 
                                                  continue
                                                  }         

                                                }
                                        } 

                                           
                                      
                                          
                                             
                                } else if (record.Value.toLowerCase() === 'availability') {
                                  if(record.TotalTime < 5 * 60 * 60 * 1000){
                                    if(!previousRecord) {
                                            workDay.driver = record.DriverId;
                                            workDay.startTime = record.StartTime;
                                            workDay.endTime = record.EndTime;
                                            workDay.avail += parseInt(record.TotalTime);
                                        } else {
                                                if(!nextRecord){
                                                  if (workDay.startTime === 0) {
                                                          workDay.driver = record.DriverId;
                                                          workDay.startTime = record.StartTime;
                                                          workDay.endTime = record.EndTime;
                                                          workDay.avail += parseInt(record.TotalTime);
                                                          arrayWorkDay.push({ ...workDay });
                                                          workDay = {
                                                                        driver: 0,
                                                                        startTime: 0,
                                                                        endTime: 0,
                                                                        avail: 0
                                                                        };
                                                      } else {
                                                        workDay.driver = record.DriverId;
                                                        workDay.endTime = record.EndTime;
                                                        workDay.avail += parseInt(record.TotalTime);
                                                        arrayWorkDay.push({ ...workDay });
                                                        workDay = {
                                                                      driver: 0,
                                                                      startTime: 0,
                                                                      endTime: 0,
                                                                      avail: 0
                                                                      };
                                                      }
                                            }else {
                                              workDay.avail += parseInt(record.TotalTime);
                                              if(workDay.startTime === 0) {
                                                workDay.driver = record.DriverId;
                                                workDay.startTime = record.StartTime;
                                                workDay.endTime = record.EndTime;
                                                
                                              } else {
                                                workDay.endTime = record.EndTime;
                                          
                                              }
                                            }
                                      } 
                                    } else {
                                            if(previousRecord) {
                                                if(previousRecord.Value.toLowerCase() === 'availability') {
                                                  const startTime = new Date(previousRecord.EndTime).getTime();
                                                  const endTime = new Date(record.EndTime).getTime();
                                                  const timeDifference = endTime - startTime;
                                                  if(timeDifference > 7 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                    continue;
                                                  } else {
                                                    workDay.endTime = record.EndTime;
                                                  }
                                                    } else {
                                                      workDay.avail += parseInt(record.TotalTime);
                                                    }         
                                          } else {
                                            workDay.avail += parseInt(record.TotalTime);
                                            if(!nextRecord) {
                                              arrayWorkDay.push({ ...workDay });
                                                      workDay = {
                                                      driver: 0,
                                                      startTime: 0,
                                                      endTime: 0,
                                                      avail: 0
                                                      };
                                            
                                            } else { 
                                            continue
                                            }         

                                      }
                                  } 
                                } else if (record.Value.toLowerCase() === 'driving') {
                                        if (workDay.startTime === 0) {
                                          workDay.driver = record.DriverId;
                                          workDay.startTime = record.StartTime;
                                          workDay.endTime = record.EndTime;
                                      } else {
                                        workDay.endTime = record.EndTime;
                                      }
                                }

           
        } else if (record.Value.toLowerCase() === 'break/rest') {
            
          if(!previousRecord) {
            continue;
          } else {
            if (workDay.startTime !== 0) {
              if(!nextRecord) {
                // if(previousRecord.Value.toLowerCase() === 'break/rest' || previousRecord.Value.toLowerCase() === 'unknown'){

                // }
                arrayWorkDay.push({ ...workDay });
                                workDay = {
                                    driver: 0,
                                    startTime: 0,
                                    endTime: 0,
                                    avail: 0
                                }; 
                } else {

                  if(nextRecord.Value.toLowerCase() === 'break/rest') {
                
                     if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) > 5 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                          arrayWorkDay.push({ ...workDay });
                          workDay = {
                              driver: 0,
                              startTime: 0,
                              endTime: 0,
                              avail: 0
                          };
                     }
                  } else {
                    const startTime = new Date(workDay.endTime).getTime();
                    const endTime = new Date(nextRecord.StartTime).getTime();
                    const timeDifference = endTime - startTime;
                    if (timeDifference <= 5 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                      continue;
                    } else {
                      arrayWorkDay.push({ ...workDay });
                      workDay = {
                          driver: 0,
                          startTime: 0,
                          endTime: 0,
                          avail: 0
                      };
                    }   
                  }
              }
            }
          }


        } else if (record.Value.toLowerCase() === 'unknown') {
              if(nextRecord){
                if(nextRecord.Value.toLowerCase() === 'unknown') {
                  if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) < 5 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                    continue;
                  } else {
                    arrayWorkDay.push({ ...workDay });
                    workDay = {
                        driver: 0,
                        startTime: 0,
                        endTime: 0,
                        avail: 0
                    };
                  }
                } else {
                  if(parseInt(record.TotalTime) < 5 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                    continue;
                  }
                }
              
              } else {
                if(record.TotalTime < 1 * 60 * 60 * 1000) {
                  continue;
                } else {
                  if (!nextRecord || nextRecord.Value.toLowerCase() === 'driving' || nextRecord.Value.toLowerCase() === 'work' || nextRecord.Value.toLowerCase() === 'availability') {
                  
                    if (workDay.startTime !== 0) {
                        arrayWorkDay.push({ ...workDay });
                        workDay = {
                            driver: 0,
                            startTime: 0,
                            endTime: 0,
                            avail: 0
                        };
                    }
                }
                }
              }
       
         
        }
    }
    }
 

    const calculate9orLess = (data) => {
     
      let arrayFaults = [];

      for(let i=0; i<driversDB.length;i++){
          let driver = driversDB[i];
          let filtered = data.filter(item => item.driver === driver.Id)
          for (let j = 0; j < filtered.length; j++) { 

            const record = filtered[j];
            const nextRecord = filtered[j+1];
            const dayStart = new Date(record.startTime).getTime()
            const dayEnd = new Date(record.endTime).getTime()
            const startWorkDay =  new Date(record.startTime).getTime();
            const endWorkDay =  new Date(record.endTime).getTime();
            const timeDifferenceDay = endWorkDay - startWorkDay;
            const hoursDay = String(Math.floor(timeDifferenceDay / (60 * 60 * 1000))).padStart(2, '0');
            const minutesDay = String(Math.floor((timeDifferenceDay % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
         
            if(nextRecord){
              const startWork =  new Date(record.startTime).getTime();
              const endWork =  new Date(nextRecord.endTime).getTime();
              const startTimeRest = new Date(record.endTime).getTime();
              const endTimeRest = new Date(nextRecord.startTime).getTime();
              
              // const day = nextRecord.startTime.split("T")[0];
              const timeDifference = endTimeRest - startTimeRest;
              const timeDifferenceWork = endWork - startWork;
             

              if(timeDifferenceDay > 15 * 60 * 60 * 1000){
               
            

                if(isGradski) {
                  if(dayEnd - dayStart > 36 * 60 * 60 * 1000) {
                    count++
                    let faultData = {
                      Prekršaj: count,
                      Vozač: findDriver(record.driver),
                      Kompanija: company.Name,
                      Poruka: `Nema devetku u 2 dana za redom. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursDay}:${minutesDay}!`
                    }
                    arrayFaults.push(faultData)
                  } else {
                    count++
                    let faultData = {
                      Prekršaj: count,
                      Vozač: findDriver(record.driver),
                      Kompanija: company.Name,
                      Poruka: `Nema devetku u 24h. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursDay}:${minutesDay}!`
                    }
                    arrayFaults.push(faultData)
                  }
        
                } else {
                  if(dayEnd - dayStart > 36 * 60 * 60 * 1000) {
                    count++
                    let faultData = {
                      Prekršaj: count,
                      Vozač: findDriver(record.driver),
                      Kompanija: company.Name,
                      Poruka: `Nema devetku u 2 dana za redom. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursDay}:${minutesDay}!`
                    }
                    arrayFaults.push(faultData)
                  } else {
                    if(timeDifferenceDay > 15 * 60 * 60 * 1000 && timeDifferenceDay <= 21 * 60 * 60 * 1000 + 15 * 60 * 1000) {
                      if(record.avail  <= 45 * 60 * 1000) {
                        count++
                        let faultData = {
                          Prekršaj: count,
                          Vozač: findDriver(record.driver),
                          Kompanija: company.Name,
                          Poruka: `Nema devetku u 24h. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursDay}:${minutesDay}!`
                        }
                        arrayFaults.push(faultData)
                      }
                    } else if(timeDifferenceDay > 21 * 60 * 60 * 1000 + 15 * 60 * 1000) {
                      count++
                      let faultData = {
                        Prekršaj: count,
                        Vozač: findDriver(record.driver),
                        Kompanija: company.Name,
                        Poruka: `Nema devetku u 24h. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursDay}:${minutesDay}!`
                      }
                      arrayFaults.push(faultData)
                    }
                  }
                }
              } else {

                if(timeDifference < 9 * 60 * 60 * 1000) {
              
                  const hours = String(Math.floor(timeDifference / (60 * 60 * 1000))).padStart(2, '0');
                  const minutes = String(Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                  
                  if(timeDifferenceWork >= 15 * 60 * 60 * 1000) {
                    const dayStart = record.endTime.split("T")[0];
                    const dayEnd = nextRecord.startTime.split("T")[0];
                    if(dayStart == dayEnd) {
                      
                      if(timeDifferenceWork >= 15 * 60 * 60 * 1000) {
                        if(isGradski) {
                          count++
                          let faultData = {
                            Prekršaj: count,
                            Vozač: findDriver(record.driver),
                            Kompanija: company.Name,
                            Poruka: `Nema devetku u 24h. Prekoračenje radnog dana ${formatSRBTime(record.startTime)}, radni dan trajao ${hours}:${minutes}!`
                          }
                          arrayFaults.push(faultData)
                        } else {
                          if(timeDifferenceWork >= 21 * 60 * 60 * 1000) {
                            count++
                            let faultData = {
                              Prekršaj: count,
                              Vozač: findDriver(record.driver),
                              Kompanija: company.Name,
                              Poruka: `Nema devetku u 24h. Prekoračenje radnog dana ${formatSRBTime(record.startTime)}, radni dan trajao ${hours}:${minutes}!`
                            }
                            arrayFaults.push(faultData)
                        } else if(timeDifferenceWork >= 15 * 60 * 60 * 1000 && timeDifferenceWork < 21 * 60 * 60 * 1000){
                              if(record.avail + nextRecord.avail   < 3 * 60 * 60 * 1000) {
                                count++
                                let faultData = {
                                  Prekršaj: count,
                                  Vozač: findDriver(record.driver),
                                  Kompanija: company.Name,
                                  Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(record.startTime)}, trajao ${hours}:${minutes}!`
                                }
                                arrayFaults.push(faultData)
                            }
                        }
                        }
                       
                      }
                    } else {
                      if(timeDifferenceWork >= 15 * 60 * 60 * 1000) {
                        if(isGradski){
                          count++
                          let faultData = {
                            Prekršaj: count,
                            Vozač: findDriver(record.driver),
                            Kompanija: company.Name,
                            Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                          }
                          arrayFaults.push(faultData)
                        } else {
                          if(timeDifferenceWork >= 21 * 60 * 60 * 1000) {
                              count++
                              let faultData = {
                                Prekršaj: count,
                                Vozač: findDriver(record.driver),
                                Kompanija: company.Name,
                                Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                              }
                              arrayFaults.push(faultData)
                          } else if(timeDifferenceWork >= 15 * 60 * 60 * 1000 && timeDifferenceWork < 21 * 60 * 60 * 1000){
                                if(record.avail + nextRecord.avail   < 3 * 60 * 60 * 1000) {
                                  count++
                                  let faultData = {
                                    Prekršaj: count,
                                    Vozač: findDriver(record.driver),
                                    Kompanija: company.Name,
                                    Poruka: `Nema dovoljni dnevni odmor dana ${formatSRBTime(nextRecord.startTime)}, trajao ${hours}:${minutes}!`
                                  }
                                  arrayFaults.push(faultData)
                              }
                          }
                        }
                      }
                    }
                    }
                 }
                
              }
            
            } else {
              if(isGradski) {
                if(dayEnd - dayStart > 36 * 60 * 60 * 1000) {
                  count++
                  let faultData = {
                    Prekršaj: count,
                    Vozač: findDriver(record.driver),
                    Kompanija: company.Name,
                    Poruka: `Nema devetku u 2 dana za redom. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursDay}:${minutesDay}!`
                  }
                  arrayFaults.push(faultData)
                }
      
              } else {
                if(dayEnd - dayStart > 36 * 60 * 60 * 1000) {
                  count++
                  let faultData = {
                    Prekršaj: count,
                    Vozač: findDriver(record.driver),
                    Kompanija: company.Name,
                    Poruka: `Nema devetku u 2 dana za redom. Prekoračenje radnog dana ${formatSRBTime(record.endTime)}, radni dan trajao ${hoursDay}:${minutesDay}!`
                  }
                  arrayFaults.push(faultData)
                }
              }
            }
          }
      }
      
      return arrayFaults;
    }

    const faults = calculate9orLess(arrayWorkDay)

    return faults;
}