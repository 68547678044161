import React from 'react';
import './Summary.css';
import image from './logo summary.png';

const Summary = () => {

    return (
                <React.Fragment>
                             <div id="summary_image"><img src={image} alt="TPS logo"/></div>
                    <div id="summary" >
                        <div>
                            <b>TPServis</b> je portal za upravljanje i mikro - menadžment svih
                            procesa potrebnih za rad voznog parka, snabdevanje istog i
                            održavanje u što boljem i preciznijem roku.
                        </div>
                        <br></br>
                        <div><b>TPServis</b> omogućava:{" "}
                            <ol>
                                <li>
                                    Planiranje održavanja, 
                                </li>
                                <li>
                                    Evidenciju potrošnog materijala i delova,
                                </li>
                                <li>
                                    Praćenje potrošnih delova i implementaciju istih u vozila,
                                </li>
                                <li>    
                                    Praćenje promena u voznom parku,
                                </li>
                                <li>Evidenciju rada servisa,
                                </li>
                                <li>Bolji uvod u procese sistema održavanja i amortizaciju...
                                </li>
                            </ol>
                        </div>
                    </div>
                </React.Fragment>
       
    );
};

export default Summary;
