import React from 'react';
import './magacin.css'

const Magacin = () =>{
    
return (
    <div className="magacini">
    
    </div>
  );
};

export default Magacin;