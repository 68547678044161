import React, {useEffect, useState, useRef, useContext } from 'react';
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { AuthContext } from "../../../../Header/auth - context";
import { Button } from "primereact/button";
import { useSelector } from 'react-redux';
import Pumpa from './pumpa';

const SipanjaPumpe = (props) => {

    const auth = useContext(AuthContext);
    const company = useSelector((state) => state.company);
    const [pumpaDrop, setPumpaDrop] = useState("");
    const [dateDopune, setDateDopune] = useState(null);
    const [valueKol, setValueKol] = useState(null);
    const [komentar, setKomentar] = useState("");
    const [pumpaDB, setPumpaDB] = useState([]);
    const toast = useRef(null);
    
const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

      const setDopunaPumpeHandler = () => {
        if(pumpaDrop !== "" && dateDopune !== null && valueKol !== null) {
            let newDateSipanja = new Date(dateDopune);
            newDateSipanja.setHours(newDateSipanja.getHours() + 2);
            let noviDate=newDateSipanja.toISOString().substr(0, 10);
         
            let requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                },
                body: JSON.stringify({
                    id_pumpa: pumpaDrop,
                    datum_sipanja: noviDate,
                    kolicina: valueKol,
                    komentar: komentar,
                }),
              };
              fetch(process.env.REACT_APP_BACKEND_URL + "/fuel/add_pump_fuel", requestOptions)
                .then(handleErrors)
                .then((data) => {
                  toast.current.show({
                    severity: "success",
                    summary: "Unos dopune uspešan!",
                    detail: data.message,
                    life: 3000,
                  });
                  setPumpaDrop("");
                  setDateDopune(null);
                  setValueKol(null);
                  setKomentar('');
                })
                .catch((error) => {
                  error.json().then((errorMessage) => {
                    toast.current.show({
                      severity: "error",
                      summary: "Error",
                      detail: errorMessage.message,
                      life: 3000,
                    });
                    console.log(errorMessage);
                  });
                });
        } else {
            toast.current.show({
                        severity: "warn",
                        summary: "Upozorenje!",
                        detail: "Niste uneli sve podatke da bi dopunili stanje pumpe!",
                        life: 3000,
                      });
        }
    }
    useEffect(() => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          firma_id: company.companyId,
        }),
      };
    
      fetch(process.env.REACT_APP_BACKEND_URL + "/fuel", requestOptions)
        .then(handleErrors)
        .then((result) => {
          let p = [];
          for (let pump of result) {
            let _pump = {
              label: pump.pumpa,
              value: pump.id,
              state: pump.stanje
            };
            p.push(_pump);
          }
          setPumpaDB(p)
        })
        .catch((error) => {
          console.log(error);
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
      }, [setDopunaPumpeHandler]);
    


    return(
      (props.status &&
      <div id='pumpe_forma'>
        <Toast ref={toast} />
          <div className="header_popup">
            <h3>Sipanja goriva po pumpi</h3>
           </div>
           <div id="stanje_pumpi_forma">
          <Dropdown
              value={pumpaDrop}
              options={pumpaDB}
              required
              onChange={(e) => setPumpaDrop(e.value)}
              placeholder="Pumpa"
              className="gorivo_red"
            />
            <Calendar
              value={dateDopune}
              inputStyle={{ float: "left" }}
              onChange={(e) => setDateDopune(e.value)}
              required
              dateFormat="dd.mm.yy"
              showIcon
              className="gorivo_red"
              placeholder="Datum dopune pumpe"
            ></Calendar>
            <InputNumber
              value={valueKol}
              onValueChange={(e) => setValueKol(e.value)}
              className="gorivo_red"
              required
              id="kolicina"
              placeholder="Količina"
            />
            <InputText
              value={komentar}
              required
              onChange={(e) => setKomentar(e.target.value)}
              className="gorivo_red"
              style={{ marginLeft: "1%"}}
              placeholder="Komentar"
            />
             <Button
            onClick={setDopunaPumpeHandler}
            className="p-button-raised p-button-success p-button"
            id="dopuna_pumpe_button"
            label="UNOS"
          />
          </div>
           <div id="stanje_pumpi_table">
          <table>
            <thead>
            <tr>
                <th>#</th>
                <th>Naziv pumpe</th>
                <th>Stanje [L]</th>
              </tr>
            </thead>
            <tbody>
            {pumpaDB.map((item, i) => (
                <Pumpa
                  pumpa={item}
                  id={item.value}
                  pumpaDB={item}
                  key={i}
                  index={i}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>)

    )
}
export default SipanjaPumpe;