export function processRecords(records, company, driversDB, isGradski) {

    let count = 0;
    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatTime(milliseconds) {
        const hours = String(Math.floor(milliseconds / (60 * 60 * 1000))).padStart(2, '0');
        const minutes = String(Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
        return `${hours}:${minutes}`;
      }
    function formatSRBTime(dateString) {
        const parts = dateString.split('-');
        const date = new Date(`${parts[1]}/${parts[2]}/${parts[0]}`);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        return formattedDate;
    }
 
    
    
                    
    if(!isGradski){
        const arrayLessThan45mins = [];
        for(let n=0; n<driversDB.length;n++){
            let driver = driversDB[n];
            let filtered = records.filter(item => item.DriverId === driver.Id) 
            
            const groupedByDay = {};
           
      
        for (let i=0; i< filtered.length; i++) {
            const record = filtered[i];
            const previousRecord = filtered[i+-1];
          const day = record.ActivityDate.split("T")[0];
      
          if (!groupedByDay[day]) {
            groupedByDay[day] = {
              sumDrivingTotalTime: 0,
              sumBreakRestTotalTime: 0,
              has15: false,
              has30: false,
              has45: false,
            };
          }
      
          if (record.Value.toLowerCase() === 'driving') {

                    groupedByDay[day].sumDrivingTotalTime += parseInt(record.TotalTime);
            
          } else if (record.Value.toLowerCase() === 'break/rest' || record.Value.toLowerCase() === 'unknown') {
                    
                    if (parseInt(record.TotalTime) >= 15 * 60  * 1000 && parseInt(record.TotalTime) < 45 * 60 * 1000) {
                                if(parseInt(record.TotalTime) >= 15  * 60 * 1000 && parseInt(record.TotalTime) < 30 * 60 * 1000) {
                                      if(!groupedByDay[day].has15) {
                                        groupedByDay[day].has15 = true;
                                        groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                                      } 
                                  
                                } else if (parseInt(record.TotalTime) >= 30 * 60 * 1000 && parseInt(record.TotalTime) < 45 * 60 * 1000) {
                                    
                                      if(!groupedByDay[day].has15) {
                                        groupedByDay[day].has15 = true;
                                        groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                                      } else {
                                        groupedByDay[day].has30 = true;
                                        if(groupedByDay[day].sumDrivingTotalTime > 4 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                            count++
                                            arrayLessThan45mins.push({
                                            Prekršaj: count,
                                            Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                            Kompanija: company.Name,  // Assuming Company is available in the records
                                            Poruka: `Kazna dana ${formatSRBTime(day)} za manje od 45 minuta pauze. Vožnja ${formatTime(groupedByDay[day].sumDrivingTotalTime)}!`
                                            });
                                            // Reset for the next set of records
                                            groupedByDay[day] = {
                                            sumDrivingTotalTime: 0,
                                            sumBreakRestTotalTime: 0,
                                            has15: false,
                                            has30: false,
                                            has45: false,
                                            };
                                        } else {
                                            groupedByDay[day] = {
                                                sumDrivingTotalTime: 0,
                                                sumBreakRestTotalTime: 0,
                                                has15: false,
                                                has30: false,
                                                has45: false,
                                                };
                                        }
                                      }
                                }
                      } else if (parseInt(record.TotalTime) >= 45 * 60 * 1000){
                                if(groupedByDay[day].sumDrivingTotalTime > 4 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                    count++
                                    arrayLessThan45mins.push({
                                    Prekršaj: count,
                                    Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                    Kompanija: company.Name,  // Assuming Company is available in the records
                                    Poruka: `Kazna dana ${formatSRBTime(day)} za manje od 45 minuta pauze. Vožnja ${formatTime(groupedByDay[day].sumDrivingTotalTime)}!`
                                    });
                                    // Reset for the next set of records
                                    groupedByDay[day] = {
                                    sumDrivingTotalTime: 0,
                                    sumBreakRestTotalTime: 0,
                                    has15: false,
                                    has30: false,
                                    has45: false,
                                    };
                                } else {
                                    groupedByDay[day] = {
                                        sumDrivingTotalTime: 0,
                                        sumBreakRestTotalTime: 0,
                                        has15: false,
                                        has30: false,
                                        has45: false,
                                        };
                                }
                        }
                   
                      

                    
                    
          } else if (record.Value.toLowerCase() === 'availability') { 

            if(!previousRecord){
                if(parseInt(record.TotalTime) >= 15  * 60 * 1000 && parseInt(record.TotalTime) < 30 * 60 * 1000) {
                    if(!groupedByDay[day].has15) {
                      groupedByDay[day].has15 = true;
                      groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                    } 
                
              } else if (parseInt(record.TotalTime) >= 30 * 60 * 1000 && parseInt(record.TotalTime) < 45 * 60 * 1000) {
                  
                    if(!groupedByDay[day].has15) {
                      groupedByDay[day].has15 = true;
                      groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                    } else {
                      groupedByDay[day].has30 = true;
                         if(groupedByDay[day].sumDrivingTotalTime > 4 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                             count++
                             arrayLessThan45mins.push({
                             Prekršaj: count,
                             Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                             Kompanija: company.Name,  // Assuming Company is available in the records
                             Poruka: `Kazna dana ${formatSRBTime(day)} za manje od 45 minuta pauze. Vožnja ${formatTime(groupedByDay[day].sumDrivingTotalTime)}!`
                             });
                             // Reset for the next set of records
                             groupedByDay[day] = {
                             sumDrivingTotalTime: 0,
                             sumBreakRestTotalTime: 0,
                             has15: false,
                             has30: false,
                             has45: false,
                             };
                         } else {
                             groupedByDay[day] = {
                                 sumDrivingTotalTime: 0,
                                 sumBreakRestTotalTime: 0,
                                 has15: false,
                                 has30: false,
                                 has45: false,
                                 };
                         }
                    }
              } 
            } else {
                if(previousRecord.Value.toLowerCase() === 'break/rest') {
                    let sumValue = parseInt(record.TotalTime) + parseInt(previousRecord.TotalTime)
                    if(sumValue >= 15 * 60 * 1000 && sumValue < 30 * 60 * 1000){
                        if(!groupedByDay[day].has15) {
                            groupedByDay[day].has15 = true;
                            groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                        }
                    } else if (sumValue >= 30 * 60 * 1000 && sumValue < 40 * 60 * 1000) {
                        if(!groupedByDay[day].has15) {
                            groupedByDay[day].has15 = true;
                            groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                        } else if (!groupedByDay[day].has30) {
                            groupedByDay[day].has30 = true;
                            if(groupedByDay[day].sumDrivingTotalTime > 4 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                   
                                count++
                                arrayLessThan45mins.push({
                                Prekršaj: count,
                                Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                Kompanija: company.Name,  // Assuming Company is available in the records
                                Poruka: `Kazna dana ${formatSRBTime(day)} za manje od 45 minuta pauze. Vožnja ${formatTime(groupedByDay[day].sumDrivingTotalTime)}!`
                                });
                                // Reset for the next set of records
                                groupedByDay[day] = {
                                sumDrivingTotalTime: 0,
                                sumBreakRestTotalTime: 0,
                                has15: false,
                                has30: false,
                                has45: false,
                                };
                            } else {
                                groupedByDay[day] = {
                                    sumDrivingTotalTime: 0,
                                    sumBreakRestTotalTime: 0,
                                    has15: false,
                                    has30: false,
                                    has45: false,
                                    };
                            }
                        }
                    } else if (sumValue >= 45 * 60 * 1000)  {
                        if(groupedByDay[day].sumDrivingTotalTime > 4 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                   
                            count++
                            arrayLessThan45mins.push({
                            Prekršaj: count,
                            Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                            Kompanija: company.Name,  // Assuming Company is available in the records
                            Poruka: `Kazna dana ${formatSRBTime(day)} za manje od 45 minuta pauze. Vožnja ${formatTime(groupedByDay[day].sumDrivingTotalTime)}!`
                            });
                            // Reset for the next set of records
                            groupedByDay[day] = {
                            sumDrivingTotalTime: 0,
                            sumBreakRestTotalTime: 0,
                            has15: false,
                            has30: false,
                            has45: false,
                            };
                        } else {
                            groupedByDay[day] = {
                                sumDrivingTotalTime: 0,
                                sumBreakRestTotalTime: 0,
                                has15: false,
                                has30: false,
                                has45: false,
                                };
                        }
                    }
                   
                } else {
                    if (parseInt(record.TotalTime) >= 15 * 60  * 1000 && parseInt(record.TotalTime) < 45 * 60 * 1000) {
                        if(parseInt(record.TotalTime) >= 15  * 60 * 1000 && parseInt(record.TotalTime) < 30 * 60 * 1000) {
                              if(!groupedByDay[day].has15) {
                                groupedByDay[day].has15 = true;
                                groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                              } 
                          
                        } else if (parseInt(record.TotalTime) >= 30 * 60 * 1000 && parseInt(record.TotalTime) < 45 * 60 * 1000) {
                            
                              if(!groupedByDay[day].has15) {
                                groupedByDay[day].has15 = true;
                                groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                              } else {
                                groupedByDay[day].has30 = true;
                                   if(groupedByDay[day].sumDrivingTotalTime > 4 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                   
                                        count++
                                        arrayLessThan45mins.push({
                                        Prekršaj: count,
                                       Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                       Kompanija: company.Name,  // Assuming Company is available in the records
                                       Poruka: `Kazna dana ${formatSRBTime(day)} za manje od 45 minuta pauze. Vožnja ${formatTime(groupedByDay[day].sumDrivingTotalTime)}!`
                                       });
                                       // Reset for the next set of records
                                       groupedByDay[day] = {
                                       sumDrivingTotalTime: 0,
                                       sumBreakRestTotalTime: 0,
                                       has15: false,
                                       has30: false,
                                       has45: false,
                                       };
                                   } else {
                                       groupedByDay[day] = {
                                           sumDrivingTotalTime: 0,
                                           sumBreakRestTotalTime: 0,
                                           has15: false,
                                           has30: false,
                                           has45: false,
                                           };
                                   }
                              }
                        } 
                            } else if (parseInt(record.TotalTime) >= 45 * 60 * 1000){
                                        if(groupedByDay[day].sumDrivingTotalTime > 4 * 60 * 60 * 1000 + 30 * 60 * 1000) {
                                                                        
                                            count++
                                            arrayLessThan45mins.push({
                                            Prekršaj: count,
                                            Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                            Kompanija: company.Name,  // Assuming Company is available in the records
                                            Poruka: `Kazna dana ${formatSRBTime(day)} za manje od 45 minuta pauze. Vožnja ${formatTime(groupedByDay[day].sumDrivingTotalTime)}!`
                                            });
                                            // Reset for the next set of records
                                            groupedByDay[day] = {
                                            sumDrivingTotalTime: 0,
                                            sumBreakRestTotalTime: 0,
                                            has15: false,
                                            has30: false,
                                            has45: false,
                                            };
                                        } else {
                                            groupedByDay[day] = {
                                                sumDrivingTotalTime: 0,
                                                sumBreakRestTotalTime: 0,
                                                has15: false,
                                                has30: false,
                                                has45: false,
                                                };
                                        }
                                }
                }
            
            }
       
             
            
               

             
             
   }

        }
      }
  return arrayLessThan45mins;
  }
  
}