import React from 'react';
import load from './3.gif';
import './loading.css';

const Loading = () => {
  return (
    <div id="loading">
    <img src={load} alt="TPS loading"/>
    </div>
  )
  
  
};

export default Loading;
