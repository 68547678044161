import React from "react";
import "./unosdelovapopup.css";

const Delovi = (props) => {
  let titleK = props.kategorija.find(
    (item) => item.value === props.delovi.kategorija
  );
  let titlePK = props.podkategorija.find(
    (item) => item.id === props.delovi.podkategorija
  );
  let titleM = props.magacin.find(
    (item) => item.value === props.delovi.magacin
  );

  return (
    <tr style={{ height: "15px" }}>
      <td>{props.index + 1}</td>
      <td>{props.delovi.naziv}</td>
      <td>{titleK.label}</td>
      <td>{titlePK.podkategorija}</td>
      <td>{titleM.label}</td>
      <td>{props.delovi.kolicina}</td>
      <td>{props.delovi.cena}</td>
      <td>
        <i
          className="bi bi-trash"
          onClick={() => {
            props.delete(props.index);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path
              fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
          </svg>
        </i>
      </td>
    </tr>
  );
};
export default Delovi;
