import React from "react";

const Configuration = () => {

  return (
    <div id="config_workspace">

    </div>
  );
};

export default Configuration;
