import React, { useState, useEffect } from "react";


const Celija = (props) => {
    const [pumpaP1, setPumpaP1] = useState(0);
    const [pumpaP2, setPumpaP2] = useState(0);
    const [pumpaOMV, setPumpaOMV] = useState(0);
    const [pumpaART, setPumpaART] = useState(0);
    const [pumpaOstalo, setPumpaOstalo] = useState(0);
    const [ukupnaDnevnaKm, setUkupnaDnevnaKm] = useState(0);
    const [dnevnaKm, setDnevnaKm] = useState(0);
  

    useEffect(() => {
        let value = props.data.filter(item => item.vozilo_id === props.vozilo);
        
        let sum = 0;
        let sum2 = 0;
        let sum3 = 0;
        let sum4 = 0;
        let sum5 = 0;
        let ukupnaKM = 0;
        let kmMap = new Map()

        for(let i=0; i< props.days.length; i++){
            const date = props.days[i];
            kmMap.set(i, 0)
            for(let v of value){
                const d = new Date(v.datum_sipanja) 
                
                if (date.getTime() === d.getTime()){
                    const uk = kmMap.get(i)
                    if (uk < v.kilometraza) {
                        kmMap.set(i, v.kilometraza)
                    }
                }
            }
        }
    
        for (let i = 0; i < value.length; i++) {
            let date = new Date(value[i].datum_sipanja)
            if (date.getTime() === props.day.getTime()) {
                if (ukupnaKM < value[i].kilometraza) {
                    ukupnaKM = value[i].kilometraza
                    setUkupnaDnevnaKm(ukupnaKM)
                    // kmMap.set(props.day, ukupnaKM)
                }
                if (value[i].pumpa_id === 1) {
                    sum += value[i].kolicina
                    setPumpaP1(sum)
                }
                if (value[i].pumpa_id === 2) {
                    sum2 += value[i].kolicina
                    setPumpaP2(sum2)
                }
                if (value[i].pumpa_id === 3) {
                    sum3 += value[i].kolicina
                    setPumpaOMV(sum3)
                }
                if (value[i].pumpa_id === 4) {
                    sum4 += value[i].kolicina
                    setPumpaART(sum4)
                }
                if (value[i].pumpa_id === 5) {
                    sum5 += value[i].kolicina
                    setPumpaOstalo(sum5)
                }

            }
        }  
       
      
        if (props.index === 0) {
            setDnevnaKm(ukupnaKM - props.day0km)
        } else {
            let found = false;
            let ind = props.index-1;
      
            while (ind >= 0){
                const ukupno = kmMap.get(ind)
                if(ukupno && ukupno > 0){
                    setDnevnaKm(ukupnaKM-ukupno);
                    found = true;
                    break;
                }
                ind--
            }
            if(!found){
                setDnevnaKm(ukupnaKM-props.day0km);
            }
        }

    }, [props, dnevnaKm, ukupnaDnevnaKm])




    return (
        <div style={{ width: '250px' }} className='stavka_celija'>
            <main>
                <span style={{ width: '50px' }}>{pumpaP2}</span>
                <span style={{ width: '50px' }}>{pumpaP1}</span>
                <span style={{ width: '50px' }}>{pumpaOMV}</span>
                <span style={{ width: '50px' }}>{pumpaART}</span>
                <span style={{ width: '50px' }}>{pumpaOstalo}</span>
            </main>
            <main>
                <span style={{ width: '100px' }}>{ukupnaDnevnaKm}</span>
                <span style={{ width: '50px' }}>{ukupnaDnevnaKm !== 0 ? dnevnaKm : 0}</span>
                <span style={{ width: '100px' }}>{(((pumpaP1 + pumpaP2 + pumpaOMV + pumpaART + pumpaOstalo) * 100 / dnevnaKm).toFixed(2)) === 'NaN' ? 0 : ((pumpaP1 + pumpaP2 + pumpaOMV + pumpaART + pumpaOstalo) * 100 / dnevnaKm).toFixed(2)}</span>
            </main>


        </div>
    );
};

export default Celija;
