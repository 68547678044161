import React, {useRef, useState, useEffect, useContext} from 'react';
import { Toast } from 'primereact/toast';
import Loading from '../../Loading/loading';
import './settings.css';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import User from './user';
import { Button } from "primereact/button";
import { AuthContext } from "../../Header/auth - context";
import { useSelector } from 'react-redux';

const Access_config = () => {
  
  const company = useSelector(state => state.company)
  const userRole = useSelector(state => state.user_role)
  const toast = useRef(null);
  const auth = useContext(AuthContext);
  const [load, setLoad] = useState(true);
  const [users, setUsers] = useState([]);
  const [render, setRender] = useState(false);
  const [rola, setRola] = useState([]);
  const [dropRola, setDropRola] = useState(null);
  const [status, setStatus] = useState(null);
  const [open, setOpen] = useState(false);
  const [userSelect, setUserSelect] = useState([]);
  const [selectStatus, setSelectStatus] = useState(false);
  const [masterRole, setMasterRole] = useState(null);

  const renderFunction = () => {
    if (render === false) {
      setRender(true)
    } else {
      setRender(false)
    }
  }

  useEffect(()=>{
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/users", requestOptions)
    .then(handleErrors)
    .then(
      (result) => {
        setUsers(result);
        setLoad(false);
      }).catch((error) => {
        error.json().then( errorMessage => {
          toast.current.show({severity:'error', summary: 'Error', detail:errorMessage.message, life: 3000});
          console.log(error);
          setLoad(false);
              })
      });
      fetch(process.env.REACT_APP_BACKEND_URL + "/roles")
      .then(handleErrors)
      .then(
        (result) => {
        setRola(result);
        }).catch((error) => {
          error.json().then( errorMessage => {
            toast.current.show({severity:'error', summary: 'Error', detail:errorMessage.message, life: 3000});
            console.log(error);
            setLoad(false);
                })
        });
        if (userRole !== null) {
          setMasterRole(userRole.roleId)
        }
  },[render, open])     
  
  
  
  const handleErrors = (response) => {
    if (response.ok) {
     return response.json();
   } else {
     throw response;
   }
  }

const handleOpenWindow = () => {
    setOpen(true)
}
const handleCloseWindow = () => {
    setOpen(false)
}
const handleSelectUser = (item) => {
    setUserSelect(item);
    if(item.status === 1){
        setSelectStatus(true)
    } else {
        setSelectStatus(false)
    }
    let dropRole = {
        id: null, role: ''
    }
    if(item.role_id === 1) {
        dropRole.id = item.role_id
        dropRole.role = 'Uprava'
    } else if (item.role_id === 2) {
        dropRole.id = item.role_id
        dropRole.role = 'Održavanje'
    } else if (item.role_id === 3) {
        dropRole.id = item.role_id
        dropRole.role = 'Administracija'
    }
    setDropRola(dropRole)
    setRender(false);
}
const handleDBInput = () => {
        let newStatus;
        
        if(status === true) {
            newStatus = 1;
        } else if (status === false) {
            newStatus = 0;
        } else if (status === null) {
            newStatus = selectStatus;
        } 

    
        if (newStatus !== null || selectStatus !== null) {
          let requestOptions = {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            },
            body: JSON.stringify({
              id: userSelect.id,
              role_id: dropRola.id,
              status: newStatus,
              master: masterRole
            }),
          };
          fetch(process.env.REACT_APP_BACKEND_URL + "/users", requestOptions)
            .then(handleErrors)
            .then((data) =>
              toast.current.show({
                severity: "success",
                summary: "Uspešno ažuriranje!",
                detail: data.message,
                life: 3000,
              }),
              renderFunction()
            )
            .catch((error) => {
              error.json().then((errorMessage) => {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: errorMessage.message,
                  life: 3000,
                });
                console.log(errorMessage);
              });
            });
        } else {
          toast.current.show({
            severity: "warn",
            summary: "Upozorenje!",
            detail: "Ažuriranje nije uspelo!",
            life: 3000,
          });
        }
}

  return (
    <div id="setting_main">
      {load && <Loading/>}   
        <Toast ref={toast}/>
        <div className="setting_table">
        
            <table>
                <thead>
                    <tr>
                    <th>Rb</th>
                    <th>Ime</th>
                    <th>Prezime</th>
                    <th>Email</th>
                    <th>Rola</th>
                    <th>Status</th>
                    <th>Otvori</th>
                    </tr>
                </thead>
                <tbody>
            {users.length > 0 ? users.map((item, i)=>(
                <User index={i} key={i} users={item} open={handleOpenWindow} selectUser={handleSelectUser} valueDropRola={setDropRola} render={render} status={setStatus}/>
           
        )) : <tr><td>Nema korisnika u bazi, kontaktirajte administratora!!!</td></tr> }
        </tbody>
                </table>
                </div>
                <div id="setting_second">
        {open && userSelect !== [] ? 
        <div id="setting_item">
                
                    <Toast ref={toast} />
                    <div className="card-setting">
                        <div>
                        {`${userSelect.name} ${userSelect.surname} `}
                        </div>
                        <div>
                        <Button
                        onClick={handleCloseWindow}
                        label="X"
                        className="p-button-sm p-button-raised p-button-danger"
                        />
                        </div>
                    
                    </div>
                    <div className="card-body">
                        <div><p>Uloga korisnika:</p>    
                        <Dropdown optionLabel="role" value={dropRola} options={rola} onChange={(e) => setDropRola(e.value)} placeholder="Izaberi ulogu korisnika"/>
                        </div>
                        <div><p>Nalog korisnika (aktivan/neaktivan):</p>
                        <InputSwitch checked={status == null ? selectStatus : status} onChange={(e) => setStatus(e.value)}/>
                        </div>
                    </div>
                    <footer>
                    <Button
                        onClick={handleDBInput}
                        label="Sačuvaj"
                        className="p-button p-button-raised p-button-success"
                        />
                    </footer>
                
            </div> : null}
                </div>
        </div>
  );    
};

export default Access_config;