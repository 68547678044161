import React, { useState, useEffect } from "react";

import Celija from "./stavka_celija";

const Stavka_vozilo = (props) => {
    const [tachoClock, setTachoClock] = useState(0);
    const [totalG, setTotalG] = useState(0);
    const [totalKM, setTotalKM] = useState(0);

    useEffect(()=>{
                let clock = props.tachoState.filter(item => item.vozilo_id === props.data.id);     
                if(clock.length !== 0){
                 
                    setTachoClock(clock[0].kilometraza) 

                } else {
                  setTachoClock(0) 
                }
                let totalG = props.interData.filter(item => item.vozilo_id === props.data.id);
                
                let sumMax = 0;  
                for(let i=0; i<totalG.length; i++){
                  if(sumMax < totalG[i].kilometraza){
                    sumMax = totalG[i].kilometraza
                  }
                  if(sumMax > tachoClock) {
                    setTotalKM(sumMax - tachoClock)
                  }
                }
                let sumG = 0;  
                for(let i=0; i<totalG.length; i++){
                  sumG += totalG[i].kolicina;
                  setTotalG(sumG)
                }
    },[props])


  return (
    <section className='stavka_vozilo'>
     <div>{props.data.garazni_broj}</div>
     <div>{props.data.registracija}</div>
     <div>{tachoClock === null ? 0 : tachoClock}</div>
     {props.daysData.map((item, i)=>(
        <Celija key={i} index={i} vozilo={props.data.id} day={item} days={props.daysData} data={props.interData} day0km={tachoClock} day0={props.day0} dateTo={props.dateTo}/>
     ))}
      <div>
            <p>{totalG}</p>
            <p>{totalKM}</p>            
      </div>
      <div>
            {((totalG*100/totalKM).toFixed(2)) === 'NaN'? 0 : (totalG*100/totalKM).toFixed(2) }      
      </div>
    </section>
  );
};

export default Stavka_vozilo;
