import React from 'react';
import Logo from './Logo';
import Auth from './Auth';
import {Link} from 'react-router-dom';

import './Header.css';

const Header = props => {

  return (
    <header className="header">
      <Link to="/tabla_zaduzenja"><p className="tpservis_header">TPServis</p></Link>
      <Logo/>
      <Auth logout={props.logout} logged={props.token} user={props.user}/>
    </header>
  );
};

export default Header;
