import React from 'react';
import './unos_radnih.css';
import Slika from './servis.jpg'

const UnosRadnih = props => {
  return (
    <div className="unos">
      <h1>UNOS RADNIH NALOGA</h1>
      <img src={Slika} alt="Unos radnih naloga"/>
      {props.children}
    </div>
  );
};

export default UnosRadnih;