import React, { useState, useEffect, useRef, useContext } from "react";
import "./servis.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Toast } from "primereact/toast";
import Loading from "../../Loading/loading";
import { AuthContext } from "../../Header/auth - context";
import { useSelector } from "react-redux";

const Servis = () => {
  const company = useSelector(state => state.company)
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [first, setFirst] = useState(0);
  const [load, setLoad] = useState(true);
  const [serviceLogs, setServiceLogs] = useState([]);
  const auth = useContext(AuthContext);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  useEffect(() => {

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    let mounted = true;

    fetch(process.env.REACT_APP_BACKEND_URL + "/service_logs/table", requestOptions)
      .then(handleErrors)
      .then((result) => {
        if (mounted) {
          let _serviceLogs = []
          for(let i=0; i<result.length; i++){
            let obj= {
              index: '',
              id: '',
              Broj_radnog_naloga:'',
              Garazni:'',
              Tip_odrzavanja:'',
              Magacin:'',
              Deo:'',
              ZaposleniUD:'',
              OdgovorniMajstor:'',
              newDatum_PO:'',
              newDatum_ZO:'',
              Kilometraza:'',
              Kolicina:'',
              opis_komentar:''
            }
            obj.index= i+1;
            obj.id= result[i].id;
            obj.Broj_radnog_naloga=result[i].Broj_radnog_naloga;
            obj.Garazni=result[i].Garazni;
            obj.Tip_odrzavanja=result[i].Tip_odrzavanja;
            obj.Magacin=result[i].Magacin;
            obj.Deo=result[i].Deo;
            obj.ZaposleniUD=result[i].ZaposleniUD;
            obj.OdgovorniMajstor=result[i].OdgovorniMajstor;
            if(result[i].newDatum_PO === null) {
              let dateNEW_PO = null;
              obj.newDatum_PO=dateNEW_PO
            } else{
              let datePO = new Date(result[i].newDatum_PO);
              let dateNEW_PO = datePO.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
              obj.newDatum_PO=dateNEW_PO
            }
            if(result[i].newDatum_ZO === null) {
              let dateNEW_ZO = null;
              obj.newDatum_ZO=dateNEW_ZO
            } else{
              let dateZO = new Date(result[i].newDatum_ZO);
              let dateNEW_ZO = dateZO.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
              obj.newDatum_ZO=dateNEW_ZO
            }
            obj.Kilometraza=result[i].Kilometraza;
            obj.Kolicina=result[i].Kolicina;
            obj.opis_komentar=result[i].opis_komentar;
            _serviceLogs.push(obj)
          }
          setServiceLogs(_serviceLogs);
          setLoad(false);
        }
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
          setLoad(false);
        });
      });
    return () => {
      mounted = false;
    };
  }, [load]);

  return (
    <React.Fragment>
      <div className="servis_table">
        <div
          className="servis_input"
          style={{ textAlign: "right", marginRight: "1%" }}
        >
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <input
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pretraga"
            size="50"
          />
        </div>
        <Toast ref={toast} />
        {load && <Loading />}
        <DataTable
          value={serviceLogs}
          paginator
          selectionMode="single"
          first={first}
          rows={12}
          globalFilter={globalFilter}
          onPage={(e) => setFirst(e.first)}
        >
          <Column sortable field="index" header="Rb"></Column>
          <Column
            sortable
            field="Broj_radnog_naloga"
            header="Radni nalog"
          ></Column>
          <Column sortable field="Garazni" header="Garažni"></Column>
          <Column
            sortable
            field="Tip_odrzavanja"
            header="Tip održavanja"
          ></Column>
          <Column sortable field="Magacin" header="Magacin"></Column>
          <Column sortable field="Deo" header="Deo ugrađen"></Column>
          <Column sortable field="ZaposleniUD" header="Zaposleni U.D."></Column>
          <Column
            sortable
            field="OdgovorniMajstor"
            header="Odgovorni majstor"
          ></Column>
          <Column
            sortable
            field="newDatum_PO"
            header="Početak održavanja"
          ></Column>
          <Column
            sortable
            field="newDatum_ZO"
            header="Zvršetak održavanja"
          ></Column>
          <Column sortable field="Kilometraza" header="Kilometraža"></Column>
          <Column sortable field="Kolicina" header="Količina"></Column>
          <Column sortable field="opis_komentar" header="Komentar"></Column>
        </DataTable>
      </div>
    </React.Fragment>
  );
};

export default Servis;
