import React from 'react';
import './servis.css'

const Servis = () =>{
    
return (
    <div className="servisi">
    
    </div>
  );
};

export default Servis;