import React, { useState, useEffect, useRef, useContext } from 'react';
import { Toast } from 'primereact/toast';
import Loading from '../../Loading/loading';
import './info.css';
import { AuthContext } from "../../Header/auth - context";
import { useSelector } from 'react-redux';

const Info = () => {

  const company = useSelector(state => state.company)
  const auth = useContext(AuthContext);
  const toast = useRef(null);
  const [load, setLoad] = useState(true);
  const [alarms, setAlarms] = useState([]);
  const [alarmsVehicleReg, setAlarmsVehicleReg] = useState([]);
  const [alarmsVehicleSestom, setAlarmsVehicleSestom] = useState([]);
  const [alarmsVehicleTacho, setAlarmsVehicleTacho] = useState([]);

  useEffect(() => {
    
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/alarms/info", requestOptions)
      .then(handleErrors)
      .then((result) => {
        const sorted = result.partInfo.sort((a, b) => a.kolicina - b.kolicina);
          setAlarms(sorted);
        
          let _vozilaReg = [];
          let _vozilaSes = [];
          let _vozilaTaho = [];
          for (let i = 0; i < result.vehicleReg.length; i++) {
            let reg;
            let date = new Date(result.vehicleReg[i].datum_REG);
            reg = date.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});

            let noviPodaci = {
              id: result.vehicleReg[i].id,
              garazni_broj: result.vehicleReg[i].garazni_broj,
              registracija: result.vehicleReg[i].registracija,
              datum_REG: reg
            };
            _vozilaReg.push(noviPodaci);
          }
          for (let i = 0; i < result.vehicleSes.length; i++) {
            let sestom;
            let date = new Date(result.vehicleSes[i].datum_SESTOM);
            sestom = date.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});

            let noviPodaci = {
              id: result.vehicleSes[i].id,
              garazni_broj: result.vehicleSes[i].garazni_broj,
              registracija: result.vehicleSes[i].registracija,
              datum_SESTOM: sestom
            };
            _vozilaSes.push(noviPodaci);
          }
         
          for (let i = 0; i < result.vehicleTaho.length; i++) {
            let taho;
            let date = new Date(result.vehicleTaho[i].datum_TAHO);
            taho = date.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});

            let noviPodaci = {
              id: result.vehicleTaho[i].id,
              garazni_broj: result.vehicleTaho[i].garazni_broj,
              registracija: result.vehicleTaho[i].registracija,
              datum_TAHO: taho
            };
            _vozilaTaho.push(noviPodaci);
          }
          setAlarmsVehicleReg(_vozilaReg);
          setAlarmsVehicleSestom(_vozilaSes);
          setAlarmsVehicleTacho(_vozilaTaho);
      }).catch((error) => {
        error.json().then(errorMessage => {
          toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage.message, life: 3000 });
          console.log(error);
          setLoad(false);
        })
      });
        setLoad(false);
  }, [])

  const dateDifference = (b)=> {
    const a = new Date();
    let year = String(b).substr(6, 4)
    let month = String(b).substr(3, 2)
    let day = String(b).substr(0, 2)
    let date = month.concat('/',day,'/',year)
    let newDate = new Date(date)
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
    if(utc1 - utc2 > 0) {
      return true
    } else {
      return false
    }
  }

  const coloringParts = (item) => {
    if(item <= 0) {
      return '#F96565'
    } else if (item > 0 && item < 6 ){
      return '#F9F465'
    } else {
      return null
    }
  }

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  }

  return (
    <div id="info_main">
      {load && <Loading />}
      <div className="info">
        <p>INFORMACIJE O SISTEMU</p>
      </div>
      <main className="info_main">

        <Toast ref={toast} />
        <div className="info_alarm">
          <div className="info_alarm_header" >
            <h4>Delovi sa manje od 6 na stanju</h4>
            </div>
          <section>
            
            <div className="info_alarm_critical" style={{ fontWeight: "600" }}>
            <p style={{ width: "5%" }}>Rb</p>
            <p style={{ width: "60%" }}>Naziv delova</p>
            <p style={{ width: "15%" }}>Magacin</p>
            <p style={{ width: "20%" }}>Kritična količina</p>
          </div>
          {alarms.length > 0 ? alarms.map((item, i) => (
            <div className="info_alarm_critical" key={i}>
              <p style={{ width: "5%" }}>{i+1}</p>
              <p style={{ width: "60%" }}>{item.naziv_dela}</p>
              <p style={{ width: "15%" }}>{item.Magacin}</p>
              <p style={{ width: "20%", backgroundColor: coloringParts(item.kolicina)}}>{item.kolicina}</p>
            </div>
          )) : <p className="message">Nema delova koji imaju stanje manje od 5 komada!</p>}
          </section>
          </div>
         
        <div className="info_alarm">
        <div className="info_alarm_header" >
            <h4>Vozila kojima ističe registracija do 30 dana</h4>
          </div>
          <section>
          <div className="info_alarm_critical" style={{ fontWeight: "600" }}>
          <p style={{ width: "5%" }}>Rb</p>
            <p style={{ width: "30%" }}>Garažni broj</p>
            <p style={{ width: "30%" }}>Registracija</p>
            <p style={{ width: "35%" }}>Datum isteka reg.</p>
          </div>
          {alarmsVehicleReg.length > 0 ? alarmsVehicleReg.map((item, i) => (
            <div className="info_alarm_critical" key={i}>
              <p style={{ width: "5%" }}>{i+1}</p>
              <p style={{ width: "30%" }}>{item.garazni_broj}</p>
              <p style={{ width: "30%" }}>{item.registracija}</p>
              <p style={{ width: "35%" }}>{item.datum_REG}{dateDifference(item.datum_REG) ? <span style={{color: 'red'}}> <b> - istekao</b></span> : null}</p>
            </div>
          )) : <p className="message">Nema vozila kojima ističe registracija u roku od 30 dana!</p>}
          </section>
         </div>
        <div className="info_alarm">
        <div className="info_alarm_header" >
            <h4>Vozila kojima ističe šestomesečni do 30 dana</h4>
          </div>
          <section>
          <div className="info_alarm_critical" style={{ fontWeight: "600" }}>
          <p style={{ width: "5%" }}>Rb</p>
            <p style={{ width: "30%" }}>Garažni broj</p>
            <p style={{ width: "30%" }}>Registracija</p>
            <p style={{ width: "35%" }}>Datum isteka šestom.</p>
          </div>
          {alarmsVehicleSestom.length > 0 ? alarmsVehicleSestom.map((item, i) => (
            <div className="info_alarm_critical" key={i}>
              <p style={{ width: "5%" }}>{i+1}</p>
              <p style={{ width: "30%" }}>{item.garazni_broj}</p>
              <p style={{ width: "30%" }}>{item.registracija}</p>
              <p style={{ width: "35%" }}>{item.datum_SESTOM}{dateDifference(item.datum_SESTOM) ? <span style={{color: 'red'}}> <b> - istekao</b></span> : null}</p>
            </div>
          )) : <p className="message">Nema vozila kojima ističe šestomesečni u roku od 30 dana!</p>}
          </section>
          </div>
        <div className="info_alarm" >
        <div className="info_alarm_header" >
            <h4>Vozila kojima ističe baždarenje tahografa do 30 dana</h4>
          </div>
          <section>
          <div className="info_alarm_critical" style={{ fontWeight: "600" }}>
            <p style={{ width: "5%" }}>Rb</p>
            <p style={{ width: "30%" }}>Garažni broj</p>
            <p style={{ width: "30%" }}>Registracija</p>
            <p style={{ width: "35%" }}>Datum isteka baž. taho.</p>
          </div>
          {alarmsVehicleTacho.length > 0 ? alarmsVehicleTacho.map((item, i) => (
            <div className="info_alarm_critical" key={i}>
              <p style={{ width: "5%" }}>{i+1}</p>
              <p style={{ width: "30%" }}>{item.garazni_broj}</p>
              <p style={{ width: "30%" }}>{item.registracija}</p>
              <p style={{ width: "35%" }}>{item.datum_TAHO}{dateDifference(item.datum_TAHO) ? <span style={{color: 'red'}}> <b> - istekao</b></span> : null}</p>
            </div>
          )) : <p className="message">Nema vozila kojima ističe datum baždarenje tahografa u roku od 30 dana!</p>}
          </section>
          </div>
      </main>
      <div className="footer">© 2021 Transprodukt d.o.o. Beograd. Sva prava zadržana. - by Miloš Krstić</div>

    </div>
  );
};

export default Info;