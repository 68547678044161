import React, { useState, useEffect, useRef, useContext } from "react";
import "./vozni_park.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Loading from "../../Loading/loading";
import { AuthContext } from "../../Header/auth - context";
import { useSelector } from "react-redux";

const Vozni_park = () => {
  const company = useSelector(state => state.company)
  const [globalFilter, setGlobalFilter] = useState(null);
  const [first, setFirst] = useState(0);
  const [vozila, setVozila] = useState([]);
  const toast = useRef(null);
  const [load, setLoad] = useState(true);
  const auth = useContext(AuthContext);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  useEffect(() => {

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles/table", requestOptions)
      .then(handleErrors)
      .then((result) => {
        let _vozila = [];
        for (let i = 0; i < result.length; i++) {
          let DUVP;
          if (result[i].datum_UVP === null) {
            DUVP = null;
          } else {
            let newDateUVP = new Date(result[i].datum_UVP);
            newDateUVP.setHours(newDateUVP.getHours() + 2);
            DUVP = newDateUVP.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }
          let DSES;
          if (result[i].datum_SESTOM === null) {
            DSES = null;
          } else {
            let newDateS = new Date(result[i].datum_SESTOM);
            newDateS.setHours(newDateS.getHours() + 2);
            DSES = newDateS.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }
          let DREG;
          if (result[i].datum_REG === null) {
            DREG = null;
          } else {
            let newDateR = new Date(result[i].datum_REG);
            newDateR.setHours(newDateR.getHours() + 2);
            DREG = newDateR.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }
          let DTAHO;
          if (result[i].datum_TAHO === null) {
            DTAHO = null;
          } else {
            let newDateT = new Date(result[i].datum_TAHO);
            newDateT.setHours(newDateT.getHours() + 2);
            DTAHO = newDateT.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
          }

          let newDateVehicle = {
            id: result[i].id,
            garazni_broj: result[i].garazni_broj,
            registracija: result[i].registracija,
            tip: result[i].tip,
            Datum_UVP: DUVP,
            Datum_SESTOM: DSES,
            Datum_REG: DREG,
            Datum_TAHO: DTAHO,
            broj_sasije: result[i].broj_sasije,
            region: result[i].region,
            kilometraza: result[i].kilometraza,
            opis_komentar: result[i].opis_komentar,
          };
          _vozila.push(newDateVehicle);
        }
        setVozila(_vozila);
        setLoad(false);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
          setLoad(false);
        });
      });
  }, []);

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const dateDiffInDays = (a, b) => {
    // Discard the time and time-zone information.
    let year = String(b).substr(6, 4)
    let month = String(b).substr(3, 2)
    let day = String(b).substr(0, 2)
    let date = month.concat('/',day,'/',year)
    let newDate = new Date(date)
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };

  const rowClass = (data) => {
    let dateCheck = new Date();

    return {
      "row-late": dateDiffInDays(dateCheck, data.Datum_REG) <= 0,
      "row-verySoonExpire":
        dateDiffInDays(dateCheck, data.Datum_REG) > 0 &&
        dateDiffInDays(dateCheck, data.Datum_REG) < 8,
      "row-soonExpire":
        dateDiffInDays(dateCheck, data.Datum_REG) > 7 &&
        dateDiffInDays(dateCheck, data.Datum_REG) < 16,
      "row-expireInMonth":
        dateDiffInDays(dateCheck, data.Datum_REG) > 15 &&
        dateDiffInDays(dateCheck, data.Datum_REG) < 30,
    };
  };
  const stockBodyTemplate = (rowData) => {
    let dateCheck = new Date();

    const stockClassName = classNames({
      late: dateDiffInDays(dateCheck, rowData.Datum_SESTOM) <= 0,
      verySoonExpire:
        dateDiffInDays(dateCheck, rowData.Datum_SESTOM) > 0 &&
        dateDiffInDays(dateCheck, rowData.Datum_SESTOM) < 8,
      soonExpire:
        dateDiffInDays(dateCheck, rowData.Datum_SESTOM) > 7 &&
        dateDiffInDays(dateCheck, rowData.Datum_SESTOM) < 16,
      expireInMonth:
        dateDiffInDays(dateCheck, rowData.Datum_SESTOM) > 15 &&
        dateDiffInDays(dateCheck, rowData.Datum_SESTOM) < 30,
    });
    return <div className={stockClassName}>{rowData.Datum_SESTOM}</div>;
  };
  const stockBodyTemplate2 = (rowData) => {
    let dateCheck = new Date();

    const stockClassName = classNames({
      late: dateDiffInDays(dateCheck, rowData.Datum_TAHO) <= 0,
      verySoonExpire:
        dateDiffInDays(dateCheck, rowData.Datum_TAHO) > 0 &&
        dateDiffInDays(dateCheck, rowData.Datum_TAHO) < 8,
      soonExpire:
        dateDiffInDays(dateCheck, rowData.Datum_TAHO) > 7 &&
        dateDiffInDays(dateCheck, rowData.Datum_TAHO) < 16,
      expireInMonth:
        dateDiffInDays(dateCheck, rowData.Datum_TAHO) > 15 &&
        dateDiffInDays(dateCheck, rowData.Datum_TAHO) < 30,
    });
    return <div className={stockClassName}>{rowData.Datum_TAHO}</div>;
  };
  const stockBodyTemplate3 = (rowData) => {
    let dateCheck = new Date();

    const stockClassName = classNames({
      late: dateDiffInDays(dateCheck, rowData.Datum_REG) <= 0,
      verySoonExpire:
        dateDiffInDays(dateCheck, rowData.Datum_REG) > 0 &&
        dateDiffInDays(dateCheck, rowData.Datum_REG) < 8,
      soonExpire:
        dateDiffInDays(dateCheck, rowData.Datum_REG) > 7 &&
        dateDiffInDays(dateCheck, rowData.Datum_REG) < 16,
      expireInMonth:
        dateDiffInDays(dateCheck, rowData.Datum_REG) > 15 &&
        dateDiffInDays(dateCheck, rowData.Datum_REG) < 30,
    });
    return <div className={stockClassName}>{rowData.Datum_REG}</div>;
  };

  return (
    <div id="vozni_main">
      {load && <Loading/>}
      <div className="vozni_park">
        <p>VOZNI PARK</p>
      </div>
      <Toast ref={toast} />
      <div className="voznipark_table datatable-style-demo">
        <div
          className="vozni_input"
          style={{ textAlign: "right", marginRight: "1%" }}
        >
          <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
          <input
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pretraga"
            size="50"
          />
        </div>
        <DataTable
          value={vozila}
          rowClassName={rowClass}
          paginator
          first={first}
          rows={12}
          globalFilter={globalFilter}
          onPage={(e) => setFirst(e.first)}
        >
          <Column sortable field="garazni_broj" header="Garažni broj"></Column>
          <Column sortable field="registracija" header="Registracija"></Column>
          <Column sortable field="tip" header="Tip"></Column>
          <Column
            sortable
            field="Datum_UVP"
            header="Datum ulaska u VP"
          ></Column>
          <Column
            sortable
            field="Datum_SESTOM"
            header="Datum šestom."
            body={stockBodyTemplate}
          ></Column>
          <Column sortable field="Datum_REG" header="Datum reg." body={stockBodyTemplate3}></Column>
          <Column sortable field="Datum_TAHO" header="Datum baždarenja taho." body={stockBodyTemplate2}></Column>
          <Column sortable field="broj_sasije" header="Broj šasije"></Column>
          <Column sortable field="region" header="Region rada"></Column>
          <Column
            sortable
            field="kilometraza"
            header="Poslednja kilometraza"
          ></Column>
          <Column sortable field="opis_komentar" header="Komentar"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Vozni_park;
