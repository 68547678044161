export function processUnknown (records, company, driversDB) {

  let count = 0;

  const arrayUnknown = []
    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatSRBTime(dateString) {
      const parts = dateString.split('-');
      const date = new Date(`${parts[1]}/${parts[2]}/${parts[0]}`);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
      return formattedDate;
  }
  for(let n=0; n<driversDB.length;n++){
    let driver = driversDB[n];
    let filtered = records.filter(item => item.DriverId === driver.Id) 
    

 
    let unknown = {
      driver: 0,
      company: company.Name,
      startTime: 0,
      endTime: 0,
      day: 0,
      Message: ''
    }
    let work = {
      driver: 0,
      company: company.Name,
      startTime: 0,
      endTime: 0,
      day: 0,
      Message: ''
    }
    let sumUnknown = 0;
    let sumWork = 0;
    
    for (let i = 0; i < filtered.length; i++) {
      let record = filtered[i];
        if (record.Value.toLowerCase() === 'unknown'){
          if(sumUnknown == 0){
            unknown.driver = findDriver(record.DriverId);
            unknown.startTime = record.StartTime;
            unknown.day = record.ActivityDate;
          } 
          sumUnknown += parseInt(record.TotalTime);
          unknown.endTime = record.EndTime;
          unknown.day = record.ActivityDate;

         
        } else if (record.Value.toLowerCase() === 'work') {
          if(sumWork == 0){
            work.driver = findDriver(record.DriverId);
            work.startTime = record.StartTime;
            work.day = record.ActivityDate;
          } 
          sumWork += parseInt(record.TotalTime);
          work.endTime = record.EndTime;
          work.day = record.ActivityDate;

          
        } else {
          if(record.Note === 'Nema podataka na kartici.') {
            if(sumUnknown == 0){
              unknown.driver = findDriver(record.DriverId);
              unknown.startTime = record.StartTime;
              unknown.day = record.ActivityDate;
            } 
            sumUnknown += parseInt(record.TotalTime);
            unknown.endTime = record.EndTime;
            unknown.day = record.ActivityDate;
  
          } else {
            if(sumUnknown != 0){
              const hours = String(Math.floor(sumUnknown / (60 * 60 * 1000))).padStart(2, '0');
              const minutes = String(Math.floor((sumUnknown % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
              const day = unknown.day.split("T")[0];
              count++
              arrayUnknown.push({
                Prekršaj: count,
                Vozač: findDriver(record.DriverId),
                Kompanija: company.Name,
                Poruka: `Ukupna nepoznata aktivnost na dan ${formatSRBTime(day)} je ${hours}:${minutes}`})
  
                
              sumUnknown=0;
              unknown.day = 0;
              unknown.driver = 0;
              unknown.startTime= 0,
              unknown.endTime= 0,
              unknown.Message= '';

  
  
  
            }
            if(sumWork != 0){
              if(sumWork < 5 * 60 * 60 * 1000) {
                sumWork=0;
                work.driver = 0;
                work.day = 0;
                work.startTime= 0,
                work.endTime= 0,
                work.Message= '';
              } else {
                const startTime = new Date(work.startTime).getTime();
                const endTime = new Date(work.endTime).getTime();
                const timeDifference = endTime - startTime;
                const hours = String(Math.floor(sumWork / (60 * 60 * 1000))).padStart(2, '0');
                const minutes = String(Math.floor((sumWork % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
                if(timeDifference > 5 * 60 * 60 * 1000) {
                  const day = work.day.split("T")[0];
                  count++
                  arrayUnknown.push({
                    Prekršaj: count,
                    Vozač: findDriver(record.DriverId),
                    Kompanija: company.Name,
                    Poruka: `Ukupni povezani ostali rad na dan ${formatSRBTime(day)} je ${hours}:${minutes}, loše upravljanje tahografom!`})
  
                  sumWork=0;
                  work.day = 0;
                  work.driver = 0;
                  work.startTime= 0,
                  work.endTime= 0,
                  work.Message= '';
                }
              }
             
              
            }
          }
          
        }
    }
  }

    return arrayUnknown;
  }