import React from 'react';

const User = props => {

  
    const handleUserFunc = () =>{
        let dropRole = {
            id: null, role: ''
        }
        props.status(null)
        props.open();
        props.selectUser(props.users);
        
        if(props.users.role_id === 1) {
            dropRole.id = props.users.role_id
            dropRole.role = 'Uprava'
        } else if (props.users.role_id === 2) {
            dropRole.id = props.users.role_id
            dropRole.role = 'Održavanje'
        } else if (props.users.role_id === 3) {
            dropRole.id = props.users.role_id
            dropRole.role = 'Administracija'
        }
        props.valueDropRola(dropRole);
    }

  return (
    <tr key={props.index}>
            <td>{props.index+1}</td>
            <td>{props.users.name}</td>
            <td>{props.users.surname}</td>
            <td>{props.users.email}</td>
            <td>{props.users.role_id === 1 && 'Uprava'}{props.users.role_id === 3 &&  'Administracija'}{props.users.role_id === 2 && 'Održavanje'}</td>
            <td style={{color: props.users.status === 1? 'green' : 'red', fontWeight: '600'}}>{props.users.status === 1? 'Aktivan' : 'Neaktivan'}</td>
            <td><svg onClick={handleUserFunc} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0  2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
        </svg></td>
    </tr>
  );
};

export default User;