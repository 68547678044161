import React, { useState, useEffect, useRef, useContext } from "react";
import "./gorivo_izvestaj.css";
import { Toast } from "primereact/toast";
import StavkaVozilo from "./stavka_vozilo";
import GorivoStanje from "./gorivo_stanje";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { AuthContext } from "../../../../Header/auth - context";
import { useSelector } from "react-redux";

const GorivoIzvestaj = () => {
  const company = useSelector(state => state.company)
  const auth = useContext(AuthContext);
  const [vozilaDB, setVehicleDB] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [intervalData, setIntervalData] = useState([])
  const [dayBefore, setDayBefore] = useState([])
  const [day0, setDay0] = useState(null);
  const [dates, setDates] = useState([]);
  const [mount, setMount] = useState(false);
  const [ukupnoLitaraPeriod, setUkupnoLitaraPeriod] = useState(0);
  const [ukupnoKmPeriod, setUkupnoKmPeriod] = useState(0);
  const [stanje, setStanje] = useState(null);
  const [stanjaSipanjaP2, setStanjaSipanjaP2] = useState([]);

  const toast = useRef(null);


  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };


  useEffect(() => {
    
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setVehicleDB(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
  }, []);

  const getDayIntervalBefore = () => {
    if (mount === false) {
      setMount(true)
    } else {
      setMount(false)
    }
    if (dateFrom) {
      let newDateFrom = new Date(dateFrom);
      newDateFrom.setHours(newDateFrom.getHours() + 2);
      let stavkaFROM = newDateFrom.toISOString().substr(0, 10);

      var d = new Date(dateFrom);
      d.setDate(d.getDate() - 1);
      setDay0(d)

      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          firma_id: company.companyId,
          dateFrom: stavkaFROM,
        }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/fuel/day_before", requestOptions)
        .then(handleErrors)
        .then((data) => {
          setDayBefore(data);
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    }
  }


  const setIntervalReport = (e) => {
    e.preventDefault();
    getDayIntervalBefore();
    if (dateTo !== null && dateFrom !== null) {
      if (dateTo >= dateFrom) {
        const start = new Date(dateFrom);
        const end = new Date(dateTo);

        let loop = new Date(start);
        let arrayDates = [];
        while (loop <= end) {
          arrayDates.push(new Date(loop))
          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);

        }
        setDates(arrayDates)
        if (mount === false) {
          setMount(true)
        } else {
          setMount(false)
        }
        let newDateTO = new Date(dateTo);
        newDateTO.setHours(newDateTO.getHours() + 2);
        let stavkaTO = newDateTO.toISOString().substr(0, 10);

        let newDateFrom = new Date(dateFrom);
        newDateFrom.setHours(newDateFrom.getHours() + 2);
        let stavkaFROM = newDateFrom.toISOString().substr(0, 10);

        let requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          body: JSON.stringify({
            firma_id: company.companyId,
            dateFrom: stavkaFROM,
            dateTo: stavkaTO,
          }),
        };
        fetch(process.env.REACT_APP_BACKEND_URL + "/fuel/report", requestOptions)
          .then(handleErrors)
          .then((data) => {
            let sum=0;
            let uk = 0;
            if(dayBefore.length > 0){
              for(let i=0; i<dayBefore.length; i++){
                let _sum=0;
                for(let j=0; j<data.length; j++){
                  if(dayBefore[i].vozilo_id === data[j].vozilo_id){
                    if(data[j].kilometraza > _sum){
                      _sum = data[j].kilometraza;
                    }
                  }
                  uk=_sum-dayBefore[i].kilometraza;
                }
                sum+=uk;
              }
              setUkupnoKmPeriod(sum);
            } else {
              let uniqueValues = data.map(item => item.vozilo_id)
              .filter((value, index, self) => self.indexOf(value) === index)
              for(let i=0; i<uniqueValues.length; i++){
                let _sum=0;
                for(let j=0; j<data.length; j++){
                  if(uniqueValues[i] === data[j].vozilo_id){
                    if(data[j].kilometraza > _sum){
                      _sum = data[j].kilometraza;
                    }
                  }
                  uk=_sum
                }
                sum+=uk;
              }
              setUkupnoKmPeriod(sum)
            }
            setIntervalData(data);
            ukupnoLitara(data);
            setShowReport(true);
          })
          .catch((error) => {
            error.json().then((errorMessage) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMessage.message,
                life: 3000,
              });
              console.log(errorMessage);
            });
          });
          fetch(process.env.REACT_APP_BACKEND_URL + "/fuel/state", requestOptions)
            .then(handleErrors)
            .then((data) => {
              setStanjaSipanjaP2(data)
              let _sum=0;
              for(let j=0; j<data.length; j++){
                    _sum += data[j].kolicina;
        
                }
                setStanje(_sum);
            })
            .catch((error) => {
              error.json().then((errorMessage) => {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: errorMessage.message,
                  life: 3000,
                });
                console.log(errorMessage);
              });
            });
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Upozorenje",
          detail: "Datum OD mora biti manji od datuma DO!",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "info",
        summary: "Obeveštenje",
        detail: "Molim vas izaberite interval za koji želite da dobijte izveštaj!",
        life: 3000,
      });
    }

  }
  const ukupnoLitara = (data) => {
    let ukupnoL = 0;
    for (let i = 0; i < data.length; i++) {
      ukupnoL += data[i].kolicina;
    }
    setUkupnoLitaraPeriod(ukupnoL)
  }

  const formatDays = (item) => {
    let day;
    let date = new Date(item);
    // date.setHours(date.getHours() + 2);
    day = date.toLocaleDateString('sr-RS');

    return day;
  }
  return (
    <div id="gorivo_izvestaj_workspace">
      <Toast ref={toast} />
      <div id="izvestaj_gorivo_form">
        <form>
          <Calendar
            value={dateFrom}
            onChange={(e) => setDateFrom(e.value)}
            onHide={getDayIntervalBefore}
            placeholder="Interval datuma od"
            showIcon
            className="red_gorivo_izvestaj_form"
            dateFormat="dd.mm.yy"
            tooltip="Datum OD"
            tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
          ></Calendar>
          <Calendar
            value={dateTo}
            onChange={(e) => setDateTo(e.value)}
            placeholder="Interval datuma do"
            showIcon
            className="red_gorivo_izvestaj_form"
            dateFormat="dd.mm.yy"
            tooltip="Datum DO"
            tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
          ></Calendar>
          <div id="gorivo_izvestaji_button" className="red_gorivo_izvestaj_form">
            <Button
              onClick={setIntervalReport}
              label="Generiši izveštaj"
              className="p-button-raised p-button-success p-button"
              id="dopuna_pumpe_button"
            />
          </div>
        </form>
      </div>
      <div style={{ height: showReport ? '100%' : '0%', display: showReport ? 'block' : 'none', opacity: showReport ? 1 : 0 }} id="second_izvestaj_view">
        <section className='stavka_vozilo'>
          <div>Garažni broj</div>
          <div>Registracija</div>
          <div>{`Kilometraža [${day0 === null ? 'datum' : day0.toLocaleDateString('sr-RS')}]`}</div>
          {dates.map((item, i) => (
            <div style={{ width: '250px' }} key={i}>{formatDays(item)}</div>
          ))}
          <div>
            <p>Ukupno sipano [l]</p>
            <p>Ukupno pređenih [km]</p>
          </div>
          <div>
            Prosečna potrošnja
          </div>
        </section>
        {vozilaDB.map((item, i) => (
          <StavkaVozilo key={i} data={item} interData={intervalData} dateFrom={dateFrom} dateTo={dateTo} daysData={dates} tachoState={dayBefore} day0={day0}/>
        ))}
        <section className='stavka_vozilo'>
          <div style={{ width: '300px' }}><b>--- STANJE ---</b></div>
          {dates.map((item, i) => (
            <div style={{ width: '250px', backgroundColor: 'rgb(172, 172, 172)', borderLeft: 'none', borderRight: '1px solid rgb(224, 221, 221)' }} key={i}>{formatDays(item)}</div>
          ))}
        </section>
        <section className='stavka_vozilo'>
          <div className="suma_gorivo_naslovi" style={{ width: '300px', height: "200px", borderRight: '3px solid rgb(126, 125, 125)'}}>
            <div style={{ width: '300px' }}><b>Dnevne akumulacije</b></div>
            <div style={{ width: '300px', height: '100px', borderLeft: 'none' }} className="suma_gorivo_naslovi_child">
              <div style={{ height: '100px', width: '300px' }}>
                <div style={{ height: '100px', width: '150px', borderLeft: 'none'  }}>
                <div style={{ width: '150px',borderLeft: 'none', backgroundColor: '#d5cdff', fontSize: '25px' }}>{stanje ===null ? 0: stanje}</div>
                <div style={{ width: '150px' }}>Dopuna / zaliha pumpe</div>
                </div>
                <div style={{ width: '150px', height: '100px',borderRight: 'none' }}>UKUPNO U MESECU PO DOBAVLJAČIMA</div>
              </div>
              
            </div>
            <div style={{ width: '300px',borderRight: 'none'  }}>KUMULATIV</div>
          </div>
          {dates.map((item, i) => (
            <GorivoStanje key={i} data={item} interData={intervalData} dateFrom={dateFrom} dateTo={dateTo} daysData={dates} tachoState={dayBefore} stanje={stanjaSipanjaP2} day0={day0} />
          ))}
          <div className='summary_view' style={{ width: '350px', height: '200px'}}>
              <div style={{ width: '350px' , height: '100px', flexDirection:'row'}}>
                <div style={{ height: '100px', borderLeft: 'none'}}>
                  <p style={{ height: '50px'}}>Ukupno sipano litara za ceo VP</p>
                  <p style={{ height: '50px'}}>Ukupno pređenih km za ceo VP</p>
                </div>
                <div style={{ height: '100px'}}>
                  Prosečna potrošnja VP
                </div>
              </div>
              <div style={{ width: '350px', height: '100px', borderLeft: '3px solid rgb(126, 125, 125)'}}>
                <div style={{ height: '100px', borderLeft: 'none'}}>
                  <b><p style={{ height: '50px'}}>{ukupnoLitaraPeriod}</p></b>
                  <b><p style={{ height: '50px'}}>{ukupnoKmPeriod}</p></b>
                </div>
                <div style={{ height: '100px'}}>
                  <b>{isNaN((ukupnoLitaraPeriod*100/ukupnoKmPeriod).toFixed(2)) ? 0 : (ukupnoLitaraPeriod*100/ukupnoKmPeriod).toFixed(2)}</b>
                </div>
              </div>
          </div>
        
        </section>
      </div>
      <div id="izvestaj_poruka" style={{ display: showReport ? 'none' : 'block' }}>
        <h3>Za generisanje izveštaja izaberite interval i kliknite na dugme &quot;Generiši izveštaj&quot;!</h3>
      </div>


    </div>
  );
};

export default GorivoIzvestaj;
