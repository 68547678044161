import React from "react";

const Print = (props) => {
  let part = props.partDB.find((p) => p.id === props.nalog.deo);
  let empTake = props.employeeDB.find((e) => e.id === props.nalog.zaposleniUD);
  let empRES = props.employeeDB.find((e) => e.id === props.nalog.odgMajstor);
  let garazni = props.vehicleDB.find((v) => v.id === props.nalog.garazniID);

  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>{garazni.garazni_broj}</td>
      <td>{part.naziv_dela}</td>
      <td>{empTake.Ime_i_prezime}</td>
      <td>{empRES.Ime_i_prezime}</td>
      <td>{props.nalog.kolicina}</td>
    </tr>
  );
};
export default Print;
