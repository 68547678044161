export function processRecordsNightWork(records, company, driversDB, isGradski) {

    let count = 0;

    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatTime(milliseconds) {
        const hours = String(Math.floor(milliseconds / (60 * 60 * 1000))).padStart(2, '0');
        const minutes = String(Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
        return `${hours}:${minutes}`;
      }
      function formatSRBTime(stringDate) {
        const date = new Date(stringDate);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        return formattedDate
      }
                    
    if(!isGradski){
   
        const arrayNightWork = [];
        for(let n=0; n<driversDB.length;n++){
          let driver = driversDB[n];
          let filtered = records.filter(item => item.DriverId === driver.Id) 
          let  groupedByDay = {
            sumWorkTotalTime: 0,
            isNightWork: false,
        };
      
        for (let i=0; i<filtered.length; i++) {
      
          const record = filtered[i];
          const nextRecord = filtered[i+1];
          const day = record.ActivityDate.split("T")[0];
      
          if (record.Value.toLowerCase() === 'driving') {
                let date;
                if(groupedByDay.sumWorkTotalTime > 0) {
                  date = new Date(record.EndTime);
                } else {
                  date = new Date(record.StartTime);
                  groupedByDay.start = record.StartTime
                }
                
           
                // dateAdjusted.setHours(date.getHours() + selectedHour.code);        
                const hour = date.getHours();
                if (hour >= 0 && hour <= 3) {
                  groupedByDay.isNightWork = true;
                }

            groupedByDay.sumWorkTotalTime += parseInt(record.TotalTime);
            if(nextRecord) {
              if(nextRecord.Value.toLowerCase() === 'work' || nextRecord.Value.toLowerCase() === 'driving') {
                continue
              } else {
                if (parseInt(nextRecord.TotalTime) > 7 * 60 * 60 * 1000) {
                    if(groupedByDay.isNightWork) {
                      if(groupedByDay.sumWorkTotalTime > 10 * 60 * 60 * 1000) {
                        count++
                        arrayNightWork.push({
                          Prekršaj: count,
                          Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                          Kompanija: company.Name,  // Assuming Company is available in the records
                          Poruka: `Kazna na dan ${formatSRBTime(day)} za noćni rad veći od 10h tačnije ${formatTime(groupedByDay.sumWorkTotalTime)}!`
                        });
                        groupedByDay = {
                          sumWorkTotalTime: 0,
                          isNightWork: false,
                        };
                      }
                  }
                }  
              }
            } else {
              if(groupedByDay.isNightWork) {
                if(groupedByDay.sumWorkTotalTime > 10 * 60 * 60 * 1000) {
                  count++
                    arrayNightWork.push({
                    Prekršaj: count,
                    Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                    Kompanija: company.Name,  // Assuming Company is available in the records
                    Poruka: `Kazna na dan ${formatSRBTime(day)} za noćni rad veći od 10h tačnije ${formatTime(groupedByDay.sumWorkTotalTime)}!`
                  });
                  groupedByDay = {
                    sumWorkTotalTime: 0,
                    isNightWork: false,
                  };
                }
            }
            }
            } else if(record.Value.toLowerCase() === 'work') {
              let date;
                if(groupedByDay.sumWorkTotalTime > 0) {
                  date = new Date(record.EndTime);
                } else {
                  date = new Date(record.StartTime);
                } 
              const hour = date.getHours();
              if (hour >= 0 && hour <= 3) {
                groupedByDay.isNightWork = true;
              }

              groupedByDay.sumWorkTotalTime += parseInt(record.TotalTime);
              if(nextRecord) {
                if(nextRecord.Value.toLowerCase() === 'work' || nextRecord.Value.toLowerCase() === 'driving') {
                  continue
                } else {
                  if (parseInt(nextRecord.TotalTime) > 7 * 60 * 60 * 1000) {
                      if(groupedByDay.isNightWork) {
                        if(groupedByDay.sumWorkTotalTime > 10 * 60 * 60 * 1000) {
                          count++
                          arrayNightWork.push({
                          Prekršaj: count,
                            Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                            Kompanija: company.Name,  // Assuming Company is available in the records
                            Poruka: `Kazna na dan ${formatSRBTime(day)} za noćni rad veći od 10h tačnije ${formatTime(groupedByDay.sumWorkTotalTime)}!`
                          });
                          groupedByDay = {
                            sumWorkTotalTime: 0,
                            isNightWork: false,
                          };
                        }
                    } else {
                      groupedByDay.sumWorkTotalTime = 0
                    }
                  }
                    
                }
              } else {
                if(groupedByDay.isNightWork) {
                  if(groupedByDay.sumWorkTotalTime > 10 * 60 * 60 * 1000) {
                      count++
                    arrayNightWork.push({
                      Prekršaj: count,
                      Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                      Kompanija: company.Name,  // Assuming Company is available in the records
                      Poruka: `Kazna na dan ${formatSRBTime(day)} za noćni rad veći od 10h tačnije ${formatTime(groupedByDay.sumWorkTotalTime)}!`
                    });
                    groupedByDay = {
                      sumWorkTotalTime: 0,
                      isNightWork: false,
                    };
                  }
              }
              }

                  
            } else {
              if(parseInt(record.TotalTime) > 7 * 60 * 60 * 1000){ 
                    if(groupedByDay.isNightWork) {
                      if(groupedByDay.sumWorkTotalTime > 10 * 60 * 60 * 1000) {
                        count++
                          arrayNightWork.push({
                          Prekršaj: count,
                          Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                          Kompanija: company.Name,  // Assuming Company is available in the records
                          Poruka: `Kazna na dan ${formatSRBTime(day)} za noćni rad veći od 10h tačnije ${formatTime(groupedByDay.sumWorkTotalTime)}!`
                        });
                        groupedByDay = {
                          sumWorkTotalTime: 0,
                          isNightWork: false,
                        };
                      } else {
                        groupedByDay = {
                          sumWorkTotalTime: 0,
                          isNightWork: false,
                        };
                      }
                  } else {
                    groupedByDay.sumWorkTotalTime = 0
                    groupedByDay.isNightWork = false
                  }
              } else if(parseInt(record.TotalTime) >= 4 * 60 * 60 * 1000 + 30 * 60 * 1000 && parseInt(record.TotalTime) <= 7 * 60 * 60 * 1000) {
                  for(let j=i+1; j<filtered.length; j++) {
                    let recordCheck = filtered[j]
                    let nextRecordCheck = filtered[j+1]
                      if(recordCheck) {
                        if(recordCheck.Value.toLowerCase() === 'break/rest' || recordCheck.Value.toLowerCase() === 'unknown') {
                          if(parseInt(recordCheck.TotalTime) > parseInt(record.TotalTime)) {
                            let timeEnd = new Date(recordCheck.StartTime).getTime();
                            let timeStart = new Date(record.EndTime).getTime();
                            if(timeEnd - timeStart < 24 * 60 * 60 * 1000) {
                              if(groupedByDay.isNightWork) {
                                if(groupedByDay.sumWorkTotalTime > 10 * 60 * 60 * 1000) {
                                  count++
                                    arrayNightWork.push({
                                    Prekršaj: count,
                                    Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                    Kompanija: company.Name,  // Assuming Company is available in the records
                                    Poruka: `Kazna na dan ${formatSRBTime(day)} za noćni rad veći od 10h tačnije ${formatTime(groupedByDay.sumWorkTotalTime)}!`
                                  });
                                  groupedByDay = {
                                    sumWorkTotalTime: 0,
                                    isNightWork: false,
                                  };
                                  break
                                }
                            } else {
                              groupedByDay.sumWorkTotalTime = 0
                              groupedByDay.isNightWork = false
                              break
                            }
                            }
                          } else if(nextRecordCheck) {
                            if(nextRecordCheck.Value.toLowerCase() === 'break/rest' || nextRecordCheck.Value.toLowerCase() === 'unknown') { 
                              if(parseInt(recordCheck.TotalTime) + parseInt(nextRecordCheck.TotalTime) > parseInt(record.TotalTime)) {
                                let timeEnd = new Date(recordCheck.StartTime).getTime();
                                let timeStart = new Date(record.EndTime).getTime();
                                if(timeEnd - timeStart < 24 * 60 * 60 * 1000) {
                                  if(groupedByDay.isNightWork) {
                                    if(groupedByDay.sumWorkTotalTime > 10 * 60 * 60 * 1000) {
                                      count++
                                        arrayNightWork.push({
                                        Prekršaj: count,
                                        Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                        Kompanija: company.Name,  // Assuming Company is available in the records
                                        Poruka: `Kazna na dan ${formatSRBTime(day)} za noćni rad veći od 10h tačnije ${formatTime(groupedByDay.sumWorkTotalTime)}!`
                                      });
                                      groupedByDay = {
                                        sumWorkTotalTime: 0,
                                        isNightWork: false,
                                      };
                                      break
                                    }
                                } else {
                                  groupedByDay.sumWorkTotalTime = 0
                                  groupedByDay.isNightWork = false
                                  break
                                }
                                  
                                }
                              }
                            }
                          }
                       
                        }
                      }
                  }
              } else {
                if(nextRecord) {
                  if(nextRecord.Value.toLowerCase() === 'driving' || nextRecord.Value.toLowerCase() === 'work' || nextRecord.Value.toLowerCase() === 'availability') {
                    continue
                  } else {
                    if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) > 7 * 60 * 60 * 1000) {
                      if(groupedByDay.isNightWork) {
                        if(groupedByDay.sumWorkTotalTime > 10 * 60 * 60 * 1000) {
                          count++
                            arrayNightWork.push({
                            Prekršaj: count,
                            Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                            Kompanija: company.Name,  // Assuming Company is available in the records
                            Poruka: `Kazna na dan ${formatSRBTime(day)} za noćni rad veći od 10h tačnije ${formatTime(groupedByDay.sumWorkTotalTime)}!`
                          });
                          groupedByDay = {
                            sumWorkTotalTime: 0,
                            isNightWork: false,
                          };
                        } else {
                          groupedByDay.sumWorkTotalTime = 0;
                          groupedByDay.isNightWork = false;
                        }

                    } else {
                      groupedByDay.sumWorkTotalTime = 0;
                      groupedByDay.isNightWork = false
                    }
                    }
                  }
                } else {
                  if(groupedByDay.isNightWork) {
                    if(groupedByDay.sumWorkTotalTime > 10 * 60 * 60 * 1000) {
                      count++
                        arrayNightWork.push({
                        Prekršaj: count,
                        Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                        Kompanija: company.Name,  // Assuming Company is available in the records
                        Poruka: `Kazna na dan ${formatSRBTime(day)} za noćni rad veći od 10h tačnije ${formatTime(groupedByDay.sumWorkTotalTime)}!`
                      });
                      groupedByDay = {
                        sumWorkTotalTime: 0,
                        isNightWork: false,
                      };
                    }
                }
                }
              }
            }

        }
      }
   
  return arrayNightWork;
  }
  
}