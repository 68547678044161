import React from 'react';
import './unos_zaposlenih.css';
import Slika from './zaposleni.jpg'

const UnosZaposlenih = props => {
  return (
    <div className="unos">
      <h1>UNOS ZAPOSLENIH</h1>
      <img src={Slika} alt="Unos zaposlenih"/>
      {props.children}
    </div>
  );
};

export default UnosZaposlenih;