import React from "react";

const Pumpa = (props) => {

  return (
    <tr style={{ height: "15px" }}>
      <td>{props.index + 1}</td>
      <td>{props.pumpa.label}</td>
      <td>{props.pumpa.state}</td>
    </tr>
  );
};
export default Pumpa;
