import React from 'react';
import './unos_delova.css';
import Slika from './delovi.jpg'

const UnosDelova = props => {
 
  return (
    <div className="unos">
      <h1>UNOS DELOVA</h1>
      <img src={Slika} alt="Unos delova"/>
      {props.children}
    </div>
  );
};

export default UnosDelova;