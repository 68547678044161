import React, { useContext, useRef, useState, useEffect } from "react";
import "./home.css";
import { confirmDialog } from "primereact/confirmdialog";
import { AuthContext } from "../../Header/auth - context";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import PlanFooter from "./plan_footer";

const Plan = (props) => {

  const toast = useRef(null);
  const auth = useContext(AuthContext);
  const [toggleButton, setToggleButton] = useState(true);
  const [toggleText, setToggleText] = useState(props.text);
  const [stateTicketDB, setStateTicketDB] = useState([]);
  const [stateTicketDrop, setStateTicketDrop] = useState(props.plan.Stanje);
  const [userDrop, setUserDrop] = useState(props.plan.Task_za);
  const [prioritetDrop, setPrioritetDrop] = useState(props.plan.Prioritet);
  const [textAreaView, setTextAreaView] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState('40%');

  const [gradient, setGradient] = useState(false);
  const thisDate = new Date(props.date);
  let newPreview = thisDate.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric' });

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };


  const footerDataSort = () => {
    let TaskOd = '';
    let TaskZa = '';
    let Prioritet = '';
    let Warn = false;
    for(let i=0; props.users.length > i; i++){
      if(props.users[i].value === props.plan.Task_od){
        TaskOd = props.users[i].label;
      }
      if(props.users[i].value === props.plan.Task_za){
        TaskZa = props.users[i].label;
      }
    }
    for(let i=0; props.prioritet.length > i; i++){
      if(props.prioritet[i].value === props.plan.Prioritet){
        Prioritet = props.prioritet[i].label;
        if(props.prioritet[i].value === 1){
          Warn = true;
        }
      }
    }
    
    return [TaskOd, TaskZa, Prioritet, Warn]
  }
 

  useEffect( () => {
    if(toggleText.length > 95) {
      setGradient(true);
    }
   
    let text = props.planAll.find(e => e.id === props.ID)
    if (text) {
      setToggleText(text.Text)
    }
    fetch(process.env.REACT_APP_BACKEND_URL + "/plan/stanje_plana")
      .then(handleErrors)
      .then((result) => {
        let state = [];
        for (let st of result) {
          let _state = {
            label: st.status,
            value: st.id,
          };
          state.push(_state);
        }
        setStateTicketDB(state);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
  }, [props.ID])

  const editButton = () => {
    setToggleButton(false)
    setTextAreaView(true)
    setGradient(false)
   
    if(toggleText.length > 95){
      setTextAreaHeight('60%')
    } else {
      setTextAreaHeight('40%')
    }
  }

  const saveButton = () => {
    let textNew = document.getElementById('textPlan').value;
    setToggleText(textNew)
    setToggleButton(true)

    if(toggleText.length > 95){
      setGradient(true)
      setTextAreaView(false)
    }
    if (
      props.ID !== null && props.ID !== undefined &&
      toggleText !== "" && textNew.length > 1 && textNew !== undefined
    ) {
      let requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          id: props.ID,
          text: textNew,
          state: stateTicketDrop,
          Task_za: userDrop,
          prioritet: prioritetDrop
        })
      };

      if (props.text !== toggleText || props.plan.Stanje !== stateTicketDrop || props.plan.Task_za !== userDrop || props.plan.Prioritet !== prioritetDrop) {
        
        // if(props.plan.Stanje !== stateTicketDrop){
        //     props.renderMethod();
        // }
        // if(props.plan.Task_za !== userDrop){
        //   for(let i=0; i<props.users.length; i++){
        //     if(props.users[i].value === userDrop){
        //       setStateFooter({type: 'OPERATER_ZA', value: props.users[i].label})
        //     }
        //   }
        // }
        // if(props.plan.Prioritet !== prioritetDrop){
        //   for(let i=0; i<props.prioritet.length; i++){
        //     if(props.prioritet[i].value === prioritetDrop){
        //       setStateFooter({type: 'PRIORITET', value: props.prioritet[i].label})
        //       if (props.prioritet[i].value !== 1) {
        //         setStateFooter({type: 'WARNING', value: false})
        //       } else {
        //         setStateFooter({type: 'WARNING', value: true})
        //       }
        //     }
        //   }
        // }
        
        fetch(
          process.env.REACT_APP_BACKEND_URL + "/plan",
          requestOptions
        )
          .then(handleErrors)
          .then((data) => {
            if(data.message === 'Stari plan je isti kao novi plan!') {
              toast.current.show({
                severity: "info",
                summary: "Info",
                detail: "Stari plan je isti kao novi plan!",
                life: 3000,
              });
              
            } else {
              toast.current.show({
                severity: "success",
                summary: "Ažuriranje uspešno!",
                detail: data.message,
                life: 3000,
              })
           
              props.render();
            }
          }
          )
          .catch((error) => {
            error.json().then((errorMessage) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMessage.message,
                life: 3000,
              });
              console.log(errorMessage);
            });
          });
      } else {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Stari plan je isti kao novi plan!",
          life: 3000,
        });
      }

    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Tekst tiketa mora biti veći od 2 karaktera!",
        life: 3000,
      });
    }
    setTextAreaView(false)
  }


  const handleDelete = () => {
    confirmDialog({
      message: "Jel ste sigurni da želite obrisati plan?",
      acceptLabel: "Da",
      rejectLabel: "Ne",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  const accept = () => {
    const planToDelete = props.planAll[props.index];

    if (props.index > -1) {

      const requestDelOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ id: planToDelete.id }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/plan/deletePlan", requestDelOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          
          let newPlanDB = props.planAll.slice();
          newPlanDB.splice(props.index, 1);
          if(props.plan.Stanje === 1){
            props.setPlan({type: 'PLAN_DB1', value: newPlanDB})
          }
          if(props.plan.Stanje === 2){
            props.setPlan({type: 'PLAN_DB2', value: newPlanDB})
          }
          if(props.plan.Stanje === 3){
            props.setPlan({type: 'PLAN_DB3', value: newPlanDB})
          }
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    }
    setToggleText(props.text)
  };
  const reject = () => {
    console.log("REJECTED DELETION!");
  };

  const textAreaViewHandler = () =>{
    // console.log(e)
    // if(e._reactName === 'onClick') {
    //   console.log(true)
    // }
    if(toggleButton){
      if(toggleText.length > 95 && !textAreaView){
        setGradient(false)
        setTextAreaView(textAreaView => !textAreaView)
      } else if(toggleText.length > 95 && textAreaView) {
        setGradient(true)
        setTextAreaView(textAreaView => !textAreaView)
      } else if(toggleText.length < 50){
        setGradient(false)
        setTextAreaHeight('40%')
      }
    }
    
  }
  const onScrollHandler = () => {
   if(gradient){
    setGradient(false)
  }
  }
    
  return (
    <div className="card" style={{height: textAreaView ? textAreaHeight : props.height}}>
      <Toast ref={toast} />
      <div className="card-header">
        <div>
          <p><b>{props.ID}</b> |<i>{` Kreiran: `}</i>{` ${newPreview}`}</p>
        </div>
        <div id='btnPlan'>
          {toggleButton ? <Button onClick={editButton} className="p-button-sm p-button-raised p-button-primary" icon="pi pi-pencil" /> :
            <Button onClick={saveButton} className="p-button-sm p-button-raised p-button-success" icon="pi pi-check" />}


          <Button
            onClick={handleDelete}
            icon="pi pi-trash"
            className="p-button-sm p-button-raised p-button-danger"
          />
        </div>

      </div>
      <div className={!gradient ? 'card-bodyPlan' : 'card-bodyPlan card-bodyPlan-gradiant'} onScroll={onScrollHandler} onClick={textAreaViewHandler} >
        {toggleButton ?
          <p className="card-text">{toggleText}</p>
          : <div id='forma_izmene'>
             <section>
            <InputTextarea
              id='textPlan'
              style={{
                float: "left",
                width: "100%",
               
              }}
              autoResize 
              value={toggleText}
              className="textAreaPlan"
              onChange={(e) => setToggleText(e.target.value)}
              placeholder="Unesite plan"
            />
            </section>
          <section style={{position: 'sticky', bottom: '0', backgroundColor:'white'}}>
          <Dropdown
            value={stateTicketDrop}
            options={stateTicketDB}
            required
            onChange={(e) => setStateTicketDrop(e.value)}
            placeholder="Status"
            tooltip="Status zaduženja"
            tooltipOptions={{ position: 'top' }}
            className="plan_forma_izmena"
          />
          <Dropdown
              value={userDrop}
              options={props.users}
              required
              onChange={(e) => setUserDrop(e.value)}
              placeholder="Za zaposlenog"
              tooltip="Za zaposlenog"
              tooltipOptions={{position: 'top'}}
              className="plan_forma_izmena"
            />
          <Dropdown
              value={prioritetDrop}
              options={props.prioritet}
              required
              onChange={(e) => setPrioritetDrop(e.value)}
              placeholder="Proritet"
              tooltip="Prioritet"
              tooltipOptions={{position: 'top'}}
              className="plan_forma_izmena"
            />
        </section>
            </div>
           
        }
      </div>
      <PlanFooter operaterOd={footerDataSort()[0]} operaterZa={footerDataSort()[1]} warn={footerDataSort()[3]} prioritet={footerDataSort()[2]}/>
    </div>
  );
};
export default React.memo(Plan);

