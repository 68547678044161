import React, { useEffect, useContext, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import "./settings.css";
import { AuthContext } from "../../Header/auth - context";
import { useSelector } from "react-redux";
import PumpsItem from "./components/pumpsItem";
import { InputText } from "primereact/inputtext";
import { confirmDialog } from "primereact/confirmdialog";

const PumpsConfig = () => {
  const toast = useRef(null);
  const auth = useContext(AuthContext);
  const company = useSelector((state) => state.company);
  const [pumps, setPumps] = useState([]);
  const [pumpsToUpdate, setPumpsToUpdate] = useState(null);
  const [pumpsToUpdateID, setPumpsToUpdateID] = useState(null);
  const [pumpsInput, setPumpsInput] = useState("");
  
  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  const handlePumpInput = () => {

    let identifiedPump = pumps.find(u => u.label.toLowerCase() === pumpsInput.toLowerCase())

    if (pumpsInput.length > 0 && pumpsInput !== "") {
      if(identifiedPump === undefined){
        let requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          body: JSON.stringify({
            firma_id: company.companyId,
            naziv: pumpsInput,
          }),
        };
        fetch(
          process.env.REACT_APP_BACKEND_URL + "/fuel/insertPump",
          requestOptions
        )
          .then(handleErrors)
          .then(
            (data) =>
              toast.current.show({
                severity: "success",
                summary: "Uspešno dodavanje pumpe!",
                detail: data.message,
                life: 3000,
              }),
              setPumpsToUpdate(null),
              setPumpsToUpdateID(null),
              setPumpsInput(''),
              setTimeout(()=>{
                fetchPumps()
              },100)
          )
          .catch((error) => {
            error.json().then((errorMessage) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMessage.message,
                life: 3000,
              });
              console.log(errorMessage);
            });
          });
      } else {
        toast.current.show({
          severity: "info",
          summary: "Upozorenje!",
          detail: "Dodavanje pumpe nije uspelo. Isti naziv pumpe već postoji!",
          life: 3000,
        });
        setPumpsToUpdate(null),
        setPumpsToUpdateID(null),
        setPumpsInput('')
      }
    } else {
      toast.current.show({
        severity: "info",
        summary: "Upozorenje!",
        detail: "Dodavanje magacina nije uspelo. Naziv mora biti veći od 1 karaktera!",
        life: 3000,
      });
      setPumpsToUpdate(null)
      setPumpsToUpdateID(null)
    }
  };

  const handlePumpUpdate = () => {
    if (pumpsToUpdate !== null && pumpsToUpdateID !== null && pumpsInput.length > 0) {
      let requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          firma_id: company.companyId,
          id: pumpsToUpdateID,
          naziv: pumpsInput,
        }),
      };
      fetch(
        process.env.REACT_APP_BACKEND_URL + "/fuel/pump",
        requestOptions
      )
        .then(handleErrors)
        .then(
          (data) => {
            if(data.message === 'Izmena nije uspela, isti podaci!'){
              toast.current.show({
                severity: "warn",
                summary: "Upozorenje!",
                detail: data.message,
                life: 3000
              })
            } else {
              toast.current.show({
                severity: "success",
                summary: "Uspešno ažuriranje!",
                detail: data.message,
                life: 3000,})
            }
            setPumpsToUpdate(null),
            setPumpsToUpdateID(null),
            setPumpsInput('')
          }
        )
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            setPumpsToUpdate(null),
            setPumpsToUpdateID(null),
            setPumpsInput('')
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Ažuriranje nije uspelo!",
        life: 3000,
      });
    }
  };
  const accept = () => {
    if (pumpsToUpdateID !== null) {
      let requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          id: pumpsToUpdateID,
        }),
      };
      fetch(
        process.env.REACT_APP_BACKEND_URL + "/fuel/pump",
        requestOptions
      )
        .then(handleErrors)
        .then(
          (data) => {
            toast.current.show({
              severity: "success",
              summary: "Uspešno brisanje!",
              detail: data.message,
              life: 3000,
            }),
            setTimeout(()=>{
              fetchPumps()
            },100),
            setPumpsToUpdate(null)
            setPumpsToUpdateID(null)
            pumpsInput('')
          }
        )
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
        toast.current.show({
          severity: "info",
          summary: "Obaveštenje!",
          detail: "Morate prvo selektovati pumpu!",
          life: 3000,
        })
      }
  };

  const reject = () => {
    console.log("REJECTED DELETION!");
    setPumpsToUpdate(null)
    setPumpsToUpdateID(null)
  };
  const handleDeletePump = () => {
    if(pumpsToUpdateID !== null){
      confirmDialog({
        message: "Jel ste sigurni da želite obrisati pumpu?",
        acceptLabel: "Da",
        rejectLabel: "Ne",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",
        accept,
        reject,
      });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Obaveštenje!",
        detail: "Morate prvo selektovati pumpu!",
        life: 3000,
      });
    }
  };

  const handleEditPump = (index) => {
    setPumpsToUpdate(index);
    setPumpsToUpdateID(index.value);
    setPumpsInput(index.label);
  };
const fetchPumps = ()=> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.token,
    },
    body: JSON.stringify({
      firma_id: company.companyId,
    }),
  };

  fetch(process.env.REACT_APP_BACKEND_URL + "/fuel", requestOptions)
    .then(handleErrors)
    .then((result) => {
      let p = [];
      for (let pump of result) {
        let _pump = {
          label: pump.pumpa,
          value: pump.id,
        };
        p.push(_pump);
      }
      setPumps(p)
    })
    .catch((error) => {
      console.log(error);
      error.json().then((errorMessage) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessage.message,
          life: 3000,
        });
        console.log(errorMessage);
      });
    });
}
  useEffect(() => {
    fetchPumps()
  }, [pumpsToUpdateID,pumpsInput]);

  return (
      <section id='config_workspace_section'>
      <Toast ref={toast} />
        {" "}
        <div
          onSubmit={(event) => event.preventDefault()}
          className="form_warehouse"
        >
          <div>
            <InputText
              placeholder="Naziv pumpe..."
              value={pumpsInput}
              onChange={(e) => setPumpsInput(e.target.value)}
            />
          </div>
          <div>
            {" "}
            <button
              type="submit"
              onClick={handlePumpUpdate}
              className="btn btn-warning"
            >
              AŽURIRAJ
            </button>
            <button  type="submit" className="btn btn-danger" onClick={handleDeletePump}>
                  OBRIŠI
                </button>
            <button
              type="submit"
              onClick={handlePumpInput}
              className="btn btn-success"
            >
              UNOS
            </button>
          </div>
        </div>
        <div className="warehouse_workspace">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Naziv</th>
                <th>Izaberi</th>
              </tr>
            </thead>
            <tbody>
              {pumps.map((item, i) => (
                <PumpsItem
                  pump={item}
                  key={i}
                  index={i}
                  edit={handleEditPump}
                />
              ))}
            </tbody>
          </table>
        </div>
      </section>
  );
};

export default PumpsConfig;
