import React from 'react'

const PlanFooter = (props) =>{

return (
    <div className="card-footer">
          <div>
            <p><b>Od:</b> <i>{props.operaterOd}</i></p>
          </div>
          <div>
            <p><b>Za:</b> <i>{props.operaterZa}</i></p>
          </div>
          <div>
            <p><b>Prioritet:</b> <i style={{ color: props.warn && 'red', fontWeight: props.warn && '700', fontSize: props.warn && '12px'}}>{props.prioritet}</i></p>
          </div>
      </div>
)
}
export default PlanFooter;