import React, { useState, useRef} from "react";
import ReactDOM from "react-dom";
import "./unosgorivapopup.css";
import SipanjaVozila from "./sipanja_vozila.js";
import { Toast } from "primereact/toast";
import { CSSTransition } from "react-transition-group";
import { Button } from "primereact/button";
import { InputSwitch } from 'primereact/inputswitch';
import SipanjaPumpe from "./sipanja_pumpe";

const UnosGorivaPop = (props) => {
  const nodeRef = React.useRef(null);
  const toast = useRef(null);
  const [status, setStatus] = useState(false);

  const content = (
    <CSSTransition
      in={props.show}
      timeout={300}
      classNames="slide-in-top"
      mountOnEnter
      unmountOnExit
      nodeRef={nodeRef}
    >
      <div id="unos_g_popup" ref={nodeRef}>
        <section>
          <div className="header_popup_g">
            <InputSwitch id="toggle_fuel_window" checked={status} onChange={(e) => setStatus(e.value)}/>
            <h2>UNOS GORIVA</h2>
            <Button
              onClick={props.close}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger"
            />
          </div>
          <Toast ref={toast} />
         
            <SipanjaVozila status={status}/>
            <SipanjaPumpe status={status}/>
          
        </section>
      </div>
    </CSSTransition>
  );
  return ReactDOM.createPortal(content, document.getElementById("ug-hook"));
};

export default UnosGorivaPop;
