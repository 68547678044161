import React, {useState, useEffect} from 'react';
import '../servis.css'
import Slika from './vozila.jpg'
import {CSSTransition} from 'react-transition-group';
import { Button } from 'primereact/button';


const Serviced_vehicles = props =>{
    
    const nodeRef = React.useRef(null);
    const [show, setShow] = useState(false);
    const [filtered, setFiltered] = useState([]);
    const [uniqueBRN, setUniqueBRN] = useState([])

    const openPopupData = () => {
        setShow(true);
        
        let filter = [];
     for(let i=0; i<props.largeData.length; i++){
        if(props.largeData[i].Garazni === props.data){
            let obj= {
                id: '',
                Broj_radnog_naloga:'',
                Garazni:'',
                Tip_odrzavanja:'',
                Magacin:'',
                Deo:'',
                ZaposleniUD:'',
                OdgovorniMajstor:'',
                newDatum_PO:'',
                newDatum_ZO:'',
                Kilometraza:'',
                Kolicina:'',
                opis_komentar:''
              }
              obj.id= props.largeData[i].id;
              obj.Broj_radnog_naloga=props.largeData[i].Broj_radnog_naloga;
              obj.Garazni=props.largeData[i].Garazni;
              obj.Tip_odrzavanja=props.largeData[i].Tip_odrzavanja;
              obj.Magacin=props.largeData[i].Magacin;
              obj.Deo=props.largeData[i].Deo;
              obj.ZaposleniUD=props.largeData[i].ZaposleniUD;
              obj.OdgovorniMajstor=props.largeData[i].OdgovorniMajstor;
              if(props.largeData[i].newDatum_PO === null) {
                let dateNEW_PO = null;
                obj.newDatum_PO=dateNEW_PO
              } else{
                let datePO = new Date(props.largeData[i].newDatum_PO);
                let dateNEW_PO = datePO.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
                obj.newDatum_PO=dateNEW_PO
              }
              if(props.largeData[i].newDatum_ZO === null) {
                let dateNEW_ZO = null;
                obj.newDatum_ZO=dateNEW_ZO
              } else{
                let dateZO = new Date(props.largeData[i].newDatum_ZO);
                let dateNEW_ZO = dateZO.toLocaleDateString('sr-RS', { month: '2-digit', day: '2-digit', year: 'numeric'});
                obj.newDatum_ZO=dateNEW_ZO
              }
              obj.Kilometraza=props.largeData[i].Kilometraza;
              obj.Kolicina=props.largeData[i].Kolicina;
              obj.opis_komentar=props.largeData[i].opis_komentar;
            filter.push(obj)
        }
        
     }
     setFiltered(filter)
    }

    const closePopupData = (e) => {
        e.stopPropagation();  
        setShow(false);
    }

    

    useEffect(()=>{
        
            let uniqueValues = filtered.map(item => item.Broj_radnog_naloga)
            .filter((value, index, self) => self.indexOf(value) === index)
                       
        setUniqueBRN([...uniqueValues]) 
        
        
    },[filtered])

    
return (
  
    <React.Fragment>
          <div key={props.index} onClick={openPopupData} className="odrzavana_vozila_item">
            <img src={Slika} alt="Odrzavano vozilo"/>
            <div className="vozila_odrzavana_reg">{props.data}</div>
         </div>
         <CSSTransition in={show} timeout={300} classNames="slide-in-top" mountOnEnter unmountOnExit nodeRef={nodeRef}>
                    <div id="data" ref={nodeRef}>
                        <div className="header_vozila_odrzavana"><h2>{props.data}</h2>
                        <Button onClick={closePopupData} icon="pi pi-times" className="p-button-rounded p-button-danger"/>
                        </div>
                        <div className="servis_filtered" >
                        {uniqueBRN.length > 0 ? uniqueBRN.map((item, i)=>(
                                 <div className="servis_filtered_item" key={i}>
                                     <div className="servis_filtered_item_brnaloga">Broj naloga: {item}</div>
                                     <div className="servis_filtered_item_stavke">
                                     <table>
                                        <thead>
                                        <tr>
                                            <th>Deo</th>
                                            <th>Magacin</th>
                                            <th>ZUD</th>
                                            <th>OdgMaj.</th>
                                            <th>DPO</th>
                                            <th>DZO</th>
                                            <th>Kilometraža</th>
                                            <th>Količina</th>
                                            <th>Komentar</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                         {filtered.filter((itemFilter)=>itemFilter.Broj_radnog_naloga===item).map((stavka, j)=>(
                                         <tr key={j}>
                                        <td>{stavka.Deo}</td>
                                         <td>{stavka.Magacin}</td>
                                         <td>{stavka.ZaposleniUD}</td>
                                         <td>{stavka.OdgovorniMajstor}</td>
                                         <td>{stavka.newDatum_PO}</td>
                                         <td>{stavka.newDatum_ZO}</td>
                                         <td>{stavka.Kilometraza}</td>
                                         <td>{stavka.Kolicina}</td>
                                         <td>{stavka.opis_komentar}</td>
                                         </tr>
                                     ))}</tbody></table>
                                     </div>
                                </div>
                          )): `Nema naloga za vozilo === ${props.data}`}
                        </div>
                    </div>
        </CSSTransition>
    </React.Fragment>
  );
};

export default Serviced_vehicles;
