import React, { useState, useRef, useContext } from 'react';
import { Toast } from 'primereact/toast';
import './login.css';
import Slika from './loginbg-min.jpg';
import Icon from './icon.jpg';
import { AuthContext } from '../Header/auth - context';
import { TabMenu } from 'primereact/tabmenu';
import { useDispatch } from "react-redux";



const Login = (props) => {

    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleErrors = (response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw response;
        }
    }
    const infoAlarmCheck = (id) => {
        const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            },
            body: JSON.stringify({
              firma_id: id,
            }),
          };
         fetch(
            process.env.REACT_APP_BACKEND_URL + "/alarms/info",
            requestOptions
          )
            .then(handleErrors)
            .then((result) => {
              if (
                result.partInfo.length > 0 ||
                result.vehicleReg.length > 0 ||
                result.vehicleSes.length > 0 ||
                result.vehicleTaho.length > 0
              ) {
                setTimeout(()=>{
                    dispatch({
                        type: "COUNTER",
                        value:
                          result.partInfo.length +
                          result.vehicleReg.length +
                          result.vehicleSes.length +
                          result.vehicleTaho.length,
                      });
                },[100])
              }
            });
      };
    const loginSubmitHandler = () => {

        let emailInput = document.getElementById('emaillogin').value;
        let passwordInput = document.getElementById('passlogin').value;
        if (emailInput === "" || passwordInput === "") {
            toast.current.show({ severity: 'info', summary: 'Obaveštenje', detail: 'Unesite email i lozinku!', life: 3000 });
        } else {
            let requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: emailInput, password: passwordInput })
            };
            fetch(process.env.REACT_APP_BACKEND_URL + "/users/login", requestOptions)
                .then(handleErrors)
                .then(data => {
                    props.getUser(data.user)
                    auth.login(data.userId, data.token, data.user.name, data.user.surname)
                    infoAlarmCheck(data.user.firma_id);
                    dispatch({
                        type: "COMPANY",
                        value: 
                        {companyId: data.user.firma_id,
                        companyName: data.user.Naziv}
                      });
                      dispatch({
                        type: "USER_ROLE",
                        value: 
                        {roleId: data.user.role_id}
                      });
                }).catch((error) => {
                    error.json().then(errorMessage => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage.message, life: 3000 });
                        console.log(errorMessage);

                    })

                });
        }
    }

    const signUpSubmitHandler = () => {

        let nameInput = document.getElementById('nameSignUp').value;
        let surnameInput = document.getElementById('surnameSignUp').value;
        let emailInput = document.getElementById('emailSignUp').value;
        let passwordInput = document.getElementById('passSignUp').value;
        if (emailInput === "" || passwordInput === "" || nameInput === "" || surnameInput === "") {
            toast.current.show({ severity: 'info', summary: 'Obaveštenje', detail: 'Unesite sve prerekvizite forme!', life: 3000 });
        } else {
            let requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: emailInput, password: passwordInput, name: nameInput, surname: surnameInput })
            };
            fetch(process.env.REACT_APP_BACKEND_URL + "/users/signup", requestOptions)
                .then(handleErrors)
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data.message, life: 3000 })
                }).catch((error) => {
                    error.json().then(errorMessage => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage.message, life: 3000 });
                        console.log(errorMessage);

                    })

                });
        }
    }



    const sendPassHandler = () => {
        let emailSend = document.getElementById('emailPassSend').value;
   
        if (emailSend === "" || emailSend.includes('@') === false) {
            toast.current.show({ severity: 'warn', summary: 'Obaveštenje', detail: 'Unesite ispravan email!', life: 3000 });
        } else {
            let requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: emailSend })
            };
            fetch(process.env.REACT_APP_BACKEND_URL + "/users/passSend", requestOptions)
                .then(handleErrors)
                .then(data => {
                    toast.current.show({ severity: 'success', summary: 'Uspešno', detail: data.message, life: 3000 });
                    document.getElementById('emailPassSend').value = '';
                    setActiveIndex(0);
                }).catch((error) => {
                    error.json().then(errorMessage => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: errorMessage.message, life: 3000 });
                        console.log(errorMessage);

                    })

                });
        }
    }

    const items = [
        { label: 'Prijava', icon: 'pi pi-user' },
        { label: 'Registracija', icon: 'pi pi-user-plus' },
        { label: 'Nova lozinka', icon: 'pi pi-users' }
    ];
    return (
        <div id='login_screen'><Toast ref={toast} />

            <img id='login_background' src={Slika} alt="Login background" />
            <div id='loginform'>
                <img id='avatar' src={Icon} alt="Icon" />
                <TabMenu id='modelTab' activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} style={{ marginBottom: '0' }} model={items} />
                {activeIndex === 0 && <h3>Prijava na sistem</h3>}
                {activeIndex === 0 &&
                    <form id="login" onSubmit={e => e.preventDefault()}>
                        <div className="inputform">
                            <label htmlFor="inputEmail4" className="form-label">Email</label>
                            <input type="email" className="form-control" id="emaillogin" placeholder="Unesite Email" />
                        </div>
                        <div className="inputform">
                            <label htmlFor="inputPassword4" className="form-label">Lozinka</label>
                            <input type="password" className="form-control" id="passlogin" autoComplete="on" placeholder="Unesite lozinku" />
                        </div>

                        <div>
                            <button type="submit" onClick={loginSubmitHandler} className="btn btn-warning">Prijava</button>
                        </div>
                        
                    </form>
                }
                {activeIndex === 1 && <h3>Registracija</h3>}
                {activeIndex === 1 ? (
                    <form onSubmit={e => e.preventDefault()}>
                        <div id="firstPart">
                            <div style={{ marginBottom: "0", marginRight: "5%" }} className="inputform">
                                <label htmlFor="inputEmail4" className="form-label">Ime</label>
                                <input style={{ width: "100%" }} type="text" className="form-control" id="nameSignUp" placeholder="Unesite ime" />
                            </div>
                            <div style={{ marginBottom: "0", marginLeft: "5%" }} className="inputform">
                                <label htmlFor="inputPassword4" className="form-label">Prezime</label>
                                <input style={{ width: "100%" }} type="text" className="form-control" id="surnameSignUp" placeholder="Unesite prezime" />
                            </div>
                        </div>

                        <div id='signup'>
                            <div className="inputform">
                                <label htmlFor="inputEmail4" className="form-label">Email</label>
                                <input type="email" className="form-control" id="emailSignUp" placeholder="Unesite Email" />
                            </div>
                            <div className="inputform">
                                <label htmlFor="inputPassword4" className="form-label">Lozinka</label>
                                <input type="password" className="form-control" autoComplete="on" id="passSignUp" placeholder="Unesite lozinku" />
                            </div>


                            <button type="submit" onClick={signUpSubmitHandler} className="btn btn-warning">Registracija</button>
                        </div>

                    </form>
                ) : null}
                {activeIndex === 2 && <h3>Pošalji zahtev za novu lozinku</h3>}
                {activeIndex === 2 ? (
                    <form id="login" onSubmit={e => e.preventDefault()}>
                        <div className="inputform">
                            <label htmlFor="inputEmail4" className="form-label">Email</label>
                            <input type="email" className="form-control" id="emailPassSend" placeholder="Unesite Email" />
                        </div>
                        <div>
                            <button type="submit" onClick={sendPassHandler} className="btn btn-warning">Pošaljite zahtev</button>
                        </div>

                        
                    </form>
                ) : null}
            </div>

        </div>
    );
};

export default Login;