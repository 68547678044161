import React from 'react';
import Slika from './sipajne_img.jpg'

const UnosGoriva = props => {
  return (
    <div className="unos">
      <h1>UNOS GORIVA</h1>
      <img src={Slika} alt="Unos goriva"/>
      {props.children}
    </div>
  );
};

export default UnosGoriva;