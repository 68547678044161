import React, { useEffect, useState, useRef, useContext } from "react";
import "./unosvozilapopup.css";
import ReactDOM from "react-dom";
import Vozila from "./vozila.js";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { AuthContext } from "../../../../Header/auth - context";
import { confirmDialog } from "primereact/confirmdialog";
import { CSSTransition } from "react-transition-group";
import { Button } from "primereact/button";
import {useDispatch, useSelector} from 'react-redux';

const UnosVozilaPop = (props) => {
  const dispatch = useDispatch();
  const company = useSelector(state => state.company)
  const nodeRef = React.useRef(null);
  const auth = useContext(AuthContext);
  const toast = useRef(null);
  const [stavkav, setStavkav] = useState([]);
  const [filteredVehicle, setfilteredVehicle] = useState([]);
  const [stavkaVozToUpdate, setStavkaVozToUpdate] = useState([]);
  const [vozDB, setVozDB] = useState("");
  const [dropRegion, setDropRegion] = useState("");
  const [reg, setReg] = useState([]);
  const [dropvType, setDropvType] = useState("");
  const [vType, setvType] = useState([]);
  const [dateUVP, setDateUVP] = useState(null);
  const [dateS, setDateS] = useState(null);
  const [dateR, setDateR] = useState(null);
  const [dateT, setDateT] = useState(null);
  const [sasija, setSasija] = useState("");
  const [milage, setMilage] = useState(null);
  const [registracija, setRegistracija] = useState("");
  const [valueTAV, setValueTAV] = useState("");
  const [idToUpdate, setIdToUpdate] = useState(null);
  const [render, setRender] = useState(false);

  const handleDeleteStavkav = (index) => {
    if (index > -1) {
      const item = stavkav.slice();
      item.splice(index, 1);
      setStavkav(item);
    }
  };
  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  const handleInputV = () => {
    const stavkav = {
      garazni: "",
      registracija: "",
      tip: "",
      datumvp: "",
      datums: "",
      datumreg: "",
      datumtaho: "",
      brojsas: "",
      region: "",
      kilometraza: "",
      opis: "",
    };
    let registracija = document.getElementsByClassName("regv")[0].value;
    let garazni = document.getElementsByClassName("p-autocomplete-input")[0]
      .value;
    let brojsas = document.getElementsByClassName("bsv")[0].value;
    let opis = document.getElementsByClassName("opisv")[0].value;

    if (
      registracija !== "" &&
      dropvType !== "" &&
      garazni !== "" &&
      dateUVP !== null &&
      brojsas !== "" &&
      milage !== ""
    ) {
      stavkav.registracija = registracija;
      stavkav.garazni = garazni;
      stavkav.tip = dropvType;

      if (dateUVP === null) {
        stavkav.datum_PR = dateUVP;
      } else {
        let newDateUVP = new Date(dateUVP);
        newDateUVP.setHours(newDateUVP.getHours() + 2);
        stavkav.datumvp = newDateUVP.toISOString().substr(0, 10);
      }
      if (dateS === null) {
        stavkav.datums = dateS;
      } else {
        let newDateS = new Date(dateS);
        newDateS.setHours(newDateS.getHours() + 2);
        stavkav.datums = newDateS.toISOString().substr(0, 10);
      }
      if (dateR === null) {
        stavkav.datumreg = dateR;
      } else {
        let newDateR = new Date(dateR);
        newDateR.setHours(newDateR.getHours() + 2);
        stavkav.datumreg = newDateR.toISOString().substr(0, 10);
      }
      if (dateT === null) {
        stavkav.datumtaho = dateT;
      } else {
        let newDateT = new Date(dateT);
        newDateT.setHours(newDateT.getHours() + 2);
        stavkav.datumtaho = newDateT.toISOString().substr(0, 10);
      }
      stavkav.brojsas = brojsas;
      stavkav.region = dropRegion;
      stavkav.kilometraza = milage;
      stavkav.opis = opis;

      if( dropvType > 1 && (dateS === null || dateR === null || dateT === null) ) {
        toast.current.show({
          severity: "warn",
          summary: "Upozorenje!",
          detail: "Niste uneli ostale podatke za vozilo!",
          life: 3000,
        });
      } else {
        setStavkav((item) => [...item, stavkav]);
        deleteContentVehicle()
      }
    }  else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste uneli sve podatke za vozilo!",
        life: 3000,
      });
    }
  };
  const handleInputVehicleDBHandler = () => {

    if(stavkav.length > 0){
    for (let i = 0; i < stavkav.length; i++) {
      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          firma_id: company.companyId,
          garazni_broj: stavkav[i].garazni,
          registracija: stavkav[i].registracija,
          tip_id: stavkav[i].tip,
          datum_UVP: stavkav[i].datumvp,
          datum_SESTOM: stavkav[i].datums,
          datum_REG: stavkav[i].datumreg,
          datum_TAHO: stavkav[i].datumtaho,
          broj_sasije: stavkav[i].brojsas,
          region_id: stavkav[i].region,
          kilometraza: stavkav[i].kilometraza,
          opis_komentar: stavkav[i].opis,
        }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles/insertVehicle", requestOptions)
        .then(handleErrors)
        .then((data) => {
          toast.current.show({
            severity: "success",
            summary: "Unos uspešan!",
            detail: data.message,
            life: 3000,
          });
          setStavkav([]);
          deleteContentVehicle();
          fetch(process.env.REACT_APP_BACKEND_URL + "/alarms/info", requestOptions)
          .then(handleErrors)
          .then((result) => {
            setTimeout(()=>{
              if (result.partInfo.length  > 0 || result.vehicleReg.length  > 0 || result.vehicleSes.length  > 0 || result.vehicleTaho.length  > 0) {
                dispatch({type: 'COUNTER', value: result.partInfo.length + result.vehicleReg.length + result.vehicleSes.length + result.vehicleTaho.length})
                }
          },[100])
          })
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    }} else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: 'Nemate unetu ni jednu stavku. Nakon dodavanja stavke pokušajte unos ponovo.',
        life: 3000,
      });
    }
  };
  const handleUpdateV = () => {
    let registracija = document.getElementsByClassName("regv")[0].value;
    let garazni = document.getElementsByClassName("p-autocomplete-input")[0]
      .value;
    let brojsas = document.getElementsByClassName("bsv")[0].value;
    let opis = document.getElementsByClassName("opisv")[0].value;

    let datumUVP;
    if (dateUVP === null) {
      datumUVP = null;
    } else {
      let newDateUVP = new Date(dateUVP);
      newDateUVP.setHours(newDateUVP.getHours() + 2);
      datumUVP = newDateUVP.toISOString().substr(0, 10);
    }
    let datumS;
    if (dateS === null) {
      datumS = null;
    } else {
      let newDateS = new Date(dateS);
      newDateS.setHours(newDateS.getHours() + 2);
      datumS = newDateS.toISOString().substr(0, 10);
    }
    let datumR;
    if (dateR === null) {
      datumR = null;
    } else {
      let newDateR = new Date(dateR);
      newDateR.setHours(newDateR.getHours() + 2);
      datumR = newDateR.toISOString().substr(0, 10);
    }
    let datumT;
    if (dateT === null) {
      datumT = null;
    } else {
      let newDateT = new Date(dateT);
      newDateT.setHours(newDateT.getHours() + 2);
      datumT = newDateT.toISOString().substr(0, 10);
    }

    if (idToUpdate !== null) {
      let requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          id: idToUpdate,
          garazni_broj: garazni,
          registracija: registracija,
          tip_id: dropvType,
          datum_UVP: datumUVP,
          datum_SESTOM: datumS,
          datum_REG: datumR,
          datum_TAHO: datumT,
          broj_sasije: brojsas,
          region_id: dropRegion,
          kilometraza: milage,
          opis_komentar: opis,
        }),
      };
      const requestOptions2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ 
          firma_id: company.companyId
         }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", requestOptions)
        .then(handleErrors)
        .then((data) =>
          toast.current.show({
            severity: "success",
            summary: "Ažuriranje uspešno!",
            detail: data.message,
            life: 3000,
          }),
          deleteContentVehicle(),
          fetch(process.env.REACT_APP_BACKEND_URL + "/alarms/info", requestOptions2)
          .then(handleErrors)
          .then((result) => {
            setTimeout(()=>{
              if (result.partInfo.length  > 0 || result.vehicleReg.length  > 0 || result.vehicleSes.length  > 0 || result.vehicleTaho.length  > 0) {
                dispatch({type: 'COUNTER', value: result.partInfo.length + result.vehicleReg.length + result.vehicleSes.length + result.vehicleTaho.length})
                }
          },[100])
          })
        )
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste izabrali vozilo koje želite da ažurirate!",
        life: 3000,
      });
    }
  };
  useEffect(() => {

    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({
        firma_id: company.companyId
      }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setVozDB(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
    fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles/vehicletype")
      .then(handleErrors)
      .then((result) => {
        let vtype = [];
        for (let tip of result) {
          let _type = {
            label: tip.tip,
            value: tip.id,
          };
          vtype.push(_type);
        }
        setvType(vtype);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
    fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles/region")
      .then(handleErrors)
      .then((result) => {
        let region = [];
        for (let reg of result) {
          let _reg = {
            label: reg.region,
            value: reg.id,
          };
          region.push(_reg);
        }
        setReg(region);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
  }, [idToUpdate, stavkav, render]);

  const searchVehicle = (event) => {
    let query = event.query;
    let _filteredVehicle = [];
    let filteredItems = vozDB.filter(
      (item) =>
        item.garazni_broj.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (filteredItems && filteredItems.length) {
      _filteredVehicle.push(...filteredItems);
    }

    setfilteredVehicle(_filteredVehicle);
  };
  const stavkaVozToUpdateFUNC = (e) => {
    setIdToUpdate(e.value.id);
    setDropRegion(e.value.region_id);
    setDropvType(e.value.tip_id);
    setRegistracija(e.value.registracija);

    if (e.value.datum_UVP === null) {
      setDateUVP(null);
    } else {
      let datumUVP = new Date(e.value.datum_UVP);
      setDateUVP(datumUVP);
    }
    if (e.value.datum_SESTOM === null) {
      setDateS(null);
    } else {
      let datumS = new Date(e.value.datum_SESTOM);
      setDateS(datumS);
    }
    if (e.value.datum_REG === null) {
      setDateR(null);
    } else {
      let datumR = new Date(e.value.datum_REG);
      setDateR(datumR);
    }
    if (e.value.datum_TAHO === null) {
      setDateT(null);
    } else {
      let datumT = new Date(e.value.datum_TAHO);
      setDateT(datumT);
    }
    setSasija(e.value.broj_sasije);
    setMilage(e.value.kilometraza);
    setValueTAV(e.value.opis_komentar);

    setStavkaVozToUpdate(e.value);
  };
  const handleDeleteV = () => {
    confirmDialog({
      message: "Jel ste sigurni da želite obrisati vozilo?",
      acceptLabel: "Da",
      rejectLabel: "Ne",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };
  const accept = () => {
    if (idToUpdate !== null) {
      const requestOptions2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ 
          firma_id: company.companyId
         }),
      };
      let requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ id: idToUpdate }),
      };

      fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", requestOptions)
        .then(handleErrors)
        .then((result) => {
          toast.current.show({
            severity: "success",
            summary: "Brisanje uspešno!",
            detail: result.message,
            life: 3000,
          });
          fetch(process.env.REACT_APP_BACKEND_URL + "/alarms/info", requestOptions2)
          .then(handleErrors)
          .then((result) => {
            setTimeout(()=>{
              if (result.partInfo.length  > 0 || result.vehicleReg.length  > 0 || result.vehicleSes.length  > 0 || result.vehicleTaho.length  > 0) {
                dispatch({type: 'COUNTER', value: result.partInfo.length + result.vehicleReg.length + result.vehicleSes.length + result.vehicleTaho.length})
                }
          },[100])
          })
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste izabrali vozilo koje želite da obrišete!",
        life: 3000,
      });
    }
    deleteContentVehicle();
  };
  const reject = () => {
    console.log("REJECTED DELETION!");
  };
  const deleteContentVehicle = () => {
    setStavkaVozToUpdate([]);
    setRegistracija("");
    setDropvType(null);
    setSasija("");
    setDropRegion(null);
    setMilage(null);
    setDateUVP(null);
    setDateS(null);
    setDateR(null);
    setDateT(null);
    setValueTAV("");
    if (render === true) {
      setRender(false);
    } else {
      setRender(true);
    }
  }
  const handleClose = () => {
    deleteContentVehicle();
    setIdToUpdate(null);
    setStavkav([])
    props.close();
  }

  const content = (
    <CSSTransition
      in={props.show}
      timeout={300}
      classNames="slide-in-top"
      mountOnEnter
      unmountOnExit
      nodeRef={nodeRef}
    >
      <div id="unos_d_popup" ref={nodeRef}>
        <section>
          <div className="header_popup">
            <h2>UNOS VOZILA</h2>
            {props.children}
            <Button
              onClick={handleClose}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger"
            />
          </div>
          <Toast ref={toast} />
          <div className="main_popup_v">
            <form onSubmit={(event) => event.preventDefault()}>
              <AutoComplete
                inputStyle={{ width: "100%" }}
                panelClassName="garaz2"
                value={stavkaVozToUpdate}
                placeholder="Garažni broj"
                suggestions={filteredVehicle}
                completeMethod={searchVehicle}
                field="garazni_broj"
                onSelect={stavkaVozToUpdateFUNC}
                onChange={(e) => setStavkaVozToUpdate(e.value)}
              />
              <InputText
                value={registracija}
                style={{ marginLeft: "2%" }}
                onChange={(e) => setRegistracija(e.target.value)}
                className="registracija treciRedv regv"
                placeholder="Registracija"
              />
              <Dropdown
                value={dropvType}
                options={vType}
                required
                onChange={(e) => setDropvType(e.value)}
                placeholder="Izaberi tip vozila"
                className="treciRedv tipv"
              />
              <InputText
                value={sasija}
                onChange={(e) => setSasija(e.target.value)}
                className="treciRed bsv"
                style={{ margin: "0 0 0 2%", float: "left" }}
                placeholder="Broj šasije"
              />
              <Dropdown
                value={dropRegion}
                style={{ marginBottom: "0" }}
                options={reg}
                required
                onChange={(e) => setDropRegion(e.value)}
                placeholder="Izaberi region rada"
                className="treciRedv regionv"
              />
              <InputNumber
                value={milage}
                style={{ marginBottom: "0" }}
                onValueChange={(e) => setMilage(e.value)}
                placeholder="Kilometraza"
                className="treciRedv kilometraza"
                required
              />
              <Calendar
                value={dateUVP}
                inputStyle={{ float: "left" }}
                onChange={(e) => setDateUVP(e.value)}
                showIcon
                dateFormat="dd.mm.yy"
                className="cetvrtiRedv duvpv"
                required
                placeholder="Datum ulaska u VP"
                tooltip="Datum ulaska u VP" 
                tooltipOptions={{className: 'bluegray-tooltip', position: 'top'}}
              ></Calendar>
              <Calendar
                value={dateS}
                onChange={(e) => setDateS(e.value)}
                showIcon
                dateFormat="dd.mm.yy"
                className="cetvrtiRedv dsv"
                required
                placeholder="Datum šestomesečnog"
                tooltip="Datum šestomesečnog" 
                tooltipOptions={{className: 'bluegray-tooltip', position: 'top'}}
              ></Calendar>
              <Calendar
                value={dateR}
                onChange={(e) => setDateR(e.value)}
                showIcon
                dateFormat="dd.mm.yy"
                className="cetvrtiRedv drv"
                required
                placeholder="Datum registracije"
                tooltip="Datum registracije" 
                tooltipOptions={{className: 'bluegray-tooltip', position: 'top'}}
              ></Calendar>
              <Calendar
                value={dateT}
                onChange={(e) => setDateT(e.value)}
                showIcon
                dateFormat="dd.mm.yy"
                className="cetvrtiRedv drt"
                required
                placeholder="Baždarenje tahografa"
                tooltip="Datum baždarenja tahografa" 
                tooltipOptions={{className: 'bluegray-tooltip', position: 'top'}}
              ></Calendar>
              <InputTextarea
                rows={2}
                cols={30}
                value={valueTAV}
                className="opisv"
                onChange={(e) => setValueTAV(e.value)}
                placeholder="Opis / komentar"
              />
              <div className="buttonsUD">
                <button className="btn btn-secondary" onClick={handleInputV}>
                  +STAVKA
                </button>
                <button className="btn btn-warning" onClick={handleUpdateV}>
                  AŽURIRAJ
                </button>
                <button className="btn btn-danger" onClick={handleDeleteV}>
                  OBRIŠI
                </button>
              </div>
            </form>
          </div>
          <div className="second_popup_v">
            <div className="vozilo_stavka">
              {stavkav.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Garažni broj</th>
                      <th>Registracija</th>
                      <th>Datum ulaska u VP</th>
                      <th>Broj šasije</th>
                      <th>kilometraza</th>
                      <th>-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stavkav.map((item, i) => (
                      <Vozila
                        key={i}
                        vozila={item}
                        index={i}
                        delete={handleDeleteStavkav}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                "Niste uneli ni jednu stavku!"
              )}
            </div>
            <div className="form_v">
              <button
                className="btn btn-success"
                onClick={handleInputVehicleDBHandler}
              >
                UNOS
              </button>
            </div>
          </div>
        </section>
      </div>
    </CSSTransition>
  );
  return ReactDOM.createPortal(content, document.getElementById("uv-hook"));
};

export default UnosVozilaPop;
