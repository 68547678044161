import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import "./unoszaposlenihpopup.css";
import Zaposleni from "./zaposleni.js";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { AuthContext } from "../../../../Header/auth - context";
import { confirmDialog } from "primereact/confirmdialog";
import { CSSTransition } from "react-transition-group";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";

const UnosZaposlenihPop = (props) => {
  const nodeRef = React.useRef(null);
  const auth = useContext(AuthContext);
  const toast = useRef(null);
  const company = useSelector((state) => state.company);
  const [stavkaZ, setStavkaZ] = useState([]);
  const [filteredEmployee, setfilteredEmployee] = useState([]);
  const [stavkaEMPToUpdate, setStavkaEMPToUpdate] = useState([]);
  const [employeeDB, setEmployeeDB] = useState("");
  const [dropWorkRole, setDropWorkRole] = useState("");
  const [workR, setWorkR] = useState([]);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [datePR, setDatePR] = useState(null);
  const [dateZR, setDateZR] = useState(null);
  const [datePLP, setDatePLP] = useState(null);
  const [dateVVD, setDateVVD] = useState(null);
  const [dateVTK, setDateVTK] = useState(null);
  const [valueTAZ, setValueTAZ] = useState("");
  const [empToUpdate, setEmpToUpdate] = useState(null);
  const [render, setRender] = useState(false);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  const handleDeleteStavkaZ = (index) => {
    if (index > -1) {
      const item = stavkaZ.slice();
      item.splice(index, 1);
      setStavkaZ(item);
    }
  };

  const handleInputZ = () => {
    const stavkaZ = {
      ime: "",
      prezime: "",
      jmbg: "",
      radno_mesto: "",
      datum_PR: "",
      datum_ZR: "",
      datum_PLP: "",
      datum_VVD: "",
      datum_VTK: "",
      opis: "",
    };
    let ime = document.getElementsByClassName("ime")[0].value;
    let prezime = document.getElementsByClassName("prezime")[0].value;
    let jmbg = document.getElementsByClassName("p-autocomplete-input")[0].value;
    let opis = document.getElementsByClassName("opisz")[0].value;

    if (
      ime !== "" &&
      prezime !== "" &&
      jmbg !== "" &&
      dropWorkRole !== "" &&
      datePR !== ""
    ) {
      stavkaZ.ime = ime;
      stavkaZ.prezime = prezime;
      stavkaZ.jmbg = jmbg;
      stavkaZ.radno_mesto = dropWorkRole;
      if (datePR === null) {
        stavkaZ.datum_PR = datePR;
      } else {
        let newDatePR = new Date(datePR);
        newDatePR.setHours(newDatePR.getHours() + 2);
        stavkaZ.datum_PR = newDatePR.toISOString().substr(0, 10);
      }
      if (dateZR === null) {
        stavkaZ.datum_ZR = dateZR;
      } else {
        let newDateZR = new Date(dateZR);
        newDateZR.setHours(newDateZR.getHours() + 2);
        stavkaZ.datum_ZR = newDateZR.toISOString().substr(0, 10);
      }
      if (datePLP === null) {
        stavkaZ.datum_PLP = datePLP;
      } else {
        let newDatePLP = new Date(datePLP);
        newDatePLP.setHours(newDatePLP.getHours() + 2);
        stavkaZ.datum_PLP = newDatePLP.toISOString().substr(0, 10);
      }
      if (dateVVD === null) {
        stavkaZ.datum_VVD = dateVVD;
      } else {
        let newDateVVD = new Date(dateVVD);
        newDateVVD.setHours(newDateVVD.getHours() + 2);
        stavkaZ.datum_VVD = newDateVVD.toISOString().substr(0, 10);
      }
      if (dateVTK === null) {
        stavkaZ.datum_VTK = dateVTK;
      } else {
        let newDateVTK = new Date(dateVTK);
        newDateVTK.setHours(newDateVTK.getHours() + 2);
        stavkaZ.datum_VTK = newDateVTK.toISOString().substr(0, 10);
      }
      stavkaZ.opis = opis;

      setStavkaZ((item) => [...item, stavkaZ]);
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste uneli sve podatke za zaposlenog!",
        life: 3000,
      });
    }
  };

  const handleInputEmployeeDBHandler = () => {
    if(stavkaZ.length > 0){
    for (let i = 0; i < stavkaZ.length; i++) {
      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          firma_id: company.companyId,
          ime: stavkaZ[i].ime,
          prezime: stavkaZ[i].prezime,
          JMBG: stavkaZ[i].jmbg,
          radno_mesto_id: stavkaZ[i].radno_mesto,
          datum_PR: stavkaZ[i].datum_PR,
          datum_ZR: stavkaZ[i].datum_ZR,
          datum_PLP: stavkaZ[i].datum_PLP,
          datum_VVD: stavkaZ[i].datum_VVD,
          datum_VTK: stavkaZ[i].datum_VTK,
          opis_komentar: stavkaZ[i].opis,
        }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/employees/InsertEmployee", requestOptions)
        .then(handleErrors)
        .then((data) => {
          toast.current.show({
            severity: "success",
            summary: "Unos uspešan!",
            detail: data.message,
            life: 3000,
          });
          setStavkaZ([]);
          deleteContentEmployee();
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Greška!",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    }} else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: 'Nemate unetu ni jednu stavku. Nakon dodavanja stavke pokušajte unos ponovo.',
        life: 3000,
      });
    }
  };
  const handleUpdateZ = () => {
    let ime = document.getElementsByClassName("ime")[0].value;
    let prezime = document.getElementsByClassName("prezime")[0].value;
    let jmbg = document.getElementsByClassName("p-autocomplete-input")[0].value;
    let opis = document.getElementsByClassName("opisz")[0].value;

    let datumPR;
    if (datePR === null) {
      datumPR = null;
    } else {
      let newDatePR = new Date(datePR);
      newDatePR.setHours(newDatePR.getHours() + 2);
      datumPR = newDatePR.toISOString().substr(0, 10);
    }
    let datumZR;
    if (dateZR === null) {
      datumZR = null;
    } else {
      let newDateZR = new Date(dateZR);
      newDateZR.setHours(newDateZR.getHours() + 2);
      datumZR = newDateZR.toISOString().substr(0, 10);
    }
    let datumPLP;
    if (datePLP === null) {
      datumPLP = null;
    } else {
      let newDatePLP = new Date(datePLP);
      newDatePLP.setHours(newDatePLP.getHours() + 2);
      datumPLP = newDatePLP.toISOString().substr(0, 10);
    }
    let datumVVD;
    if (dateVVD === null) {
      datumVVD = null;
    } else {
      let newDateVVD = new Date(dateVVD);
      newDateVVD.setHours(newDateVVD.getHours() + 2);
      datumVVD = newDateVVD.toISOString().substr(0, 10);
    }
    let datumVTK;
    if (dateVTK === null) {
      datumVTK = null;
    } else {
      let newDateVTK = new Date(dateVTK);
      newDateVTK.setHours(newDateVTK.getHours() + 2);
      datumVTK = newDateVTK.toISOString().substr(0, 10);
    }

    if (empToUpdate !== null) {
      let requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          id: empToUpdate.id,
          ime: ime,
          prezime: prezime,
          JMBG: jmbg,
          radno_mesto_id: dropWorkRole,
          datum_PR: datumPR,
          datum_ZR: datumZR,
          datum_PLP: datumPLP,
          datum_VVD: datumVVD,
          datum_VTK: datumVTK,
          opis_komentar: opis,
        }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/employees", requestOptions)
        .then(handleErrors)
        .then((data) =>
          toast.current.show({
            severity: "success",
            summary: "Ažuriranje uspešno!",
            detail: data.message,
            life: 3000,
          }),
          deleteContentEmployee()
        )
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Greška!",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste izabrali zaposlenog kojeg želite da ažurirate!",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    fetch(process.env.REACT_APP_BACKEND_URL + "/employees", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setEmployeeDB(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Greška!",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
    fetch(process.env.REACT_APP_BACKEND_URL + "/employees/radno_mesto")
      .then(handleErrors)
      .then((result) => {
        let radnoM = [];
        for (let tip of result) {
          let _type = {
            label: tip.radno_mesto,
            value: tip.id,
          };
          radnoM.push(_type);
        }
        setWorkR(radnoM);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Greška!",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
  }, [empToUpdate, stavkaZ, render]);

  const searchEmployee = (event) => {
    let query = event.query;

    let _filteredEmployee = [];
    let filteredItems = employeeDB.filter(
      (item) => item.JMBG.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (filteredItems && filteredItems.length) {
      _filteredEmployee.push(...filteredItems);
    }

    setfilteredEmployee(_filteredEmployee);
  };
  const stavkaZAPToUpdateFUNC = (e) => {
    setEmpToUpdate(e.value);
    setName(e.value.Ime);
    setLastName(e.value.Prezime);
    setDropWorkRole(e.value.Radno_mesto_id);

    if (e.value.Datum_PR === null) {
      setDatePR(null);
    } else {
      let datumPR = new Date(e.value.Datum_PR);
      setDatePR(datumPR);
    }
    if (e.value.Datum_ZR === null) {
      setDateZR(null);
    } else {
      let datumZR = new Date(e.value.Datum_ZR);
      setDateZR(datumZR);
    }
    if (e.value.Datum_PLP === null) {
      setDatePLP(null);
    } else {
      let datumPLP = new Date(e.value.Datum_PLP);
      setDatePLP(datumPLP);
    }
    if (e.value.Datum_VVD === null) {
      setDateVVD(null);
    } else {
      let datumVVD = new Date(e.value.Datum_VVD);
      setDateVVD(datumVVD);
    }
    if (e.value.Datum_VTK === null) {
      setDateVTK(null);
    } else {
      let datumVTK = new Date(e.value.Datum_VTK);
      setDateVTK(datumVTK);
    }
    setValueTAZ(e.value.opis_komentar);
    setStavkaEMPToUpdate(e.value);
  };
  const handleDeleteZ = () => {
    confirmDialog({
      message: "Jel ste sigurni da želite obrisati zaposlenog?",
      acceptLabel: "Da",
      rejectLabel: "Ne",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  const accept = () => {
    if (empToUpdate !== null) {
      let requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({ id: empToUpdate.id }),
      };

      fetch(process.env.REACT_APP_BACKEND_URL + "/employees", requestOptions)
        .then(handleErrors)
        .then((result) => {
          toast.current.show({
            severity: "success",
            summary: "Brisanje uspešno!",
            detail: result.message,
            life: 3000,
          });
        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Greška!",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste izabrali zaposlenog kojeg želite da obrišete!",
        life: 3000,
      });
    }
    deleteContentEmployee();
  };
  const reject = () => {
    console.log("REJECTED DELETION!");
  };

  const deleteContentEmployee = () => {
    setStavkaEMPToUpdate([]);
    setEmpToUpdate(null);
    setName("");
    setLastName("");
    setDropWorkRole(null);
    setDatePR(null);
    setDateZR(null);
    setDatePLP(null);
    setDateVVD(null);
    setDateVTK(null);
    setValueTAZ("");
    if (render === true) {
      setRender(false);
    } else {
      setRender(true);
    }
  }
  const handleClose = () => {
    deleteContentEmployee();
    setStavkaZ([]);
    props.close();
  }

  const content = (
    <CSSTransition
      in={props.show}
      timeout={300}
      classNames="slide-in-top"
      mountOnEnter
      unmountOnExit
      nodeRef={nodeRef}
    >
      <div id="unos_d_popup" ref={nodeRef}>
        <section>
          <div className="header_popup">
            <h2>UNOS ZAPOSLENIH</h2>
            <Button
              onClick={handleClose}
              icon="pi pi-times"
              className="p-button-rounded p-button-danger"
            />
          </div>
          <Toast ref={toast} />
          <div className="main_popup_z">
            <form onSubmit={(event) => event.preventDefault()}>
              <AutoComplete
                style={{ width: "30.65%" }}
                type="number"
                className="jmbg"
                value={stavkaEMPToUpdate}
                placeholder="JMBG"
                required
                suggestions={filteredEmployee}
                completeMethod={searchEmployee}
                field="JMBG"
                onSelect={stavkaZAPToUpdateFUNC}
                onChange={(e) => setStavkaEMPToUpdate(e.value)}
              />
              <InputText
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                className="treciRedz ime"
                style={{ margin: "0 0 2% 2%", float: "left" }}
                placeholder="Ime"
              />
              <InputText
                value={lastname}
                required
                onChange={(e) => setLastName(e.target.value)}
                className="treciRedz prezime"
                style={{ margin: "0 0 2% 2%", float: "left" }}
                placeholder="Prezime"
              />
              <Dropdown
                value={dropWorkRole}
                options={workR}
                required
                onChange={(e) => setDropWorkRole(e.value)}
                placeholder="Izaberi radno mesto"
                className="treciRedz radno_mesto"
              />
              <Calendar
                value={datePR}
                inputStyle={{ float: "left" }}
                onChange={(e) => setDatePR(e.value)}
                required
                showIcon
                dateFormat="dd.mm.yy"
                className="treciRedz datumpr"
                placeholder="Datum početka rada"
                tooltip="Datum početka rada" 
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
              ></Calendar>
              <Calendar
                value={dateZR}
                inputStyle={{ float: "left" }}
                onChange={(e) => setDateZR(e.value)}
                showIcon
                dateFormat="dd.mm.yy"
                className="treciRedz datumzr"
                placeholder="Datum završetka rada"
                tooltip="Datum završetka rada" 
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
              ></Calendar>
              <Calendar
                value={datePLP}
                inputStyle={{ float: "left" }}
                onChange={(e) => setDatePLP(e.value)}
                showIcon
                dateFormat="dd.mm.yy"
                className="treciRedz datumplp"
                placeholder="Datum poslednjeg lekarskog pregleda"
                tooltip="Datum poslednjeg lekarskog pregleda" 
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
              ></Calendar>
              <Calendar
                value={dateVVD}
                inputStyle={{ float: "left" }}
                onChange={(e) => setDateVVD(e.value)}
                showIcon
                dateFormat="dd.mm.yy"
                className="treciRedz datumvvd"
                placeholder="Datum važenja vozačke dozvole"
                tooltip="Datum važenja vozačke dozvole" 
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
              ></Calendar>
              <Calendar
                value={dateVTK}
                inputStyle={{ float: "left" }}
                onChange={(e) => setDateVTK(e.value)}
                dateFormat="dd.mm.yy"
                showIcon
                className="treciRedz datumvtk"
                placeholder="Datum važenja TAHO KARTICE"
                tooltip="Datum važenja TAHO KARTICE" 
                tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
              ></Calendar>
              <InputTextarea
                style={{ marginTop: "0" }}
                rows={2}
                cols={30}
                value={valueTAZ}
                className="opisz"
                onChange={(e) => setValueTAZ(e.value)}
                placeholder="Opis / komentar"
              />
              <div className="buttonsUD">
                <button className="btn btn-secondary" onClick={handleInputZ}>
                  +STAVKA
                </button>
                <button className="btn btn-warning" onClick={handleUpdateZ}>
                  AŽURIRAJ
                </button>
                <button className="btn btn-danger" onClick={handleDeleteZ}>
                  OBRIŠI
                </button>
              </div>
            </form>
          </div>
          <div className="second_popup_z">
            <div className="zaposleni_stavka">
              {stavkaZ.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Ime</th>
                      <th>Prezime</th>
                      <th>JMBG</th>
                      <th>Radno mesto</th>
                      <th>Datum početka rada</th>
                      <th>-</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stavkaZ.map((item, i) => (
                      <Zaposleni
                        key={i}
                        zaposleni={item}
                        index={i}
                        delete={handleDeleteStavkaZ}
                        workRole={workR}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                "Niste uneli ni jednu stavku!"
              )}
            </div>
            <div className="form_z">
              <button
                className="btn btn-success"
                onClick={handleInputEmployeeDBHandler}
              >
                UNOS
              </button>
            </div>
          </div>
        </section>
      </div>
    </CSSTransition>
  );
  return ReactDOM.createPortal(content, document.getElementById("uz-hook"));
};

export default UnosZaposlenihPop;
