import React, { useState, useRef, useEffect, useContext } from "react";
import "./pumpe.css";
import ViewSipanja from "./view_sipanja";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { AuthContext } from "../../../../Header/auth - context";
import { confirmDialog } from "primereact/confirmdialog";
import { useSelector } from "react-redux";

const SipanjaVozila = (props) => {

  const toast = useRef(null);
  const auth = useContext(AuthContext);
  const company = useSelector((state) => state.company);
  const [pumpaDropPour, setPumpaDropPour] = useState("");
  const [pumpaDropPourOld, setPumpaDropPourOld] = useState("");
  const [pumpaDBPour, setPumpaDBPour] = useState([]);
  const [stavkaGarG, setStavkaGarG] = useState(null);
  const [datePour, setDatePour] = useState(null);
  const [valueKolPour, setValueKolPour] = useState(null);
  const [valueKolPourOld, setValueKolPourOld] = useState(null);
  const [milage, setMilage] = useState(null);
  const [komentarPour, setKomentarPour] = useState("");
  const [filteredGar, setFilteredGar] = useState([]);
  const [vehicleDB, setVehicleDB] = useState([]);
  const [stavkaP, setStavkaP] = useState([]);
  const [stavkaPNew, setStavkaPNew] = useState([]);
  const [pourToUpdate, setPourToUpdate] = useState([]);
  const [pourToUpdateID, setPourToUpdateID] = useState(null);

  const handleErrors = (response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  };

  useEffect(() => {

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      body: JSON.stringify({ 
        firma_id: company.companyId
       }),
    };
    
    fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", requestOptions)
      .then(handleErrors)
      .then((result) => {
        setVehicleDB(result);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });
    fetch(process.env.REACT_APP_BACKEND_URL + "/fuel/", requestOptions)
      .then(handleErrors)
      .then((result) => {
        let pump = [];
        for (let pumpItem of result) {
          let _pump = {
            label: pumpItem.pumpa,
            value: pumpItem.id,
          };
          pump.push(_pump);
        }
        setPumpaDBPour(pump);
      })
      .catch((error) => {
        error.json().then((errorMessage) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMessage.message,
            life: 3000,
          });
          console.log(errorMessage);
        });
      });

  }, [pourToUpdate, stavkaP, props.render]);

  const handleDeleteStavkaP = (index) => {

    if (stavkaP.length > stavkaPNew.length) {
      for (let i = 0; i < stavkaPNew.length; i++) {
        if (JSON.stringify(stavkaPNew[i]) !== JSON.stringify(stavkaP[index])) {

            const item2 = [];
            item2.push(stavkaPNew[i])
            setStavkaPNew(item2);
          
        }
      }
      const item = stavkaP.slice();
      item.splice(index, 1);
      setStavkaP(item);
    } else {

      if (index > -1) {
        const item = stavkaP.slice();
        item.splice(index, 1);
        setStavkaP(item);
        setStavkaPNew(item)
      }
    }

  };


  const searchGar = (event) => {
    let query = event.query;
    let _filteredGar = [];
    let filteredItems = vehicleDB.filter(
      (item) =>
        item.garazni_broj.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    if (filteredItems && filteredItems.length) {
      _filteredGar.push(...filteredItems);
    }

    setFilteredGar(_filteredGar);
  };

  const handleInputG = (e) => {
    setPourToUpdateID(null)
    e.preventDefault();
    const stavkaG = {
      id: "",
      garazniID: "",
      datumSipanja: "",
      pumpaID: "",
      kolicina: "",
      komentar: "",
    };

    if (
      stavkaGarG !== null &&
      datePour !== null &&
      pumpaDropPour !== "" &&
      valueKolPour !== null &&
      milage !== null
    ) {
      if (typeof stavkaGarG === "object") {
        stavkaG.garazniID = stavkaGarG.id;
      } else {
        let GR = vehicleDB.find((e) => e.garazni_broj === stavkaGarG);
        stavkaG.garazniID = GR.id;
      }
      if (datePour === null) {
        stavkaG.datumSipanja = datePour;
      } else {
        let newDateS = new Date(datePour);
        newDateS.setHours(newDateS.getHours() + 2);
        stavkaG.datumSipanja = newDateS.toISOString().substr(0, 10);
      }
      stavkaG.pumpaID = pumpaDropPour;
      stavkaG.kolicina = valueKolPour;
      stavkaG.kilometraza = milage;
      stavkaG.komentar = komentarPour;
      setStavkaPNew((item) => [...item, stavkaG])
      setStavkaP((item) => [...item, stavkaG]);
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Upozorenje!",
        detail: "Niste uneli sve podatke za snabdevanje vozila!",
        life: 3000,
      });
    }

  }
  const handleUpdateG = (e) => {
    e.preventDefault();
    const updateRecord = {
      id: "",
      garazniID: "",
      datumSipanja: "",
      pumpa: "",
      kolicina: "",
      kilometraza: "",
      komentar: ""
    };
    if (pourToUpdateID !== null && pourToUpdate.id !== '') {
      if (
        pourToUpdateID !== null &&
        stavkaGarG !== null &&
        datePour !== null &&
        pumpaDropPour !== "" &&
        valueKolPour !== null &&
        milage !== null
      ) {
        updateRecord.id = pourToUpdateID;
        if (typeof stavkaGarG === "object") {
          updateRecord.garazniID = pourToUpdate.garazniID;
        } else {
          let GR = vehicleDB.find((e) => e.garazni_broj === stavkaGarG);
          updateRecord.garazniID = GR.id;
        }
        if (datePour === null) {
          updateRecord.datumSipanja = datePour; 
        } else {
          let newDatePour = new Date(datePour);
          newDatePour.setHours(newDatePour.getHours() + 2);
          updateRecord.datumSipanja = newDatePour.toISOString().substr(0, 10);
        }
        updateRecord.pumpa = pumpaDropPour;
        updateRecord.kolicina = valueKolPour;
        updateRecord.kilometraza = milage;
        updateRecord.komentar = komentarPour;

        let requestOptions = {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
          body: JSON.stringify({
            id: updateRecord.id,
            vozilo_id: updateRecord.garazniID,
            datum_sipanja: updateRecord.datumSipanja,
            pumpa_id: updateRecord.pumpa,
            kolicina: updateRecord.kolicina,
            pumpa_id_old: pumpaDropPourOld,
            kolicina_old: valueKolPourOld,
            kilometraza: updateRecord.kilometraza,
            komentar: updateRecord.komentar,
          }),
        };
        if(pourToUpdate.id !== ''){
          fetch(
            process.env.REACT_APP_BACKEND_URL + "/fuel",
            requestOptions
          )
            .then(handleErrors)
            .then((data) =>
              toast.current.show({
                severity: "success",
                summary: "Uspešno!",
                detail: data.message,
                life: 3000,
              }),
              deleteContentPour()
            )
            .catch((error) => {
              error.json().then((errorMessage) => {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: errorMessage.message,
                  life: 3000,
                });
                console.log(errorMessage);
              });
            });
        } else {
          toast.current.show({
            severity: "warn",
            summary: "Upozorenje!",
            detail: "Pokušali ste da ažurirate stavku koju ste tek kreirali. Pokušajte ponovo!",
            life: 3000,
          });
          return;
        }
       
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Upozorenje!",
          detail: "Niste uneli sve podatke za sipanje!",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "info",
        summary: "Obaveštenje",
        detail: "Niste izabrali stavku koju želite da ažurirate!",
        life: 3000,
      });
    }
  }
  const handleDeleteG = (e) => {
    e.preventDefault();
    confirmDialog({
      message: "Jel ste sigurni da želite obrisati record potrošnje goriva?",
      acceptLabel: "Da",
      rejectLabel: "Ne",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  }

  const accept = () => {
    if (pourToUpdateID !== null) {
      let requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },
        body: JSON.stringify({
          id: pourToUpdate.id,
          pumpa_id: pourToUpdate.pumpaID,
          kolicina: pourToUpdate.kolicina,
        }),
      };
      fetch(
        process.env.REACT_APP_BACKEND_URL + "/fuel",
        requestOptions
      )
        .then(handleErrors)
        .then((data) =>
          toast.current.show({
            severity: "success",
            summary: "Uspešno!",
            detail: data.message,
            life: 3000,
          }),
          deleteContentPour(),
          setPourToUpdate([])
        )
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    } else {
      toast.current.show({
        severity: "info",
        summary: "Obaveštenje",
        detail: "Niste izabrali stavku koju želite da obrišete!",
        life: 3000,
      });
    }
  }
  const reject = () => {
    console.log("REJECTED RECORD DELETION!");
  };

  const handleEditStavkaP = (index) => {

    setPourToUpdate(index)
    setPourToUpdateID(index.id)
    setValueKolPourOld(index.kolicina)

    let GR = vehicleDB.find((e) => e.id === index.garazniID);
    setStavkaGarG(GR.garazni_broj);
    if (index.datum_sipanja === null) {
      setDatePour(index.datumSipanja);
    } else {
      let newDatePour = new Date(index.datumSipanja);
      setDatePour(newDatePour);
    }
    let PMP = pumpaDBPour.find((e) => e.value === index.pumpaID);
    setPumpaDropPour(PMP.value);
    setPumpaDropPourOld(PMP.value);
    setValueKolPour(index.kolicina);
    setMilage(index.kilometraza);
    setKomentarPour(index.komentar);

  };
  const deleteContentPour = () => {
    setPumpaDropPour("");
    setStavkaGarG(null);
    setDatePour(null);
    setValueKolPour(null);
    setMilage(null);
    setKomentarPour("");
    setPourToUpdate([]);
    setPourToUpdateID(null);
    setValueKolPourOld(null);
    setPumpaDropPourOld(null);
    setStavkaP([])
    setStavkaPNew([])
  }

  const handleInputFuelPouringDBHandler = () => {
    if(stavkaP.length > 0 || stavkaPNew.length > 0) {
    if (stavkaP.length > stavkaPNew.length) {
      for (let i = 0; i < stavkaPNew.length; i++) {
        let requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },

          body: JSON.stringify({
            garazni_id: stavkaPNew[i].garazniID,
            datum_POUR: stavkaPNew[i].datumSipanja,
            pumpa: stavkaPNew[i].pumpaID,
            kolicina: stavkaPNew[i].kolicina,
            kilometraza: stavkaPNew[i].kilometraza,
            komentar: stavkaPNew[i].komentar,
          }),
        };
        fetch(process.env.REACT_APP_BACKEND_URL + "/fuel/vehicle_pours", requestOptions)
          .then(handleErrors)
          .then((data) => {
            toast.current.show({
              severity: "success",
              summary: "Unos sipanja uspešan!",
              detail: data.message,
              life: 3000,
            });
            setStavkaP([]);
            setStavkaPNew([]);
            deleteContentPour();
          })
          .catch((error) => {
            error.json().then((errorMessage) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMessage.message,
                life: 3000,
              });
              console.log(errorMessage);
            });
          });
      }
    } else {
      for (let i = 0; i < stavkaP.length; i++) {
        let requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          },
    
          body: JSON.stringify({
            garazni_id: stavkaP[i].garazniID,
            datum_POUR: stavkaP[i].datumSipanja,
            pumpa: stavkaP[i].pumpaID,
            kolicina: stavkaP[i].kolicina,
            kilometraza: stavkaP[i].kilometraza,
            komentar: stavkaP[i].komentar,
          }),
        };
        fetch(process.env.REACT_APP_BACKEND_URL + "/fuel/vehicle_pours", requestOptions)
          .then(handleErrors)
          .then((data) => {
            toast.current.show({
              severity: "success",
              summary: "Unos sipanja uspešan!",
              detail: data.message,
              life: 3000,
            });
            setStavkaP([]);
            deleteContentPour();
          })
          .catch((error) => {
            error.json().then((errorMessage) => {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMessage.message,
                life: 3000,
              });
              console.log(errorMessage);
            });
          });
      }
    }
  } else {
    toast.current.show({
      severity: "warn",
      summary: "Upozorenje!",
      detail: 'Nemate unetu ni jednu stavku. Nakon dodavanja stavke pokušajte unos ponovo.',
      life: 3000,
    });
  }
  }
  const deleteViewContent = () => {
    setPumpaDropPour("");
    setDatePour(null);
    setValueKolPour(null);
    setMilage(null);
    setKomentarPour("");
    setPourToUpdate([]);
    setPourToUpdateID(null);
    setValueKolPourOld(null);
    setPumpaDropPourOld(null);
    setStavkaP([])
    setStavkaPNew([])
  }
  const getRecordforUpdate = (e) => {
    
    let newDateS = new Date(e.value);
    newDateS.setHours(newDateS.getHours() + 2);
    let date = newDateS.toISOString().substr(0, 10);
    let datePreview = new Date(date)
    let newPreview = datePreview.toLocaleDateString('sr-RS');
    if (stavkaGarG !== null) {

      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        },

        body: JSON.stringify({
          garazni_id: stavkaGarG.id,
          date: date,
        }),
      };
      fetch(process.env.REACT_APP_BACKEND_URL + "/fuel/get_pour_record", requestOptions)
        .then(handleErrors)
        .then((data) => {
          if (data.length !== 0) {
            setPourToUpdate(data);
            for (let i = 0; i < data.length; i++) {
              const stavkaG = {
                id: "",
                garazniID: "",
                datumSipanja: "",
                pumpaID: "",
                kolicina: "",
                kilometraza: "",
                komentar: "",
              }
              stavkaG.id = data[i].id;
              stavkaG.garazniID = data[i].vozilo_id;
              let newDateS = new Date(data[i].datum_sipanja);
              newDateS.setHours(newDateS.getHours() + 2);
              stavkaG.datumSipanja = newDateS.toISOString().substr(0, 10);
              stavkaG.pumpaID = data[i].pumpa_id;
              stavkaG.kolicina = data[i].kolicina;
              stavkaG.kilometraza = data[i].kilometraza;
              stavkaG.komentar = data[i].komentar;

              setStavkaP((item) => [...item, stavkaG]);
            }
          } else {
            toast.current.show({
              severity: "info",
              summary: "Info!",
              detail: `Nije pronađen ni jedan record za vozilo ${stavkaGarG.garazni_broj} i datum=${newPreview}!`,
              life: 3000,
            });
          }

        })
        .catch((error) => {
          error.json().then((errorMessage) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: errorMessage.message,
              life: 3000,
            });
            console.log(errorMessage);
          });
        });
    }

  }

  return (
    (!props.status &&
    <div id="pumpe_forma">
    <div className="header_popup">
      <h3>Sipanja goriva po vozilu</h3>
    </div>
    <Toast ref={toast} />
    <div className="main_popup_g">
      <form>
        <AutoComplete
          style={{
            width: "30.65%", display: "inherit",
            marginRight: "0",
            marginTop: "0",
            marginLeft: "2%"
          }}
          className="treciRedv"
          value={stavkaGarG}
          placeholder="Garažni broj"
          required
          suggestions={filteredGar}
          completeMethod={searchGar}
          field="garazni_broj"
          onChange={(e) => setStavkaGarG(e.value)}
        />
        <Calendar
          value={datePour}
          inputStyle={{ float: "left" }}
          onChange={(e) => setDatePour(e.value)}
          onSelect={getRecordforUpdate}
          onShow={deleteViewContent}
          required
          showIcon
          dateFormat="dd.mm.yy"
          className="treciRedv"
          placeholder="Datum sipanja"
          tooltip="Datum sipanja"
          tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}
        ></Calendar>
        <Dropdown
          value={pumpaDropPour}
          options={pumpaDBPour}
          required
          onChange={(e) => setPumpaDropPour(e.value)}
          placeholder="Pumpa"
          className="treciRedv"
        />
        <InputNumber
          value={valueKolPour}
          onValueChange={(e) => setValueKolPour(e.value)}
          className="treciRedv"
          required
          id="kolicina"
          placeholder="Količina"
        />
        <InputNumber
          value={milage}
          onValueChange={(e) => setMilage(e.value)}
          className="treciRedv"
          required
          id="kilometraza_sipanja"
          placeholder="Kilometraža"
        />
        <InputText
          value={komentarPour}
          onChange={(e) => setKomentarPour(e.target.value)}
          className="treciRedv komentar"
          placeholder="Opis / komentar"
        />
        <div className="buttonsUG">
          <button className="btn btn-secondary" onClick={handleInputG}>
            +STAVKA
          </button>
          <button className="btn btn-warning" onClick={handleUpdateG}>
            AŽURIRAJ
          </button>
          <button className="btn btn-danger" onClick={handleDeleteG}>
            OBRIŠI
          </button>
        </div>
      </form>
    </div>
    <div className="second_popup_g">
      <div className="sipanja_stavka">
        {stavkaP.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Garažni</th>
                <th>Datum sipanja</th>
                <th>Pumpa</th>
                <th>Količina[ l ]</th>
                <th>Kilometraža[ km ]</th>
                <th>Opis / Komentar</th>
                <th>-</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              {stavkaP.map((item, i) => (
                <ViewSipanja
                  key={i}
                  sipanja={item}
                  index={i}
                  edit={handleEditStavkaP}
                  delete={handleDeleteStavkaP}
                  pumpaDB={pumpaDBPour}
                  vehicleDB={vehicleDB}
                />
              ))}
            </tbody>
          </table>
        ) : (
          "Niste uneli ni jednu stavku!"
        )}
      </div>
      <div id="form_g">
        <button
          className="btn btn-success"
          onClick={handleInputFuelPouringDBHandler}
        >
          UNOS
        </button>
      </div>
    </div>
  </div>)
  );
};
export default SipanjaVozila;
