
export function processTwoWeekDriving(records, company, driversDB) {

    let count = 0;
    const arrayTwoWeekDriving = [];
    
    let currentMonday = 0;
    let sumDriving = 0;
 
    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatSRBTime(dateString) {
        const parts = dateString.split('-');
        const date = new Date(`${parts[1]}/${parts[2]}/${parts[0]}`);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        return formattedDate;
    }
    for(let n=0; n<driversDB.length;n++){
      let driver = driversDB[n];
      let filtered = records.filter(item => item.DriverId === driver.Id) 
    const weekDriving = [];
    for(let i = 0; i< filtered.length; i++){
      let record = filtered[i];
      let previousRecord = filtered[i-1];
      let nextRecord = filtered[i+1];
      const dateString = filtered[i].ActivityDate;
      const date = new Date(dateString);
      const dayOfWeek = date.getDay();
      const day = filtered[i].ActivityDate.split("T")[0];
      if(dayOfWeek === 1){
        if(currentMonday === 0) {
          currentMonday = day;
          sumDriving = 0;
        } else {
          if(currentMonday != day) {
            if(previousRecord){
              weekDriving.push({
                driver: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                company: company.Name,  // Assuming Company is available in the record
                day1: currentMonday,
                day7: day,
                sumDriving: sumDriving,
                Message: `Ukupna vožnja za nedelju dana do ${day}!`
              });
              currentMonday = day;
              sumDriving = 0
            }
          }
          }
        }
        
      if (record.Value.toLowerCase() === 'driving') {
        sumDriving += parseInt(record.TotalTime)
        if(nextRecord) { 
          continue
        } else {
          weekDriving.push({
            driver: findDriver(record.DriverId),  // Assuming DriverId is available in the records
            company: company.Name,  // Assuming Company is available in the record
            day1: currentMonday,
            day7: record.EndTime.split("T")[0],
            sumDriving: sumDriving,
            Message: `Ukupna vožnja za nedelju dana do ${record.EndTime}!`
          });
          currentMonday = day;
          sumDriving = 0
        }
      } else {
        if(nextRecord) { 
          continue
        } else {
          weekDriving.push({
            driver: findDriver(record.DriverId),  // Assuming DriverId is available in the records
            company: company.Name,  // Assuming Company is available in the record
            day1: currentMonday,
            day7: record.EndTime.split("T")[0],
            sumDriving: sumDriving,
            Message: `Ukupna vožnja za nedelju dana do ${record.EndTime}!`
          });
          currentMonday = day;
          sumDriving = 0
        }
      }
      }

 
    for(let i = 0; i<weekDriving.length;i++){
      let record = weekDriving[i]
      let previousRecord = weekDriving[i-1]
      

      if(record.sumDriving > 56 * 60 * 60 * 1000){
        const hours = String(Math.floor(record.sumDriving / (60 * 60 * 1000))).padStart(2, '0');
        const minutes = String(Math.floor((record.sumDriving % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
        count++
        arrayTwoWeekDriving.push({
          Prekršaj: count,
          Vozač: record.driver,  // Assuming DriverId is available in the records
          Kompanija: company.Name,  // Assuming Company is available in the record
          Poruka: `Prekoračeno nedeljno radno vreme počev od ${formatSRBTime(record.day1)} do ${formatSRBTime(record.day7)}, ukupno ${hours}:${minutes}!`
        });
      }
      if(previousRecord) {
        if(record.sumDriving + previousRecord.sumDriving > 90 * 60 * 60 * 1000) {
          const sum2weekDriving = record.sumDriving + previousRecord.sumDriving
          const hours2week = String(Math.floor(sum2weekDriving / (60 * 60 * 1000))).padStart(2, '0');
          const minutes2eek = String(Math.floor((sum2weekDriving % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');

          count++
          arrayTwoWeekDriving.push({
            Prekršaj: count,
            Vozač: record.driver,  // Assuming DriverId is available in the records
            Kompanija: company.Name,  // Assuming Company is available in the record
            Poruka: `Prekoračeno dvonedeljno radno vreme počev od ${formatSRBTime(previousRecord.day1)} do dana ${formatSRBTime(record.day7)}, ukupno ${hours2week}:${minutes2eek}!`
          });
        }
      }
    }
  }

    return arrayTwoWeekDriving
  }