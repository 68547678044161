import React, { useState, useEffect } from "react";

const Gorivo_stanje = (props) => {
    const [pumpaP1, setPumpaP1] = useState(0);
    const [pumpaP2, setPumpaP2] = useState(0);
    const [pumpaOMV, setPumpaOMV] = useState(0);
    const [pumpaART, setPumpaART] = useState(0);
    const [pumpaOstalo, setPumpaOstalo] = useState(0);
    const [pumpaP1kum, setPumpaP1kum] = useState(0);
    const [pumpaP2kum, setPumpaP2kum] = useState(0);
    const [pumpaOMVkum, setPumpaOMVkum] = useState(0);
    const [pumpaARTkum, setPumpaARTkum] = useState(0);
    const [pumpaOstalokum, setPumpaOstalokum] = useState(0);
    const [dopuna, setDopuna] = useState(0);
    const [totalG, setTotalG] = useState(0);

    useEffect(()=>{
        const date = new Date(props.data)

        for(let i=0; i< props.stanje.length; i++){
            const dS = new Date(props.stanje[i].Datum_sipanja)
            if(dS.getTime() === date.getTime()){
             setDopuna(props.stanje[i].kolicina);

        }
        }
        let sumKumulativ = 0; 
        for(let i=0; i< props.interData.length; i++){
            const dS = new Date(props.interData[i].datum_sipanja)
            if(dS.getTime() <= date.getTime()){
                sumKumulativ += props.interData[i].kolicina;

        }
        setTotalG(sumKumulativ);
        }

        let sum = 0;
        let sum2 = 0;
        let sum3 = 0;
        let sum4 = 0;
        let sum5 = 0;
        for(let i=0; i< props.interData.length; i++){
            let date = new Date (props.interData[i].datum_sipanja)
            let d = new Date (props.data)
            if(date.getTime() === d.getTime()){
                if(props.interData[i].pumpa_id === 1) {
                    sum += props.interData[i].kolicina
                }
                if(props.interData[i].pumpa_id === 2) {
                    sum2 += props.interData[i].kolicina
                }
                if(props.interData[i].pumpa_id === 3) {
                    sum3 += props.interData[i].kolicina
                }
                if(props.interData[i].pumpa_id === 4) {
                    sum4 += props.interData[i].kolicina
                }
                if(props.interData[i].pumpa_id === 5) {
                    sum5 += props.interData[i].kolicina
                }
            }
            setPumpaP1(sum)
            setPumpaP2(sum2)
            setPumpaOMV(sum3)
            setPumpaART(sum4)
            setPumpaOstalo(sum5)
        }
        let sumKum = 0;
        let sum2Kum = 0;
        let sum3Kum = 0;
        let sum4Kum = 0;
        let sum5Kum = 0;
        for(let i=0; i< props.interData.length; i++){

                const d = new Date(props.interData[i].datum_sipanja) 
                if(d.getTime() <= date.getTime()){
                    if(props.interData[i].pumpa_id === 1) {
                        sumKum += props.interData[i].kolicina
                    }
                    if(props.interData[i].pumpa_id === 2) {
                        sum2Kum += props.interData[i].kolicina
                    }
                    if(props.interData[i].pumpa_id === 3) {
                        sum3Kum += props.interData[i].kolicina
                    }
                    if(props.interData[i].pumpa_id === 4) {
                        sum4Kum += props.interData[i].kolicina
                    }
                    if(props.interData[i].pumpa_id === 5) {
                        sum5Kum += props.interData[i].kolicina
                    }

            }
            setPumpaP1kum(sumKum)
            setPumpaP2kum(sum2Kum)
            setPumpaOMVkum(sum3Kum)
            setPumpaARTkum(sum4Kum)
            setPumpaOstalokum(sum5Kum)
        }

 
    },[props])


  return (
    <section className='stavka_vozilo'>
        <div style={{ width: '250px', height: '200px'}} id='celija_gorivo_dole'>
            <main>
                <span style={{ width: '50px' }}>{pumpaP2}</span>
                <span style={{ width: '50px' }}>{pumpaP1}</span>
                <span style={{ width: '50px' }}>{pumpaOMV}</span>
                <span style={{ width: '50px' }}>{pumpaART}</span>
                <span style={{ width: '50px' }}>{pumpaOstalo}</span>
            </main>
            <main>
                <span style={{ width: '50px' }}>{pumpaP2kum}</span>
                <span style={{ width: '50px' }}>{pumpaP1kum}</span>
                <span style={{ width: '50px' }}>{pumpaOMVkum}</span>
                <span style={{ width: '50px' }}>{pumpaARTkum}</span>
                <span style={{ width: '50px' }}>{pumpaOstalokum}</span>
            </main>
            <main>
                <span style={{ width: '50px' }}>{dopuna}</span>
                <span style={{ width: '50px' }}>P2</span>
                <span style={{ width: '150px' }}>{pumpaP2+pumpaP1+pumpaOMV+pumpaART+pumpaOstalo}</span>
            </main>
            <main>
                <span style={{ width: '250px' }}>{totalG}</span>
            </main>
        </div>
    </section>
  );
};

export default Gorivo_stanje;
