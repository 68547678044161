import { createStore } from 'redux';
import { loadState, saveState } from './localStorage';


const counterReducer = (state = {count: 0, user: {}, user_role: {} ,company:{}}, action) => {

    if(action.type === 'COUNTER'){
        return {count: action.value, user: state.user, user_role: state.user_role, company: state.company};
    }
    if(action.type === 'USER'){
        return {count: state.count, user: action.value, user_role: state.user_role, company: state.company};
    }
    if(action.type === 'USER_ROLE'){
        return {count: state.count, user: state.user, user_role: action.value, company: state.company};
    }
    if(action.type === 'COMPANY'){
        return {count: state.count, user: state.user, user_role: state.user_role, company: action.value};
    }

    return state;
}

const persistedState = loadState();

const store = createStore(counterReducer,persistedState);


store.subscribe(()=> {
    saveState(store.getState());
  })


export default store;