
export function processRecordsOut(records, records2, company, driversDB, isGradski) {
    let count = 0;
    const activitiesToConsider = ['break/rest', 'driving', 'work', 'availability', 'unknown'];
    const activitiesToConsider2 = [1, 2];
    const arrayFaultsOUT = [];
    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatSRBTime(dateString) {
        const parts = dateString.split('-');
        const date = new Date(`${parts[1]}/${parts[2]}/${parts[0]}`);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        return formattedDate;
    }

    if(isGradski){
      for(let n=0; n<driversDB.length;n++){
        let driver = driversDB[n];
        let filtered = records.filter(item => item.DriverId === driver.Id)
        let filtered2 = records2.filter(item => item.DriverId === driver.Id)
      const groupedByDay = {};
      const groupedByDayOut = {}; 
  
              for (let i = 0; i < filtered.length; i++) {
                const record = filtered[i];
              //   const nextRecord = records[i+1];
                const day = record.ActivityDate.split("T")[0];
                
                if (!Object.prototype.hasOwnProperty.call(groupedByDay, day)) {
                    groupedByDay[day] = { totalTime: 0, DriverId: 0};
                  }
  
                if (activitiesToConsider.includes(record.Value.toLowerCase())) {
                    if (record.Value.toLowerCase() === 'driving') {
                      
                        groupedByDay[day].totalTime += parseInt(record.TotalTime);
                        groupedByDay[day].DriverId = record.DriverId;
                    
  
                    } else if (record.Value.toLowerCase() === 'work'){
                        groupedByDay[day].totalTime += parseInt(record.TotalTime);
                        groupedByDay[day].DriverId = record.DriverId;
             
                    } else if (record.Value.toLowerCase() === 'break/rest'){
                      // if(parseInt(record.TotalTime) >= 7 * 60 * 60 * 1000) {
                      //     continue
                      // } else{
                      //     if(nextRecord){
                      //         if(nextRecord.Value.toLowerCase() === 'break/rest' || nextRecord.Value.toLowerCase() === 'unknown' ) {
                      //             if(parseInt(record.TotalTime) + parseInt(nextRecord.TotalTime) >= 7 * 60 * 60 * 1000) {
                      //                continue
                      //             } 
                      //         } else {
                      //            if(parseInt(record.TotalTime) < 60* 60 *1000) {
  
                      //            }
                      //         groupedByDay[day].DriverId = record.DriverId;
                             
                      //         }
                      //     } else {
                      //         continue
                      //     }
                      // }
                    
                      
  
  
                      // if(previousRecord){
                      //   if(previousRecord.Value.toLowerCase() === 'break/rest') {
                      //     continue;
                      //   } else {
                      //     if(nextRecord){
                      //       if(nextRecord.Value.toLowerCase() === 'break/rest') {
                      //         continue;
                      //       } else {
                      //         groupedByDay[day].totalTime += parseInt(record.TotalTime);
                      //         groupedByDay[day].DriverId = record.DriverId;
                      //       }
                      //     }
                      //     groupedByDay[day].totalTime += parseInt(record.TotalTime);
                      //     groupedByDay[day].DriverId = record.DriverId;
                      //   } 
                      
                      // } else {
                      //   continue;
                      // }
                    } 
  
                  }
              } 
              ////////////////
              for (let j = 0; j < filtered2.length; j++) {
                const record = filtered2[j];
                const nextRecord = filtered2[j+1];
                let dayNext
                if(nextRecord) {
                  dayNext = nextRecord.EntryDate.split("T")[0];
                }
                const previousRecord = filtered2[j-1];
                let dayPrev
                if(previousRecord) {
                  dayPrev = previousRecord.EntryDate.split("T")[0];
                }
                const day2 = record.EntryDate.split("T")[0];
                let totalTimeInMS = new Date(record.EntryDate).getTime();
                
                if (!Object.prototype.hasOwnProperty.call(groupedByDayOut, day2)) {
                  groupedByDayOut[day2] = { totalTime: 0, DriverId: 0};
                  
                  }
              
                if (activitiesToConsider2.includes(record.SpecificConditionType)) {
                  if (record.SpecificConditionType == 1) {
  
                    if(nextRecord){
                      if(day2 !== dayNext) {
                          let string = dayNext+'T00:00:00.000Z'
                          let totalTimeInMS = new Date(record.EntryDate).getTime();
                          let endMS = new Date(string).getTime();
                          
                          groupedByDayOut[day2].totalTime = endMS - totalTimeInMS
                          groupedByDayOut[day2].DriverId = record.DriverId;
                      } else {
                          groupedByDayOut[day2].totalTime -= totalTimeInMS;
                          groupedByDayOut[day2].DriverId = record.DriverId;
                      }
                    
                    }                     
  
          
                    } else if (record.SpecificConditionType == 2){
                      if(previousRecord){
                          if(day2 !== dayPrev) {
                              let string = day2+'T00:00:00.000Z'
                              let totalTimeInMS = new Date(record.EntryDate).getTime();
                              let endMS = new Date(string).getTime();
                              
                              groupedByDayOut[day2].totalTime = totalTimeInMS - endMS
                              groupedByDayOut[day2].DriverId = record.DriverId;
                          } else {
                              groupedByDayOut[day2].totalTime += totalTimeInMS;
                              groupedByDayOut[day2].DriverId = record.DriverId;
                          }
                      } else {
                          let string = day2+'T00:00:00.000Z'
                          let totalTimeInMS = new Date(record.EntryDate).getTime();
                          let endMS = new Date(string).getTime();
                          
                          groupedByDayOut[day2].totalTime = totalTimeInMS - endMS
                          groupedByDayOut[day2].DriverId = record.DriverId;
                      }
                    } 
                  }
              } 
  
            
  
            for (let day in groupedByDay) {
     
                        
                          const formattedHours = Math.round(groupedByDay[day].totalTime / (1000 * 60 * 60));
                          const formattedMinutes = Math.round((groupedByDay[day].totalTime % (1000 * 60 * 60)) / (1000 * 60));
  
                          const dayExistsInOut = Object.prototype.hasOwnProperty.call(groupedByDayOut, day);
                          
  
                          // If the day is not found in groupedByDayOut, log it
                          if (!dayExistsInOut) {
                            if(groupedByDay[day].totalTime > 0) {
                              count++
                              arrayFaultsOUT.push({
                                Prekršaj: count,
                                Vozač: findDriver(groupedByDay[day].DriverId),
                                Kompanija: company.Name,
                                Poruka: `Nema OUT na dan ${formatSRBTime(day)}!`
                                          });
                            }
                           
                          } else {
                            
                            const formattedHoursOUT = Math.round(groupedByDayOut[day].totalTime / (1000 * 60 * 60));
                            const formattedMinutesOUT = Math.round((groupedByDayOut[day].totalTime % (1000 * 60 * 60)) / (1000 * 60));
                             let timeDifference = groupedByDay[day].totalTime - groupedByDayOut[day].totalTime ;
                             
                             if(timeDifference > 15 * 60 * 1000){
                                count++
                                arrayFaultsOUT.push({
                                Prekršaj: count,
                                  Vozač: findDriver(groupedByDay[day].DriverId),
                                  Kompanija: company.Name,
                                  Poruka: `Nema dovoljno OUT dana ${formatSRBTime(day)}! Radni dan ${formattedHours}:${formattedMinutes < 10 ? '0' : ''}${formattedMinutes}, OUT aktivan ${formattedHoursOUT}:${formattedMinutesOUT < 10 ? '0' : ''}${formattedMinutesOUT}`
                                            });
                             }
                          }
                      
  
            }
          }
    }
  
return arrayFaultsOUT;
}