export function processRecordsNightDriving(records, company, driversDB, isGradski) {

    let count = 0;
    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatTime(milliseconds) {
        const hours = String(Math.floor(milliseconds / (60 * 60 * 1000))).padStart(2, '0');
        const minutes = String(Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
        return `${hours}:${minutes}`;
      }
      function formatSRBTime(stringDate) {
        const date = new Date(stringDate);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        return formattedDate
      }
                    
    if(!isGradski){
        const arrayNightDriving = [];
   
        for(let n=0; n<driversDB.length;n++){
            let driver = driversDB[n];
            let filtered = records.filter(item => item.DriverId === driver.Id) 
            let  groupedByDay = {
                isNightDriving: false,
                sumNightDriving: 0
            };
      
              for (let i=0; i<filtered.length; i++) {
        
                const record = filtered[i];
                const day = record.ActivityDate.split("T")[0];
            
                if (record.Value.toLowerCase() === 'driving') {
                      let date;
                      let dateModified;
                      if(groupedByDay.sumWorkTotalTime > 0) {
                        date = new Date(record.EndTime);
                        let dateNew = date.toLocaleString();
                        dateModified = new Date(dateNew)
                      } else {
                        date = new Date(record.StartTime);
                        let dateNew = date.toLocaleString();
                        dateModified = new Date(dateNew)
                      }
                      
                 
                      // dateAdjusted.setHours(date.getHours() + selectedHour.code);        
                      const hour = dateModified.getHours();
                      if (hour >= 0 && hour <= 3) {
                        groupedByDay.isNightWork = true;
                        groupedByDay.isNightDriving = true;
                        groupedByDay.sumNightDriving += parseInt(record.TotalTime)
                      }
                 
                  } else if(record.Value.toLowerCase() === 'break/rest' || record.Value.toLowerCase() === 'availability' || record.Value.toLowerCase() === 'unknown') {
                    let date = new Date(record.EndTime);
                    let dateNew = date.toLocaleString();
                    let dateModified = new Date(dateNew);
               
                    // dateAdjusted.setHours(date.getHours() + selectedHour.code);        
                    const hour = dateModified.getHours();
                    if (hour >= 0 && hour <= 3) {
                      continue;
                    } else {
                        if(groupedByDay.isNightDriving) {
                            if(groupedByDay.sumNightDriving > 3 * 60 * 60 * 1000) {
                              count++
                              arrayNightDriving.push({
                                Prekršaj: count,
                                Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                Kompanija: company.Name,  // Assuming Company is available in the records
                                Poruka: `Kazna na dan ${formatSRBTime(day)} za noćnu vožnju veću od 3h i to: ${formatTime(groupedByDay.sumNightDriving)}!`
                              });
                              groupedByDay.isNightDriving = false
                              groupedByDay.sumNightDriving = 0
                            } else {
                              groupedByDay.isNightDriving = false
                              groupedByDay.sumNightDriving = 0
                            }
      
                        } else {
                          groupedByDay.isNightDriving = false
                          groupedByDay.sumNightDriving = 0
                        }
                    }
      
                  }
      
              }
        
        }
  
   
  return arrayNightDriving;
  }
  
}