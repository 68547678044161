import React from 'react';
import './unos_vozila.css';
import Slika from './vozila.jpg'

const UnosVozila = props => {
  return (
    <div className="unos">
      <h1>UNOS VOZILA</h1>
      <img src={Slika} alt="Unos vozila"/>
      {props.children}
    </div>
  );
};

export default UnosVozila;