export function processCardExpiryDate(records, company, driversDB) {
    const arrayCardExpiry = [];
    let count = 0;
    const findDriver = (id) => {
      let driver = driversDB.find(driver => driver.Id === id)
      return driver.FullName;
  }
  function formatSRBTime(stringDate) {
    const date = new Date(stringDate);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
    return formattedDate
  }

  for(let n=0; n<driversDB.length;n++){
    let driver = driversDB[n];
    let filtered = records.filter(item => item.DriverId === driver.Id) 

    for(let i = 0; i< filtered.length; i++){

      const day = filtered[i].ExpiryDate.split("T")[0];
      count++
      let expiryDate = new Date(day)
      let today = new Date()

      arrayCardExpiry.push({
        Prekršaj: count,
        Vozač: findDriver(driversDB[n].Id),  // Assuming DriverId is available in the records
        Kompanija: company.Name,  // Assuming Company is available in the record
        Poruka: expiryDate < today ? `KARTICA JE ISTEKLA - ${formatSRBTime(day)}!` : `Kartica ističe - ${formatSRBTime(day)}!`
      });
    } 
  }

    return arrayCardExpiry
  }