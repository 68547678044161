import React from "react";

import "./Header.css";

const Auth = (props) => {

  return (
    <div className="logout">
      
      <p>
        v1.5 ( {props.user.name} {props.user.surname} - {props.user.Naziv} )
      </p>{" "}
      <div onClick={props.logout}>Odjava</div>

    </div>
  );
};

export default Auth;
