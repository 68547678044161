import React from 'react';
import {Link} from 'react-router-dom';
import logo from './logo tst.png';
import './Header.css';

const Logo = () => {
  return (
    <div id="logo">
        <Link to="/"><img src={logo} alt="TPS logo"/></Link>
    </div>
  );
};

export default Logo;
