export function processRecordsWork(records, company, driversDB, isGradski) {

    let count = 0;

    const findDriver = (id) => {
        let driver = driversDB.find(driver => driver.Id === id)
        return driver.FullName;
    }
    function formatTime(milliseconds) {
        const hours = String(Math.floor(milliseconds / (60 * 60 * 1000))).padStart(2, '0');
        const minutes = String(Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000))).padStart(2, '0');
        return `${hours}:${minutes}`;
      }
      function formatSRBTime(stringDate) {
        const date = new Date(stringDate);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        return formattedDate
      }
                    
    if(!isGradski){
     
        const arrayLessThan45mins = [];

        for(let n=0; n<driversDB.length;n++){
          let driver = driversDB[n];
          let filtered = records.filter(item => item.DriverId === driver.Id) 

          const groupedByDay = {};

            for (let i=0; i<filtered.length; i++) {
              const record = filtered[i];
              const nextRecord = filtered[i+1];
              const day = record.ActivityDate.split("T")[0];
          
              if (!groupedByDay[day]) {
                groupedByDay[day] = {
                  sumWorkTotalTime: 0,
                  sumBreakRestTotalTime: 0,
                  has15: false,
                  has30: false,
                  has45: false,
                  has30second: false,
                };
              }
          
              if (record.Value.toLowerCase() === 'driving') {
                groupedByDay[day].sumWorkTotalTime += parseInt(record.TotalTime);
                if(nextRecord) {
                  if(nextRecord.Value.toLowerCase() === 'work' || nextRecord.Value.toLowerCase() === 'driving') {
                    continue
                  } else {
                    if (!groupedByDay[day].has30second) {
                                if(groupedByDay[day].sumWorkTotalTime > 6 * 60 * 60 * 1000 && groupedByDay[day].sumWorkTotalTime <= 9 * 60 * 60 * 1000) {
                                            count++
                                            arrayLessThan45mins.push({
                                              Prekršaj: count,
                                              Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                              Kompanija: company.Name,  // Assuming Company is available in the records
                                              Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 6h tačnije ${formatTime(groupedByDay[day].sumWorkTotalTime)}, nije imao 30 minuta pauze!`
                                            });
                                            // Reset for the next set of records
                                            groupedByDay[day] = {
                                              sumWorkTotalTime: 0,
                                              sumBreakRestTotalTime: 0,
                                              has15: false,
                                              has30: false,
                                              has45: false,
                                              has30second: false,
                                            };
                                    // Reset for the next set of records
                                    
                                      } else if (groupedByDay[day].sumWorkTotalTime > 9 * 60 * 60 * 1000) { 
                                        count++
                                            arrayLessThan45mins.push({
                                              Prekršaj: count,
                                              Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                              Kompanija: company.Name,  // Assuming Company is available in the records
                                              Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 9h tačnije ${formatTime(groupedByDay[day].sumWorkTotalTime)}, nije imao 45 minuta pauze!`
                                        });
                                        // Reset for the next set of records
                                        groupedByDay[day] = {
                                          sumWorkTotalTime: 0,
                                          sumBreakRestTotalTime: 0,
                                          has15: false,
                                          has30: false,
                                          has45: false,
                                          has30second: false,
                                        };
                                      }
                        } else if (!groupedByDay[day].has45) {
                          if(groupedByDay[day].sumWorkTotalTime > 9 * 60 * 60 * 1000 ) {
                            count++
                              arrayLessThan45mins.push({
                              Prekršaj: count,
                              Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                              Kompanija: company.Name,  // Assuming Company is available in the records
                              Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 9h tačnije ${formatTime(groupedByDay[day].sumWorkTotalTime)}, nije imao 45 minuta pauze!`
                            });
                            // Reset for the next set of records
                            groupedByDay[day] = {
                              sumWorkTotalTime: 0,
                              sumBreakRestTotalTime: 0,
                              has15: false,
                              has30: false,
                              has45: false,
                              has30second: false,
                            };

                      }
                          }
                
                  }
                }

                    
                } else if(record.Value.toLowerCase() === 'work') {
                  if(parseInt(record.TotalTime) > 9 * 60 * 60 * 1000) {
                    count++
                      arrayLessThan45mins.push({
                      Prekršaj: count,
                      Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                      Kompanija: company.Name,  // Assuming Company is available in the records
                      Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 9h tačnije ${formatTime(record.TotalTime)}, nije imao 45 minuta pauze!`
                    });
                    groupedByDay[day] = {
                      sumWorkTotalTime: 0,
                      sumBreakRestTotalTime: 0,
                      has15: false,
                      has30: false,
                      has45: false,
                      has30second: false,
                    };
                  } else if(parseInt(record.TotalTime) > 6 * 60 * 60 * 1000 && parseInt(record.TotalTime) <= 9 * 60 * 60 * 1000) {
                    count++
                      arrayLessThan45mins.push({
                      Prekršaj: count,
                      Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                      Kompanija: company.Name,  // Assuming Company is available in the records
                      Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 6h tačnije ${formatTime(record.TotalTime)}, nije imao 30 minuta pauze!`
                    });
                    // Reset for the next set of records
                    groupedByDay[day] = {
                      sumDrivingTotalTime: 0,
                      sumBreakRestTotalTime: 0,
                      has15: false,
                      has30: false,
                      has45: false,
                      has30second: false,
                    };
                  } else {
                  
                    if(nextRecord) {
                      
                      if(nextRecord.Value.toLowerCase() === 'work' || nextRecord.Value.toLowerCase() === 'driving') {
                            if(nextRecord.TotalTime > 6 * 60 * 60 * 1000) {
                              if (!groupedByDay[day].has30second) {
                                if(groupedByDay[day].sumWorkTotalTime > 6 * 60 * 60 * 1000  && groupedByDay[day].sumWorkTotalTime <= 9 * 60 * 60 * 1000) {
                                  count++
                                              arrayLessThan45mins.push({
                                              Prekršaj: count,
                                              Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                              Kompanija: company.Name,  // Assuming Company is available in the records
                                              Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 6h tačnije ${formatTime(groupedByDay[day].sumWorkTotalTime)}, nije imao 30 minuta pauze!`
                                            });
                                            // Reset for the next set of records
                                            groupedByDay[day] = {
                                              sumWorkTotalTime: 0,
                                              sumBreakRestTotalTime: 0,
                                              has15: false,
                                              has30: false,
                                              has45: false,
                                              has30second: false,
                                            };
                                    // Reset for the next set of records
                                    
                                      } else if (groupedByDay[day].sumWorkTotalTime > 9 * 60 * 60 * 1000) { 
                                        count++
                                          arrayLessThan45mins.push({
                                          Prekršaj: count,
                                          Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                          Kompanija: company.Name,  // Assuming Company is available in the records
                                          Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 9h tačnije ${formatTime(groupedByDay[day].sumWorkTotalTime)}, nije imao 45 minuta pauze!`
                                        });
                                        // Reset for the next set of records
                                        groupedByDay[day] = {
                                          sumWorkTotalTime: 0,
                                          sumBreakRestTotalTime: 0,
                                          has15: false,
                                          has30: false,
                                          has45: false,
                                          has30second: false,
                                        };
                                      }
                            } else if (!groupedByDay[day].has45) {
                              if(groupedByDay[day].sumWorkTotalTime > 9 * 60 * 60 * 1000 ) {
                                count++
                                  arrayLessThan45mins.push({
                                  Prekršaj: count,
                                  Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                  Kompanija: company.Name,  // Assuming Company is available in the records
                                  Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 9h tačnije ${formatTime(groupedByDay[day].sumWorkTotalTime)}, nije imao 45 minuta pauze!`
                                });
                                // Reset for the next set of records
                                groupedByDay[day] = {
                                  sumWorkTotalTime: 0,
                                  sumBreakRestTotalTime: 0,
                                  has15: false,
                                  has30: false,
                                  has45: false,
                                  has30second: false,
                                };

                              }
                          }
                            } else {
                              groupedByDay[day].sumWorkTotalTime += parseInt(record.TotalTime);
                              continue
                            
                            }
                      } else { 
                        if (!groupedByDay[day].has30second) {
                        if(groupedByDay[day].sumWorkTotalTime > 6 * 60 * 60 * 1000 && groupedByDay[day].sumWorkTotalTime <= 9 * 60 * 60 * 1000) {
                          count++
                                      arrayLessThan45mins.push({
                                      Prekršaj: count,
                                      Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                      Kompanija: company.Name,  // Assuming Company is available in the records
                                      Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 6h tačnije ${formatTime(groupedByDay[day].sumWorkTotalTime)}, nije imao 30 minuta pauze!`
                                    });
                                    // Reset for the next set of records
                                    groupedByDay[day] = {
                                      sumWorkTotalTime: 0,
                                      sumBreakRestTotalTime: 0,
                                      has15: false,
                                      has30: false,
                                      has45: false,
                                      has30second: false,
                                    };
                            // Reset for the next set of records
                            
                              } else if (groupedByDay[day].sumWorkTotalTime > 9 * 60 * 60 * 1000) {
                                count++
                                  arrayLessThan45mins.push({
                                  Prekršaj: count,
                                  Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                                  Kompanija: company.Name,  // Assuming Company is available in the records
                                  Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 9h tačnije ${formatTime(groupedByDay[day].sumWorkTotalTime)}, nije imao 45 minuta pauze!`
                                });
                                // Reset for the next set of records
                                groupedByDay[day] = {
                                  sumWorkTotalTime: 0,
                                  sumBreakRestTotalTime: 0,
                                  has15: false,
                                  has30: false,
                                  has45: false,
                                  has30second: false,
                                };
                              }
                    } else if (!groupedByDay[day].has45) {
                      if(groupedByDay[day].sumWorkTotalTime > 9 * 60 * 60 * 1000 ) {
                        count++
                          arrayLessThan45mins.push({
                          Prekršaj: count,
                          Vozač: findDriver(record.DriverId),  // Assuming DriverId is available in the records
                          Kompanija: company.Name,  // Assuming Company is available in the records
                          Poruka: `Kazna na dan ${formatSRBTime(day)} za ukupni rad veći od 9h tačnije ${formatTime(groupedByDay[day].sumWorkTotalTime)}, nije imao 45 minuta pauze!`
                        });
                        // Reset for the next set of records
                        groupedByDay[day] = {
                          sumWorkTotalTime: 0,
                          sumBreakRestTotalTime: 0,
                          has15: false,
                          has30: false,
                          has45: false,
                          has30second: false,
                        };

                      }
                  } }
                    } else {
                      groupedByDay[day].sumWorkTotalTime += parseInt(record.TotalTime);
                    }
              
                  }
                
                      
                } else if (record.Value.toLowerCase() === 'break/rest' || record.Value.toLowerCase() === 'unknown') {
                      if(groupedByDay[day].has45) {
                        groupedByDay[day].sumWorkTotalTime = 0
                      } else {
                        
                        if (parseInt(record.TotalTime) >= 15 * 60  * 1000 && parseInt(record.TotalTime) < 45 * 60 * 1000) {
                                    if(parseInt(record.TotalTime) >= 15  * 60 * 1000 && parseInt(record.TotalTime) < 30 * 60 * 1000) {
                                          if(!groupedByDay[day].has15) {
                                            groupedByDay[day].has15 = true;
                                            groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                                          } 
                                      
                                    } else if (parseInt(record.TotalTime) >= 30 * 60 * 1000 && parseInt(record.TotalTime) < 45 * 60 * 1000) {
                                          groupedByDay[day].has30second = true;
                                          if(!groupedByDay[day].has15) {
                                            groupedByDay[day].has15 = true;
                                            groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                                          } else {
                                            groupedByDay[day].has30 = true;
                                            groupedByDay[day].has45 = true;
                                            groupedByDay[day] = {
                                              sumWorkTotalTime: 0,
                                              sumBreakRestTotalTime: 0,
                                              has15: false,
                                              has30: false,
                                              has45: false,
                                              has30second: false,
                                            };
                                          }
                                    } else if (parseInt(record.TotalTime) >= 45 * 60 * 1000) {
                                        groupedByDay[day].has30second = true;
                                        groupedByDay[day].has45 = true;
                                        groupedByDay[day] = {
                                          sumWorkTotalTime: 0,
                                          sumBreakRestTotalTime: 0,
                                          has15: false,
                                          has30: false,
                                          has45: false,
                                          has30second: false,
                                        };
                                        }
                          } else if (parseInt(record.TotalTime) >= 45 * 60 * 1000){
                            groupedByDay[day].has30second = true;
                                  groupedByDay[day] = {
                                    sumWorkTotalTime: 0,
                                  sumBreakRestTotalTime: 0,
                                  has15: false,
                                  has30: false,
                                  has45: false,
                                  has30second: false,
                                };
                            }
                      }  
              } else if (record.Value.toLowerCase() === 'availability') {
                if(groupedByDay[day].has45) {
                  groupedByDay[day].sumWorkTotalTime = 0
                } else {
                
                if (parseInt(record.TotalTime) >= 15 * 60  * 1000 && parseInt(record.TotalTime) < 45 * 60 * 1000) {
                            if(parseInt(record.TotalTime) >= 15  * 60 * 1000 && parseInt(record.TotalTime) < 30 * 60 * 1000) {
                                  if(!groupedByDay[day].has15) {
                                    groupedByDay[day].has15 = true;
                                    groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                                  } 
                              
                            } else if (parseInt(record.TotalTime) >= 30 * 60 * 1000 && parseInt(record.TotalTime) < 45 * 60 * 1000) {
                                  groupedByDay[day].has30second = true;
                                  if(!groupedByDay[day].has15) {
                                    groupedByDay[day].has15 = true;
                                    groupedByDay[day].sumBreakRestTotalTime += 15 * 60 * 1000;
                                  } else {
                                    groupedByDay[day].has30 = true;
                                    groupedByDay[day].has45 = true;
                                    groupedByDay[day] = {
                                      sumWorkTotalTime: 0,
                                      sumBreakRestTotalTime: 0,
                                      has15: false,
                                      has30: false,
                                      has45: false,
                                      has30second: false,
                                    };
                                  }
                            } else if (parseInt(record.TotalTime) >= 45 * 60 * 1000) {
                                groupedByDay[day].has45 = true;
                                groupedByDay[day].has30second = true;
                                }
                  } else if (parseInt(record.TotalTime) >= 45 * 60 * 1000){
                          groupedByDay[day].has30second = true;
                          groupedByDay[day] = {
                            sumWorkTotalTime: 0,
                          sumBreakRestTotalTime: 0,
                          has15: false,
                          has30: false,
                          has45: false,
                          has30second: false,
                        };
                    }
                }  
              }
            }
          }
  return arrayLessThan45mins;
  }
  
}